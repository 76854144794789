import useCurrentUser from 'hooks/useCurrentUser';
import Permissions from '_types/Permissions';
import Map from '_types/Map';
import omitFalsyFields from 'functions/object/omitFalsyFields';
import { mergeDeepRight } from 'ramda';
import CurrentUser from '_types/api/CurrentUser';
import { useQuery } from 'react-query';

export function canAccessPage(pagePath: string, permissions: Permissions): boolean {
    //  basic impl : if the user has access permission on base path, we consider he can access it.
    // for example if the user wants to access /users/entry/2 and has users.canAccess:true -> ok
    const basePath = pagePath.split('/')[1] || '/';
    return permissions[basePath] && permissions[basePath].canAccess;
}

export function mergePermissions<TPermissions extends object>(
    userRoles: string[],
    permissionsByRole: Map<boolean | unknown>,
    defaultPermissions: TPermissions,
): TPermissions {
    return userRoles.reduce((result, roleId) => {
        const rolePermissions = permissionsByRole[roleId] as Map<boolean | object>;
        if (rolePermissions) {
            return mergeDeepRight(result, omitFalsyFields(rolePermissions)) as TPermissions;
        }
        return result;
    }, defaultPermissions);
}

type useCurrentUserPermissionsReturn = Permissions & {
    currentUserPermissions: Permissions;
    arePermissionsLoaded: boolean;
    canAccessPage: (pagePath: string) => boolean;
    currentUser: CurrentUser;
};

export default function useCurrentUserPermissions(): useCurrentUserPermissionsReturn {
    const { currentUser } = useCurrentUser();
    const currentUserRoles = currentUser?.roles;

    const { data: currentUserPermissions } = useQuery<Permissions>(
        'current-user-currentUserPermissions',
        async (): Promise<Permissions> => {
            const permissionsByRole = (await import('./permissionsByRole.json' /* webpackPrefetch: true */))
                .default as Map<boolean | unknown>;
            return mergePermissions(currentUserRoles, permissionsByRole, permissionsByRole.default as Permissions);
        },
        { enabled: !!currentUser },
    );

    return {
        ...currentUserPermissions,
        currentUserPermissions: currentUserPermissions as Permissions,
        arePermissionsLoaded: !!currentUserPermissions,
        canAccessPage: (pagePath: string) =>
            !!currentUserPermissions && canAccessPage(pagePath, currentUserPermissions),
        currentUser,
    } as useCurrentUserPermissionsReturn;
}
