import { rest } from 'msw';
import questionnairesPath from 'consts/paths/api/questionnaires/questionnairesPath';
import resolvePath from 'mocks/resolvePath';

export default ({ delay = 0 } = {}) => [
    rest.post(resolvePath(questionnairesPath), (req, res, ctx) =>
        res(
            ctx.delay(delay),
            ctx.status(200),
            ctx.json({
                name: 'sadsad',
                code: null,
                type: 'itemlist',
                state: 'draft',
                phase: null,
                gender: 'female',
                chemical: 'asdsad',
                description: 'asdsad',
                additionalInfo: null,
                contact: null,
                isCustom: true,
                author: 73,
                questionsStartPosition: 1,
                languages: [{ code: 'en', name: 'English' }],
                id: 1000288,
                createDate: '2022-02-09T12:36:19.7441355+00:00',
                updateDate: '2022-02-09T12:36:19.7441355+00:00',
            }),
        ),
    ),
];
