import questionnairesPath from 'consts/paths/api/questionnaires/questionnairesPath';
import useCurrentUser from '../useCurrentUser';
import useInvalidateQuestionnaires from '../useInvalidateQuestionnaires';
import useApi from '../useApi';
import CreateQuestionnaire from '_types/api/questionnaires/Create/CreateQuestionnaire';
import Questionnaire from '_types/api/questionnaires/Questionnaire';
import { AxiosResponse } from 'axios';

const useCreateQuestionnaire = () => {
    const api = useApi();

    const { currentUser } = useCurrentUser();
    const invalidateQuestionnaires = useInvalidateQuestionnaires();

    return {
        createQuestionnaire: async (
            formData: Omit<CreateQuestionnaire, 'author' | 'state' | 'languages'>,
            { invalidate = true }: { invalidate?: boolean } = {},
        ) => {
            const data = {
                ...formData,
                author: currentUser.id,
                languages: [{ code: 'en', name: 'English' }],
                state: 'draft',
            };

            const { data: createdQuestionnaire } = await api.post<CreateQuestionnaire, AxiosResponse<Questionnaire>>(
                questionnairesPath,
                data,
            );

            if (invalidate) {
                await invalidateQuestionnaires();
            }

            return createdQuestionnaire;
        },
    };
};

export default useCreateQuestionnaire;
