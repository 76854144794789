import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Link from 'next/link';
import { ReactNode } from 'react';
import styles from './HeaderBase.module.scss';
import logoPng from './logo.png';

export type HeaderBaseProps = {
    children?: ReactNode;
};

function HeaderBase({ children }: HeaderBaseProps) {
    return (
        <Navbar expand="lg" variant="light" bg="light" className={styles.example}>
            <Container>
                <Link href="/" passHref>
                    <Navbar.Brand className={styles.logoWrapper}>
                        <img src={logoPng.src} alt="EORTC logo" width="116px" height="28px" />
                        <h2 className={styles.title}>Item Library</h2>
                    </Navbar.Brand>
                </Link>
                {children}
            </Container>
        </Navbar>
    );
}

export default HeaderBase;
