import TooltipWrapper from 'elements/TooltipWrapper/TooltipWrapper';
import { Row, Col, Container } from 'react-bootstrap';
import FooterBase from '../Base';
import styles from './loggedInFooter.module.scss';

const LoggedInFooter = () => {
    return (
        <FooterBase>
            <Container className={styles.textWrapper}>
                <Row>
                    <Col sm={5}>
                        <h3>How to obtain the questionnaires</h3>
                        <p>
                            The EORTC has two policies for the use of the QLQ-C30 and associated <br /> modules.
                        </p>
                        <div>
                            Commercial users
                            <TooltipWrapper
                                placement="top"
                                tooltipId="footerTooltip1"
                                text="If an organization, institution or company is being provided with or providing funds, sponsorship or a grant for their research, and should any of these gain financially or use the measures for commercial purposes, then a fee is chargeable."
                            >
                                <span className={styles.iconTooltip} />
                            </TooltipWrapper>
                            - Royalty fee: please contact
                            <br />
                            <a href="mailto:qol.contracts@eortc.org ">qol.contracts@eortc.org </a>
                        </div>
                        <div>
                            What is classed as &apos;academic&apos; research{' '}
                            <TooltipWrapper
                                placement="top"
                                tooltipId="footerTooltip2"
                                text="The only time a fee is not chargeable is if the study is purely for academic use and there is no financial gain by any party."
                            >
                                <div className={styles.iconTooltip} />
                            </TooltipWrapper>
                            - for educational institutions, hospitals, etc - No fee: questionnaires can be downloaded
                            from the EORTC website
                        </div>
                    </Col>
                    <Col sm={{ span: 5, offset: 2 }}>
                        <h3>Can&apos;t find what you&apos;re looking for?</h3>
                        <div>
                            Please suggest some issues that are not yet captured in questions.
                            <TooltipWrapper
                                placement="top"
                                tooltipId="footerTooltip3"
                                text="Something missing from our Library? If you would like to assess a symptom/problem/side effect that is not featured in any of the items here, you can send us your suggestion, including a justification for your idea, and we will add your request to the research pool for consideration."
                            >
                                <span className={styles.iconTooltip} />
                            </TooltipWrapper>
                        </div>
                        <p>
                            Contact us at <a href="mailto:itemlibrary@eortc.org">itemlibrary@eortc.org</a>
                        </p>
                    </Col>
                </Row>
            </Container>
        </FooterBase>
    );
};

export default LoggedInFooter;
