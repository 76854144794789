import { Form } from 'react-bootstrap';
import { useController, UseControllerProps } from 'react-hook-form';
import styles from './sharedSelect.module.scss';

export type FormSelectItem = { label: string; value: string | number };

export type FormSelectProps<TValues> = UseControllerProps<TValues> & {
    label: string;
    isHidingEmptyOptions?: boolean;
    items: FormSelectItem[] | undefined;
    disabled?: boolean;
};

export type FormSelectPropsWithoutItems<TValues> = Omit<FormSelectProps<TValues>, 'items'>;

function FormSelect<TValues>(props: FormSelectProps<TValues>) {
    const {
        field,
        fieldState: { invalid },
    } = useController<TValues>(props);

    const { label, isHidingEmptyOptions = false, items, disabled = false } = props;

    return (
        <div className={isHidingEmptyOptions ? styles.hideEmpty : undefined}>
            <Form.Select
                aria-label={label}
                {...field}
                value={field.value as string}
                isInvalid={invalid}
                disabled={disabled}
            >
                <option value="">{label}</option>
                {items &&
                    items.map((item) => (
                        <option key={item.value} value={item.value}>
                            {item.label}
                        </option>
                    ))}
            </Form.Select>
        </div>
    );
}

export default FormSelect;
