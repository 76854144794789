import { Button as BsButton } from 'react-bootstrap';
import { ButtonProps as BsButtonProps } from 'react-bootstrap/Button';
import btnStyles from './button.module.scss';

export type ButtonProps = BsButtonProps;

const Button = (props: ButtonProps) => {
    const { children, className } = props;
    return (
        <BsButton
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            type="button"
            className={`${btnStyles.customButton} ${className || ''}`.trim()}
        >
            {children}
        </BsButton>
    );
};

export default Button;
