/* eslint-disable @typescript-eslint/no-unsafe-assignment,react/jsx-props-no-spreading */
import FormModal from 'components/_modal/FormModal';
import Field from 'components/_form/Field';
import { Form } from 'react-bootstrap';
import FormCountrySelect from 'components/_form/Select/CountrySelect';
import { editUserFormModalSchema } from './schema';
import CurrentUser from '_types/api/CurrentUser';
import useCurrentUser from 'hooks/useCurrentUser';
import useAlerts from 'hooks/useAlerts';

export type EditAccountFormModalProps = {
    toggle: () => void;
    isShowing: boolean;
    targetUser: CurrentUser;
};
const EditAccountFormModal = ({ toggle, isShowing, targetUser }: EditAccountFormModalProps) => {
    const { updateCurrentUser } = useCurrentUser();
    const { addSuccessAlert } = useAlerts();

    const onSubmit = async (data: CurrentUser) => {
        await updateCurrentUser(data);
        addSuccessAlert('User updated!');
    };

    return (
        <FormModal
            title="Edit account"
            toggle={toggle}
            isShowing={isShowing}
            validationSchema={editUserFormModalSchema}
            submitButtonText="Save"
            initialValues={targetUser}
            onSubmit={onSubmit}
            onComplete={() => toggle()}
            customSize
        >
            {({ register, control, formState: { errors } }) => (
                <>
                    <Field label="name" id="name" feedback={errors.name}>
                        <Form.Control isInvalid={!!errors.name} placeholder="" {...register('name')} />
                    </Field>
                    <Field label="email" id="email" feedback={errors.email}>
                        <p className="m-0 mt-2">{targetUser.email}</p>
                    </Field>
                    <Field label="country" id="country" feedback={errors.country}>
                        <FormCountrySelect control={control} name="country" label="" />
                    </Field>
                    <Field label="organisation" id="organisation" feedback={errors.organisation}>
                        <Form.Control placeholder="" {...register('organisation')} isInvalid={!!errors.organisation} />
                    </Field>
                </>
            )}
        </FormModal>
    );
};

export default EditAccountFormModal;
