import FormSelect, { FormSelectItem, FormSelectPropsWithoutItems } from '..';
import useQuestionnaireTypes from 'hooks/useQuestionnaireTypes';
import QuestionnaireKind from '_types/api/questionnaires/QuestionnaireKind';

interface FormTypeSelectProps<TValue> extends FormSelectPropsWithoutItems<TValue> {
    kind: QuestionnaireKind;
    isForQuestionnaireModules?: boolean;
}

function QuestionnaireTypeSelect<TValue>({
    kind,
    isForQuestionnaireModules = false,
    ...props
}: FormTypeSelectProps<TValue>) {
    const { questionnaireTypesByKind } = useQuestionnaireTypes(isForQuestionnaireModules);

    const types: FormSelectItem[] = [
        ...questionnaireTypesByKind[kind].map((t) => ({
            value: t.type,
            label: isForQuestionnaireModules ? t.labelPlural : t.labelSingular,
        })),
    ];

    return <FormSelect {...props} isHidingEmptyOptions={true} items={types} />;
}

export default QuestionnaireTypeSelect;
