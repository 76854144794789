import HeaderBase from 'layout/Header/HeaderBase';
import { Button, Nav } from 'react-bootstrap';
import { useCallback } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import useCurrentUserPermissions from 'hooks/useCurrentUserPermissions';
import styles from './LoggedInHeader.module.scss';
import NavLink from 'elements/NavLink';
import useUserSession from 'hooks/useUserSession';
import Dropdown from './Dropdown';
import CreateCustomQuestionnaireFormModal from 'components/_questionnaires/CustomQuestionnaireFormModal/CreateCustomQuestionnaireFormModal';
import useModal from 'hooks/useModal';

const LoggedInHeader = () => {
    const { logOut } = useUserSession();
    const onLogoutClick = useCallback(() => {
        logOut();
    }, [logOut]);

    const { currentUser } = useCurrentUser();
    const [isShowingCreateQuestionnaire, toggleCreateQuestionnaire] = useModal();

    const { users, requests, customQuestionnaires } = useCurrentUserPermissions();

    const canAccessUsers = users?.canAccess;
    const canAccessRequests = requests?.canAccess;
    const canCreateCustomQuestionnaire = customQuestionnaires?.canCreate;

    return (
        <>
            <HeaderBase>
                <Nav className={styles.authOptionsWrapper}>
                    {canAccessUsers && <NavLink href="/users">Users</NavLink>}
                    {canAccessRequests && <NavLink href="/requests">Requests</NavLink>}
                    {canCreateCustomQuestionnaire && (
                        <Nav.Item className={styles.createQuestBtn}>
                            <Button onClick={toggleCreateQuestionnaire}>Create a new questionnaire</Button>
                        </Nav.Item>
                    )}
                    <Dropdown onLogoutClick={onLogoutClick} user={currentUser} />
                </Nav>
                <CreateCustomQuestionnaireFormModal
                    isShowing={isShowingCreateQuestionnaire}
                    toggle={toggleCreateQuestionnaire}
                />
            </HeaderBase>
        </>
    );
};

export default LoggedInHeader;
