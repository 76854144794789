import useAlerts from 'hooks/useAlerts';
import CustomQuestionnaireFormModal, { CustomQuestionnaireValues } from '..';
import useCreateQuestionnaire from 'hooks/useCreateQuestionnaire';
import { stripHtmlFromStringIfEmpty } from 'functions/string/stripHtmlFromString';
import { normalizeTypeaheadField } from 'functions/object/normalizeTypeaheadArray';
import { CreateCustomQuestionnaire } from '_types/api/questionnaires/Create/CreateQuestionnaire';
export type CreateCustomQuestionnaireFormModalProps = {
    toggle: () => void;
    isShowing: boolean;
    addQuestionToQuestionnaire?: (targetQuestionnaireId: number, numberOfQuestions: number) => Promise<void>;
};

const CreateCustomQuestionnaireFormModal = ({
    isShowing,
    toggle,
    addQuestionToQuestionnaire,
}: CreateCustomQuestionnaireFormModalProps) => {
    const { addSuccessAlert } = useAlerts();

    const { createQuestionnaire } = useCreateQuestionnaire();

    const onSubmit = async (formData: CustomQuestionnaireValues) => {
        const { description, chemical } = formData;
        const fixedDescription = stripHtmlFromStringIfEmpty(description);
        const fixedChemical = normalizeTypeaheadField(chemical);
        const result = await createQuestionnaire({
            ...formData,
            isCustom: true,
            description: fixedDescription,
            chemical: fixedChemical,
        } as CreateCustomQuestionnaire);

        if (typeof addQuestionToQuestionnaire === 'function') {
            await addQuestionToQuestionnaire(
                result?.id,
                result?.questionnaireItems?.length
                    ? result.questionsStartPosition + result?.questionnaireItems?.length
                    : result.questionsStartPosition,
            );
        }
        addSuccessAlert('Questionnaire created!');
    };

    return (
        <CustomQuestionnaireFormModal
            isShowing={isShowing}
            toggle={toggle}
            title="Add Questionnaire"
            onSubmit={onSubmit}
            canShowDraftMessage={true}
            isResetAfterSubmit={true}
        />
    );
};

export default CreateCustomQuestionnaireFormModal;
