import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormProps as RhfUseFormProps, UseFormReturn as RhfUseFormReturn } from 'react-hook-form/dist/types';
import useIsMounted from 'hooks/useIsMounted';
import useButtonWithLoader from 'elements/ButtonWithLoader/useButtonWithLoader';
import useUnexpectedError from 'hooks/useUnexpectedError';
import tryGetApiError from 'functions/api/tryGetApiError';
import { DefaultValues } from 'react-hook-form/dist/types/form';
export interface UseFormProps<TValues> extends RhfUseFormProps<TValues> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schema: any;
    onSubmit: (data: TValues) => Promise<unknown>;
}

export interface UseFormReturn<TValues> extends Omit<RhfUseFormReturn<TValues>, 'handleSubmit' | 'reset'> {
    isLoading: boolean;
    warningMessage: string;
    handleSubmit: () => unknown;
    setWarningMessage: (message: string) => unknown;
    reset: (data?: TValues) => void;
}

export default function UseForm<TValues>({
    schema,
    onSubmit,
    ...formProps
}: UseFormProps<TValues>): UseFormReturn<TValues> {
    const isMounted = useIsMounted();
    const { isLoading, setIsLoading } = useButtonWithLoader();
    const [warningMessage, setWarningMessage] = useState<string>('');

    const { setUnexpectedError } = useUnexpectedError();

    const form = useForm<TValues>({
        resolver: yupResolver(schema),
        ...formProps,
    });

    const onSubmitProxy: SubmitHandler<TValues> = async (data) => {
        try {
            setIsLoading(true);
            setWarningMessage('');
            await onSubmit(data as TValues);
            if (isMounted()) {
                setIsLoading(false);
            }
        } catch (error: unknown) {
            const apiError = tryGetApiError(error);
            if (apiError && apiError.error_description) {
                setWarningMessage(apiError.error_description);
            } else {
                // Server reply not managed
                setUnexpectedError(error as Error, { isUserAlreadyAlerted: true });
                setWarningMessage('Something went wrong, please try again.');
            }
            setIsLoading(false);
        }
    };

    const reset = (data?: TValues) => {
        setWarningMessage('');
        form.reset(data as DefaultValues<TValues>);
    };

    return {
        ...form,
        isLoading,
        warningMessage,
        handleSubmit: form.handleSubmit(onSubmitProxy),
        setWarningMessage,
        reset,
    };
}
