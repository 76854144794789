// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const getFloat = (getter) => {
    const str = getter();
    if (str) {
        if (typeof str !== 'string') {
            throw new Error('getter must return a string');
        }
        return parseFloat(str);
    }
    return str;
};

Sentry.init({
    dsn: SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: getFloat(() => process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE) || 1.0,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'local',
    showReportDialog: process.env.NEXT_PUBLIC_SENTRY_REPORT_DIALOG === 'enabled' || false,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
