enum ItemType {
    Question = 'question',
    Condition = 'condition',
    Instructions = 'instructions',
    TimeScale = 'timeScale',
    ResponseScale = 'responseScale',
    SymptomScale = 'symptomScale',
}

export default ItemType;
