import Button from 'elements/Button';
import styles from './buttonWithLoader.module.scss';
import { ButtonProps } from 'react-bootstrap/Button';

export type ButtonWithLoaderProps = ButtonProps & {
    isLoading: boolean;
    children: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: (e: any) => void;
};

const ButtonWithLoader = ({ isLoading, onClick, children, ...props }: ButtonWithLoaderProps) => (
    <div className="d-flex align-items-center">
        <Button type="button" onClick={onClick} className="d-inline-block" disabled={isLoading} {...props}>
            {isLoading ? <div className={styles.loader} /> : children}
        </Button>
    </div>
);

export default ButtonWithLoader;
