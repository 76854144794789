import { rest } from 'msw';
import tokenPath from 'consts/paths/api/auth/token';
import resolvePath from '../resolvePath';

export default ({ delay = 0 } = {}) => [
    rest.post(resolvePath(tokenPath), (req, res, ctx) =>
        res(
            ctx.delay(delay),
            ctx.status(200),
            ctx.json({
                access_token: 'mocked_access_token',
            }),
        ),
    ),
];
