import Field from 'components/_form/Field';
import { Form } from 'react-bootstrap';
import FormModal from 'components/_modal/FormModal';
import { schema, initialValues as defaultInitialValues } from './schema';
import FormGenderSelect from 'components/_form/Select/GenderSelect';
import styles from './createQuestionnaire.module.scss';
import RichTextEditor from 'components/_form/RichTextEditor';
import QuestionnaireTypeSelect from 'components/_form/Select/QuestionnaireTypeSelect';
import QuestionnaireKind from '_types/api/questionnaires/QuestionnaireKind';
import QuestionnaireType from '_types/api/questionnaires/QuestionnaireType';
import QuestionnaireStateSelect from 'components/_form/Select/QuestionnaireStateSelect';
import QuestionnaireAuthorSelect from 'components/_form/Select/QuestionnaireAuthorSelect';
import QuestionnaireState from '_types/api/questionnaires/QuestionnaireState';
import TypeaheadWithApiCall from 'components/_form/TypeaheadWithApiCall';
import GenderType from '_types/Gender';
import questionnairesPath from 'consts/paths/api/questionnaires/questionnairesPath';

export type CustomQuestionnaireProps = {
    title: string;
    submitButtonText?: string;
    toggle: () => void;
    isShowing: boolean;
    initialValues?: CustomQuestionnaireValues;
    onSubmit: (values: CustomQuestionnaireValues) => Promise<unknown>;
    canAskState?: boolean;
    canAskAuthor?: boolean;
    canShowDraftMessage?: boolean;
    isResetAfterSubmit?: boolean;
    canChangeStatus?: boolean;
};

export type CustomQuestionnaireValues = {
    name: string;
    type: QuestionnaireType;
    gender: GenderType;
    chemical: string | null;
    questionsStartPosition: number;
    description: string;
    state?: QuestionnaireState;
    author?: number;
};

const CustomQuestionnaireFormModal = ({
    title,
    submitButtonText = 'Save',
    initialValues,
    isShowing,
    toggle,
    onSubmit,
    canAskState = false,
    canAskAuthor = false,
    canShowDraftMessage = false,
    isResetAfterSubmit = false,
    canChangeStatus = false,
}: CustomQuestionnaireProps) => {
    return (
        <FormModal
            title={title}
            toggle={toggle}
            isShowing={isShowing}
            validationSchema={schema}
            submitButtonText={submitButtonText}
            initialValues={initialValues || defaultInitialValues}
            onSubmit={onSubmit}
            onComplete={() => toggle()}
            customSize
            isResetAfterSubmit={isResetAfterSubmit}
        >
            {({ register, control, formState: { errors }, setValue }) => (
                <>
                    {canShowDraftMessage && (
                        <div className={styles.infoTextWrapper}>
                            <h3>Your questionnaire will be saved as a draft.</h3>
                            <p>
                                If you wish to export this questionnaire for use, you will first need to submit it for
                                approval. Once a content manager approves it, it will be visible to the entire community
                                and everyone will be able to export it for use.
                            </p>
                        </div>
                    )}
                    <Field label="name" id="name" feedback={errors.name}>
                        <Form.Control
                            isInvalid={!!errors.name}
                            placeholder="e. g. Breast Module"
                            {...register('name')}
                        />
                    </Field>
                    <Field label="type" id="type" feedback={errors.type}>
                        <QuestionnaireTypeSelect
                            kind={QuestionnaireKind.CUSTOM}
                            control={control}
                            name="type"
                            label=""
                        />
                    </Field>
                    {canAskState && (
                        <Field label="status" id="state" feedback={errors.state}>
                            <QuestionnaireStateSelect
                                control={control}
                                name="state"
                                label=""
                                disabled={!canChangeStatus}
                            />
                        </Field>
                    )}
                    <Field label="gender" id="gender" feedback={errors.gender}>
                        <FormGenderSelect control={control} name="gender" label="" />
                    </Field>
                    <Field label="molecule" id="chemical" feedback={undefined}>
                        <TypeaheadWithApiCall
                            name="chemical"
                            control={control}
                            setValue={setValue}
                            minLength={1}
                            isInvalid={!!errors.chemical}
                            infoText=""
                            allowNew
                            multiple={false}
                            defaultSelected={[initialValues?.chemical || '']}
                            placeholder="Name of the molecule tested"
                            error={errors.chemical?.message}
                            apiConfig={{ url: `${questionnairesPath}/chemicals/search` }}
                        />
                    </Field>
                    {canAskAuthor && (
                        <Field label="author" id="author" feedback={errors.author}>
                            <QuestionnaireAuthorSelect control={control} name="author" label="" />
                        </Field>
                    )}
                    <Field
                        label="First question"
                        id="questionsStartPosition"
                        feedback={errors.questionsStartPosition}
                        key="questionsStartPosition"
                    >
                        <Form.Control
                            isInvalid={!!errors.questionsStartPosition}
                            placeholder="1"
                            defaultValue={1}
                            min={1}
                            type="number"
                            {...register('questionsStartPosition')}
                        />
                        <p className={styles.helpInfoText}>The numbering of questions starts from this number.</p>
                    </Field>
                    <Field label="description" id="description" feedback={errors.description} key="description">
                        <RichTextEditor
                            control={control}
                            setValue={setValue}
                            name="description"
                            placeholder="any other information worth mentioning..."
                        />
                    </Field>
                </>
            )}
        </FormModal>
    );
};

export default CustomQuestionnaireFormModal;
