import React, { Suspense } from 'react';
import 'react-quill/dist/quill.snow.css';
import styles from './richTextEditor.module.scss';
const ReactQuill = React.lazy(() => import('react-quill'));
const MemoizedReactQuill = React.memo(ReactQuill, (prev, next) => prev && prev.placeholder === next.placeholder);
import { Code as Loader } from 'react-content-loader';
import { useController, UseControllerProps } from 'react-hook-form';

const getModules = ({
    isToolbarEnabled,
    isEnterKeyAllowed,
}: {
    isToolbarEnabled: boolean;
    isEnterKeyAllowed: boolean;
}) => ({
    toolbar: isToolbarEnabled
        ? [
              [{ header: 1 }, { header: 2 }, { header: [1, 2, false] }],
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link', 'clean'],
          ]
        : false,
    keyboard: isEnterKeyAllowed
        ? undefined
        : // Remove enter/shift + enter keys from react-quill keyboard
          {
              bindings: {
                  enter: {
                      key: 13,
                      handler: () => {},
                  },
                  shift_enter: {
                      key: 13,
                      shiftKey: true,
                      handler: () => {},
                  },
              },
          },
});

export interface RichTextEditorProps<TValues> extends UseControllerProps<TValues> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue: (name: any, value: any, config: any) => void;
    placeholder?: string;
    style?: React.CSSProperties;
    isToolbarEnabled?: boolean;
    isEnterKeyAllowed?: boolean;
    inputAreaClassName?: string;
    wrapperClassName?: string;
}

function RichTextEditor<TValues>({
    isToolbarEnabled = true,
    isEnterKeyAllowed = true,
    inputAreaClassName,
    wrapperClassName,
    placeholder = '',
    style,
    ...props
}: RichTextEditorProps<TValues>) {
    const {
        field: { name, value },
        fieldState: { invalid },
    } = useController(props);

    const { setValue } = props;

    function onChange(text: string | null) {
        setValue(name, text, {});
    }

    return (
        <Suspense fallback={<Loader />}>
            <MemoizedReactQuill
                theme="snow"
                modules={getModules({ isToolbarEnabled, isEnterKeyAllowed })}
                className={
                    invalid
                        ? `form-control is-invalid ${wrapperClassName || styles.wrapper}`
                        : wrapperClassName || styles.wrapper
                }
                placeholder={placeholder}
                onChange={onChange}
                value={value as string}
                style={style}
            >
                <div className={inputAreaClassName || styles.inputArea} />
            </MemoizedReactQuill>
        </Suspense>
    );
}
export default RichTextEditor;
