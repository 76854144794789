import { NavDropdown } from 'react-bootstrap';
import styles from '../LoggedInHeader.module.scss';
import useModal from 'hooks/useModal';
import EditAccountFormModal from 'layout/AppMenu/EditAccount';
import ChangePasswordFormModal from 'layout/AppMenu/ChangePassword';
import CurrentUser from '_types/api/CurrentUser';

export type DropdownProps = {
    onLogoutClick: () => void;
    user: CurrentUser;
};

const Dropdown = ({ onLogoutClick, user }: DropdownProps) => {
    const [isEditAccountShowing, toggleEditAccount] = useModal();
    const [isChangePasswordShowing, toggleChangePassword] = useModal();
    return (
        <>
            <NavDropdown title={user.name} align="end" className={styles.dropdown}>
                <NavDropdown.Item onClick={toggleEditAccount}>Edit account</NavDropdown.Item>
                <NavDropdown.Item onClick={toggleChangePassword}>Change password</NavDropdown.Item>
                <NavDropdown.Item href="/myCustom/">My questionnaire page</NavDropdown.Item>
                <NavDropdown.Item onClick={onLogoutClick}>Log out</NavDropdown.Item>
            </NavDropdown>
            <EditAccountFormModal isShowing={isEditAccountShowing} toggle={toggleEditAccount} targetUser={user} />
            <ChangePasswordFormModal isShowing={isChangePasswordShowing} toggle={toggleChangePassword} />
        </>
    );
};

export default Dropdown;
