import { rest } from 'msw';
import currentUserPath from 'consts/paths/api/users/current-user';
import resolvePath from 'mocks/resolvePath';

export default ({ delay = 0 } = {}) => [
    rest.get(resolvePath(currentUserPath), (req, res, ctx) =>
        res(
            ctx.delay(delay),
            ctx.status(200),
            ctx.json({
                id: 1,
                email: 'me@emakina.com',
                userName: 'me',
                profile: 'industrial',
                name: 'me',
                status: 'string',
                country: 'US',
                organisation: 'EMAKINA',
                motivation: 'this app rocks',
                roles: ['manageUsers', 'manageOwnCustom', 'manageContent', 'manageQuestionnaireRequests'],
                questionnaires: [0],
            }),
        ),
    ),
];
