import { rest } from 'msw';
import { languagesPath } from 'consts/paths/api/languages';
import resolvePath from 'mocks/resolvePath';
import languages from './languages.json';
export default ({ delay = 1500 } = {}) => {
    return [
        rest.get(resolvePath(languagesPath), (req, res, ctx) => {
            return res(ctx.delay(delay), ctx.status(200), ctx.json(languages));
        }),
    ];
};
