/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ReactNode } from 'react';
import { setLocale as setYupLocale } from 'yup';
import { UseFormReturn } from 'hooks/useForm';
import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import styles from './formModal.module.scss';
import FormModalInner from '../FormModalInner';
import useFormModal from 'hooks/useFormModal';

setYupLocale({
    mixed: {
        // eslint-disable-next-line no-template-curly-in-string
        required: '${path} is required',
    },
});

export interface CancelSubmitModalProps<Values> {
    toggle: () => void;
    isShowing: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: (props: UseFormReturn<Values>) => ReactNode;
    title: string;
    initialValues: Values;
    onSubmit: (values: Values) => Promise<unknown>;
    submitButtonText: string;
    cancelButtonText?: string;
    // eslint-disable-next-line
    validationSchema: any;
    onComplete?: (values: Values) => void;
    customSize?: boolean;
    isResetAfterSubmit?: boolean;
    isReducible?: boolean;
    isReduced?: boolean;
    toggleReduce?: () => void;
    onCancelClickCallback?: () => void;
    buttonInsteadCancelText?: string;
    buttonInsteadCancelCallback?: () => void;
}

function FormModal<Values>({
    toggle,
    isShowing,
    children,
    onSubmit,
    initialValues,
    submitButtonText,
    validationSchema,
    cancelButtonText,
    title,
    onComplete = () => {},
    customSize,
    isResetAfterSubmit = false,
    isReducible,
    onCancelClickCallback,
    isReduced,
    toggleReduce,
    buttonInsteadCancelText,
    buttonInsteadCancelCallback,
}: CancelSubmitModalProps<Values>) {
    const { onCancelClick, form } = useFormModal<Values>({
        onComplete,
        onSubmit,
        validationSchema,
        initialValues,
        toggle,
        isResetAfterSubmit,
        onCancelClickCallback,
    });
    return (
        <>
            {!isReduced && (
                <Modal isShowing={isShowing} size="lg" dialogClassName={customSize ? styles.customSize : ''}>
                    <ModalHeader
                        title={title}
                        hide={onCancelClick}
                        isReducible={isReducible}
                        toggleReduce={toggleReduce}
                    />
                    <FormModalInner
                        form={form}
                        onCancelClick={onCancelClick}
                        submitButtonText={submitButtonText}
                        cancelButtonText={cancelButtonText}
                        buttonInsteadCancelText={buttonInsteadCancelText}
                        buttonInsteadCancelCallback={buttonInsteadCancelCallback}
                    >
                        {children}
                    </FormModalInner>
                </Modal>
            )}
            {isReduced && (
                <ModalHeader
                    title={title}
                    hide={onCancelClick}
                    isReducible={isReducible}
                    isReduced={isReduced}
                    toggleReduce={toggleReduce}
                />
            )}
        </>
    );
}

export default FormModal;
