import { ReactNode } from 'react';
import LoggedInLayout from './LoggedInLayout';
import LoggedOutLayout from './LoggedOutLayout';
import useUserSession from 'hooks/useUserSession';
import Alerts from '../Alerts';
import useCurrentUserPermissions from '../../hooks/useCurrentUserPermissions';
export type LayoutProps = {
    children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
    const { isAuthenticated } = useUserSession();
    const { currentUser, arePermissionsLoaded } = useCurrentUserPermissions();

    return (
        <>
            {isAuthenticated && currentUser && arePermissionsLoaded ? (
                <LoggedInLayout>{children}</LoggedInLayout>
            ) : (
                <LoggedOutLayout>{children}</LoggedOutLayout>
            )}
            <Alerts />
        </>
    );
};

export default Layout;
