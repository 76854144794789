import QuestionnaireKind from '_types/api/questionnaires/QuestionnaireKind';
import QuestionnaireType from '_types/api/questionnaires/QuestionnaireType';
export type QuestionnairesTypeMeta = {
    labelPlural: string;
    labelSingular: string;
    type: QuestionnaireType;
    isOfficial: boolean;
    isCustom: boolean;
};
export const questionnairesTypes: QuestionnairesTypeMeta[] = [
    {
        labelPlural: 'Item lists',
        labelSingular: 'Item list',
        type: QuestionnaireType.ITEM_LIST,
        isOfficial: false,
        isCustom: true,
    },
    {
        labelPlural: 'Core questionnaires',
        labelSingular: 'Core questionnaire',
        type: QuestionnaireType.CORE,
        isOfficial: true,
        isCustom: false,
    },
    {
        labelPlural: 'Modules',
        labelSingular: 'Module',
        type: QuestionnaireType.MODULE,
        isOfficial: true,
        isCustom: true,
    },
    {
        labelPlural: 'Standalone questionnaires',
        labelSingular: 'Standalone questionnaire',
        type: QuestionnaireType.STANDALONE,
        isOfficial: true,
        isCustom: true,
    },
    {
        labelPlural: 'Previous versions (questionnaires)',
        labelSingular: 'Previous versions (questionnaires)',
        type: QuestionnaireType.PREVIOUS,
        isOfficial: true,
        isCustom: false,
    },
    { labelPlural: 'CAT', labelSingular: 'CAT', type: QuestionnaireType.CAT, isOfficial: true, isCustom: true },
    {
        labelPlural: 'CAT short forms',
        labelSingular: 'CAT short forms',
        type: QuestionnaireType.CAT_SHORT,
        isOfficial: true,
        isCustom: false,
    },
    {
        labelPlural: 'Short forms',
        labelSingular: 'Short form',
        type: QuestionnaireType.SHORT_FORM,
        isOfficial: true,
        isCustom: false,
    },
];

const officialQuestionnaireTypes = questionnairesTypes.filter((t) => t.isOfficial);
const customQuestionnaireTypes = questionnairesTypes.filter((t) => t.isCustom);
// Hard coded values
const questionnaireModulesTypes = [questionnairesTypes[1], questionnairesTypes[2], questionnairesTypes[3]];

const useQuestionnaireTypes = (isForQuestionnaireModules?: boolean) => {
    if (isForQuestionnaireModules) {
        return {
            questionnaireTypesByKind: {
                [QuestionnaireKind.OFFICIAL]: questionnaireModulesTypes,
                [QuestionnaireKind.CUSTOM]: questionnaireModulesTypes,
            },
        };
    }
    return {
        questionnaireTypesByKind: {
            [QuestionnaireKind.OFFICIAL]: officialQuestionnaireTypes,
            [QuestionnaireKind.CUSTOM]: customQuestionnaireTypes,
        },
    };
};
export default useQuestionnaireTypes;

export const useQuestionnaireType = (type?: QuestionnaireType) => {
    return type ? questionnairesTypes.find((qt) => qt.type === type) : null;
};
