import { rest } from 'msw';
import authorsPath from 'consts/paths/api/authors';
import resolvePath from 'mocks/resolvePath';
import authors from './authors.json';
export default ({ delay = 1500 } = {}) => {
    return [
        rest.get(resolvePath(authorsPath), (req, res, ctx) => {
            return res(ctx.delay(delay), ctx.status(200), ctx.json(authors));
        }),
    ];
};
