import FormSelect, { FormSelectPropsWithoutItems } from '..';
import useQuestionnaireAuthors from 'hooks/useQuestionnaireAuthors';

function QuestionnaireAuthorSelect<TValue>(props: FormSelectPropsWithoutItems<TValue>) {
    const { questionnaireAuthors } = useQuestionnaireAuthors();
    return (
        <FormSelect
            {...props}
            items={questionnaireAuthors?.map(({ id, name }) => ({ label: name, value: id }))}
            isHidingEmptyOptions={true}
        />
    );
}

export default QuestionnaireAuthorSelect;
