import { rest } from 'msw';
import questionnairesPath from 'consts/paths/api/questionnaires/questionnairesPath';
import resolvePath from 'mocks/resolvePath';
import questionnaire from 'mocks/questionnaires/getSummaries/questionnaires.json';
export default ({ delay = 1500 } = {}) => {
    return [
        rest.put(resolvePath(`${questionnairesPath}/*/publish`), (req, res, ctx) => {
            return res(ctx.delay(delay), ctx.status(200), ctx.json(questionnaire));
        }),
    ];
};
