import FormSelect, { FormSelectItem, FormSelectPropsWithoutItems } from '..';
import GenderType from '_types/Gender';

function FormGenderSelect<TValue>(props: FormSelectPropsWithoutItems<TValue>) {
    const types: FormSelectItem[] = [
        { label: 'Neutral', value: GenderType.NEUTRAL },
        { label: 'Male specific', value: GenderType.MALE },
        { label: 'Female specific', value: GenderType.FEMALE },
    ];

    return <FormSelect {...props} items={types} isHidingEmptyOptions={true} />;
}

export default FormGenderSelect;
