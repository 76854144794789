import Layout from 'layout/Layout';
import RouteGuard from 'components/_auth/RouteGuard';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'styles/main.scss';

import type { AppProps } from 'next/app';
import ErrorBoundary from '../components/_errors/ErrorBoundary';
import { AxiosResponse } from 'axios';
import useUnexpectedError from 'hooks/useUnexpectedError';
import { useMemo } from 'react';

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
    // eslint-disable-next-line global-require
    require('../mocks');
}

const unexpectedApiResponseConditions = [
    (response: AxiosResponse) => response.status >= 500,
    (response: AxiosResponse) => response.headers['content-type']?.startsWith('text/html'),
];

function App({ Component, pageProps }: AppProps) {
    const { setUnexpectedError } = useUnexpectedError();

    const queryClient = useMemo(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onError: function (error: any) {
                            if (
                                error.response &&
                                unexpectedApiResponseConditions.some((cond) => cond(error.response as AxiosResponse))
                            ) {
                                setUnexpectedError(error);
                            }
                        },
                        refetchOnWindowFocus: false,
                        refetchOnMount: false,
                    },
                },
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <RouteGuard>
                    <Layout>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <Component {...pageProps} />
                    </Layout>
                </RouteGuard>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </ErrorBoundary>
    );
}

export default App;
