import ModalHeader from 'components/_modal/ModalHeader';
import ModalBody from 'components/_modal/ModalBody';
import { ReactNode } from 'react';
import Modal from '../Modal';

export type MessageBoxProps = {
    title: string;
    isShowing: boolean;
    toggle: () => void;
    // seems resonable to be any here?
    /* eslint-disable-next-line */
    onComplete: (value: any) => void;
    children: ReactNode;
};

const MessageBox = ({ title, isShowing, toggle, onComplete, children }: MessageBoxProps) => {
    const onClose = () => {
        setTimeout(() => {
            onComplete(false);
        }, 300);
        toggle();
    };
    return (
        <Modal isShowing={isShowing}>
            <>
                <ModalHeader title={title} hide={onClose} />
                <ModalBody>{children}</ModalBody>
            </>
        </Modal>
    );
};

export default MessageBox;
