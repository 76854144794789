import Country from '_types/Country';
import { useEffect, useState } from 'react';

const useCountries = () => {
    const [countries, setCountries] = useState<Country[] | undefined>();

    useEffect(() => {
        void (async () => {
            setCountries((await import('./countries.json' /* webpackPrefetch: true */)).default as Country[]);
        })();
    }, []);

    const getCountryName = (code: string) => {
        const result = countries?.filter((item) => item.code === code);
        if (result) {
            return result[0].name;
        }

        return '';
    };

    return {
        countries,
        getCountryName,
    };
};

export default useCountries;
