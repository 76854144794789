import { TypeaheadComponentProps } from 'react-bootstrap-typeahead/types/components/Typeahead/Typeahead';
import { TypeaheadMenuProps } from 'react-bootstrap-typeahead/types/components/TypeaheadMenu/TypeaheadMenu';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Component } from 'react';

type RenderMenuItemChildren<TOption> = (option: TOption, menuProps: TypeaheadMenuProps, idx: number) => JSX.Element;

export type ReactBootstrapTypeaheadWrapperProps<TOption> = Omit<
    TypeaheadComponentProps,
    'renderMenuItemChildren' | 'options' | 'onChange' | 'onInputChange'
> & {
    renderMenuItemChildren?: RenderMenuItemChildren<TOption>;
    options: TOption[];
    onChange?: (selected: TOption[]) => void;
    onInputChange?: (input: string) => void;
};

/*
 as Typeahead is not well typed we need that wrapper to avoid spread that issue in your code
 */
class ReactBootstrapTypeaheadWrapper<TOption> extends Component<ReactBootstrapTypeaheadWrapperProps<TOption>> {
    render() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <Typeahead {...this.props} />;
    }
}

export default ReactBootstrapTypeaheadWrapper;
