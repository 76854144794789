/**
 * a list of handlers (endpoints) to emulate (mock) a actual implementation
 */
import loginHappyPath from 'mocks/login/happyPath';
import forgotPassword from 'mocks/forgotPassword/happyPath';
import requestAccess from 'mocks/requestAccess/happyPath';
import currentUserHappyPath from 'mocks/users/current-user/happyPath';
import getUsersHappyPath from 'mocks/users/getUsers/happyPath';
import addUserHappyPath from 'mocks/users/addUser/happyPath';
import editUserHappyPath from 'mocks/users/editUser/happyPath';
import deleteUserHappyPath from 'mocks/users/deleteUser';
import editAccountHappyPath from 'mocks/users/editAccount';
import changePasswordHappyPath from 'mocks/users/changePassword';
import getQuestionnairesSummary from 'mocks/questionnaires/getSummaries';
import getQuestionnaireCategories from 'mocks/items/categories';
import createCustomQuestionnaire from 'mocks/questionnaires/createCustomQuestionnaire';
import getItemClassificationDetails from 'mocks/items/getItem';
import getQuestionnaireDetails from 'mocks/questionnaires/get';
import publishQuestionnaire from 'mocks/questionnaires/publish';
import deleteQuestionnaire from 'mocks/questionnaires/delete';
import updateQuestionnaireItem from 'mocks/questionnaires/updateQuestionnaireItem';
import deleteQuestionnaireItem from 'mocks/questionnaires/deleteQuestionnaireItem';
import editQuestionnaireItem from 'mocks/questionnaires/editQuestionnaireItem';
import getTimeScale from 'mocks/items/search/timeScales';
import getResponseScale from 'mocks/items/search/responseScales';
import addQuestionnaireItem from './questionnaires/addQuestionnaireItem';
import downloadCsv from './questionnaires/downloadCsv';
import updateItem from './items/updateItem';
import itemsSearch from 'mocks/items/search';
import getLanguages from './languages/get';
import createLanguage from './questionnaires/addLanguage';
import deleteLanguage from './questionnaires/deleteLanguage';
import addToVerifiedLanguages from './questionnaires/addToVerifiedLanguages';
import getWithTranslation from './questionnaires/getWithTranslation';
import bulkAddTranslations from './questionnaires/bulkAddTranslations';
import { RestHandler } from 'msw';
import updateQuestionnaire from 'mocks/questionnaires/updateQuestionnaire';
import getAuthors from 'mocks/questionnaires/authors';
import setRecommendedWording from './items/setRecommendedWording';
import bulkAddQuestionnaireItems from 'mocks/questionnaires/bulkAddQuestionnaireItems';
import editTranslation from './questionnaires/editTranslation';
import deleteItem from './items/delete';
import updateRecommendedWording from './items/updateRecommendedWording';
import getUsage from './usage';
import getGeneralSearchResults from 'mocks/search/generalSearch';
import getKeywordsSearchResults from 'mocks/search/keywordSearch';
import getIcdCodesSearchResults from 'mocks/search/icdCodeSearch';
import getRequests from './requests/getRequests';
import getRequest from './requests/getRequest';
import performRequestAction from './requests/performRequestAction';

const delay = 200;

type RestHandlerFactory = ({ delay }?: { delay: number }) => RestHandler[];
const restHandlers: RestHandlerFactory[] = [
    getKeywordsSearchResults,
    getIcdCodesSearchResults,
    getGeneralSearchResults,
    loginHappyPath,
    forgotPassword,
    requestAccess,
    getUsersHappyPath,
    currentUserHappyPath,
    addUserHappyPath,
    requestAccess,
    editUserHappyPath,
    deleteUserHappyPath,
    editAccountHappyPath,
    changePasswordHappyPath,
    getQuestionnairesSummary,
    getQuestionnaireCategories,
    createCustomQuestionnaire,
    getItemClassificationDetails,
    getQuestionnaireDetails,
    getWithTranslation,
    publishQuestionnaire,
    deleteQuestionnaire,
    updateQuestionnaireItem,
    deleteQuestionnaireItem,
    editQuestionnaireItem,
    getTimeScale,
    getResponseScale,
    addQuestionnaireItem,
    downloadCsv,
    getLanguages,
    createLanguage,
    updateQuestionnaire,
    getAuthors,
    itemsSearch,
    setRecommendedWording,
    bulkAddQuestionnaireItems,
    updateItem,
    deleteLanguage,
    bulkAddTranslations,
    editTranslation,
    addToVerifiedLanguages,
    deleteItem,
    updateRecommendedWording,
    getUsage,
    getRequests,
    getRequest,
    performRequestAction,
];

export default restHandlers.reduce<RestHandler[]>((prev, curr) => [...prev, ...curr({ delay })], []);
