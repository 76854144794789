import { Alert as BsAlert, Badge, CloseButton } from 'react-bootstrap';
import styles from './alertbottom.module.scss';
import AlertType from '_types/appState/Alert';
import useAlerts from 'hooks/useAlerts';
import { useState, useCallback, useEffect } from 'react';
import useTimeout from 'hooks/useTimeout';

export type AlertProps = {
    alert: AlertType;
    offSet: number;
};

const Alert = ({ alert, offSet }: AlertProps) => {
    const heightOffSet = 65;
    const [bottomOffSet, setBottomOffSet] = useState(offSet * heightOffSet);
    const { message, type, count } = alert;
    const { removeAlert } = useAlerts();

    const onClose = useCallback(() => {
        setBottomOffSet(-80);
        // We need timeout since transitions duration is 0.5s
        const timeoutID = setTimeout(() => removeAlert(alert), 500);
        return () => clearTimeout(timeoutID);
    }, [alert, removeAlert]);

    useTimeout(onClose, alert.isAutomaticallyDismissed ? 2000 : null);

    useEffect(() => {
        setBottomOffSet(offSet * heightOffSet);
    }, [offSet]);

    return (
        <BsAlert variant={type} onClick={() => 1} className={styles.container} style={{ bottom: `${bottomOffSet}px` }}>
            <div className={styles.contentWrapper}>
                <p>
                    {message}
                    {count > 1 && (
                        <Badge bg={type} className="ms-4">
                            {count}
                        </Badge>
                    )}
                </p>
                <CloseButton className={styles.close} onClick={onClose} />
            </div>
        </BsAlert>
    );
};

export default Alert;
