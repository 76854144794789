/* eslint-disable @typescript-eslint/no-unsafe-assignment,react/jsx-props-no-spreading */
import FormModal from 'components/_modal/FormModal';
import Field from 'components/_form/Field';
import { Form } from 'react-bootstrap';
import { updatePasswordFormModalSchema, updatePasswordFormModalDefaultValues } from './schema';
import UpdatePassword from '_types/api/UpdatePassword';
import MessageBox from 'components/_modal/MessageBox';
import useCurrentUser from 'hooks/useCurrentUser';

export type ChangePasswordFormModalProps = {
    toggle: () => void;
    isShowing: boolean;
};
const ChangePasswordFormModal = ({ toggle, isShowing }: ChangePasswordFormModalProps) => {
    const { updatePassword, isPasswordUpdated, resetSuccess } = useCurrentUser();

    const onSubmit = async (data: UpdatePassword) => {
        await updatePassword(data);
    };

    const onComplete = () => {
        resetSuccess();
    };

    return isPasswordUpdated ? (
        <MessageBox title="Password changed!" onComplete={onComplete} isShowing={isShowing} toggle={toggle}>
            <p>You can now use your new password to log in.</p>
        </MessageBox>
    ) : (
        <FormModal
            title="Change password"
            toggle={toggle}
            isShowing={isShowing}
            validationSchema={updatePasswordFormModalSchema}
            submitButtonText="Save"
            initialValues={updatePasswordFormModalDefaultValues}
            onSubmit={onSubmit}
            onComplete={() => {}}
            customSize
        >
            {({ register, formState: { errors } }) => (
                <>
                    <Field label="current password" id="currentPassword" feedback={errors.currentPassword}>
                        <Form.Control
                            autoComplete="off"
                            type="password"
                            {...register('currentPassword')}
                            placeholder=""
                            isInvalid={!!errors.currentPassword}
                        />
                    </Field>
                    <Field label="new password" id="newPassword" feedback={errors.newPassword}>
                        <Form.Control
                            type="password"
                            {...register('newPassword')}
                            placeholder=""
                            isInvalid={!!errors.newPassword}
                        />
                    </Field>
                    <Field label="confirm password" id="confirmPassword" feedback={errors.confirmPassword}>
                        <Form.Control
                            type="password"
                            {...register('confirmPassword')}
                            isInvalid={!!errors.confirmPassword}
                        />
                    </Field>
                </>
            )}
        </FormModal>
    );
};

export default ChangePasswordFormModal;
