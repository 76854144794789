const questionnairesPath = '/questionnaires';
export default questionnairesPath;

export const downloadCsvPath = '/download-csv';

export const downloadTranslationsPath = '/download-translations';

export const submitForApprovalPath = '/submit-for-approval';

export const cancelSubmitForApprovalPath = '/cancel-submit-for-approval';

export const publishQuestionnairePath = '/publish';

export const rejectQuestionnairePath = '/reject';
