import { atom } from 'jotai';
import Alert from '_types/appState/Alert';
import { AlertType } from '_types/appState/AlertType';
import { useImmerAtom } from 'jotai/immer';

export const alertsAtom = atom<Alert[]>([]);

export default function useAlerts() {
    const [alerts, setAlerts] = useImmerAtom(alertsAtom);

    const addAlert = (message: string, type: AlertType, isAutomaticallyDismissed = true) => {
        setAlerts((alerts) => {
            const trimmedMessage = message.trim();
            // get alert if already existing
            const alreadyExistingAlert = alerts.find(
                (alert: Alert) => alert.message === trimmedMessage && alert.type === type,
            );
            if (alreadyExistingAlert) {
                alreadyExistingAlert.count += 1;
            } else {
                alerts.push({
                    message: trimmedMessage,
                    type,
                    count: 1,
                    id: type + trimmedMessage,
                    isAutomaticallyDismissed,
                });
            }
        });
    };

    const removeAlert = (alert: Alert) => {
        setAlerts(
            (alerts) => (alerts = alerts.filter((a: Alert) => a.message !== alert.message || a.type !== alert.type)),
        );
    };

    return {
        alerts,
        addInfoAlert: (message: string) => addAlert(message, AlertType.Info),
        addWarningAlert: (message: string) => addAlert(message, AlertType.Warning, false),
        addErrorAlert: (message: string) => addAlert(message, AlertType.Error, false),
        addUnexpectedServerErrorAlert: () =>
            addAlert('An unexpected internal server occurred. Please try again later.', AlertType.Error, false),
        addSuccessAlert: (message: string) => addAlert(message, AlertType.Success),
        removeAlert: removeAlert,
    };
}
