import Cookies from 'js-cookie';
import accessTokenCookieName from 'consts/auth/accessTokenCookieName';
import { atom } from 'jotai';
import { useImmerAtom } from 'jotai/immer';
import UserSession from '_types/appState/UserSession';
import axios, { AxiosResponse } from 'axios';
import AccessToken from '_types/api/AccessToken';
import apiBaseUrl from 'consts/api/apiBaseUrl';
import authTokenSubPath from 'consts/paths/api/auth/token';
import { WritableDraft } from 'immer/dist/types/types-external';
const apiUrl = `${apiBaseUrl}${authTokenSubPath}`;
import { useQueryClient } from 'react-query';
import { useRouter } from 'next/router';

const accessTokenFromCookie = Cookies.get(accessTokenCookieName);
export const sessionAtom = atom<UserSession>({
    accessToken: accessTokenFromCookie,
    isAuthenticated: !!accessTokenFromCookie,
    isUnauthorized: false,
});

async function getAccessToken(username: string, password: string) {
    const bodyFormData = new URLSearchParams({
        username,
        password,
        grant_type: 'password',
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: AxiosResponse<AccessToken> = await axios({
        method: 'post',
        url: apiUrl,
        data: bodyFormData.toString(),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });

    return response.data.access_token;
}

function removeAccessToken(userSession: WritableDraft<UserSession>) {
    Cookies.remove(accessTokenCookieName);
    userSession.accessToken = null;
    userSession.isAuthenticated = false;
}

function useUserSession() {
    const [{ isAuthenticated, accessToken, isUnauthorized }, setUserSession] = useImmerAtom<UserSession, void>(
        sessionAtom,
    );

    const router = useRouter();
    const queryClient = useQueryClient();

    const logOn = async (username: string, password: string) => {
        const accessToken = await getAccessToken(username, password);
        Cookies.set(accessTokenCookieName, accessToken);

        setUserSession((userSession) => {
            userSession.isUnauthorized = false;
            userSession.accessToken = accessToken;
            userSession.isAuthenticated = true;
        });
    };

    const logOut = async () => {
        // avoid having the returnUrl set on logout
        await router.replace('/', undefined, { shallow: true });
        setUserSession((userSession) => {
            removeAccessToken(userSession);
        });
        // clear react-query caches
        queryClient.clear();
    };

    const invalidateAccessToken = async () => {
        setUserSession((userSession) => {
            removeAccessToken(userSession);
        });
    };

    const setIsAuthenticated = async (value: boolean) => {
        setUserSession((userSession) => {
            userSession.isAuthenticated = value;
        });
    };

    return {
        isAuthenticated,
        accessToken,
        isUnauthorized,
        logOn,
        logOut,
        invalidateAccessToken,
        setIsAuthenticated,
    };
}

export default useUserSession;
