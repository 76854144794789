import { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import AuthLoading from 'components/_auth/AuthLoading';

import Error403 from 'components/_errors/403';
import useCurrentUserPermissions from 'hooks/useCurrentUserPermissions';
import useUserSession from 'hooks/useUserSession';

type Props = {
    children: ReactNode;
};
export default function RouteGuard({ children }: Props) {
    const router = useRouter();
    const allowedAnonymousPaths = ['/', '/_error', '/reset-password'];

    const { isAuthenticated } = useUserSession();

    const { currentUser, canAccessPage, arePermissionsLoaded } = useCurrentUserPermissions();

    useEffect(() => {
        // cannot access page -> redirect with return url
        if (!isAuthenticated && !allowedAnonymousPaths.includes(router.pathname)) {
            void router.push({
                pathname: '/',
                query: { returnUrl: router.asPath },
            });
        }
        // eslint-disable-next-line
    }, [router, isAuthenticated]);

    if (
        // not authenticated and will be redirected to home
        (!allowedAnonymousPaths.includes(router.pathname) && !currentUser && router.pathname !== '/') ||
        // authenticated but not ready yet to access or evaluate rights
        (isAuthenticated && (!currentUser || !arePermissionsLoaded))
    ) {
        return <AuthLoading />;
    }

    if (
        // authenticated but cannot access the page
        isAuthenticated &&
        !canAccessPage(router.pathname) &&
        // and not yet on the error page
        router.pathname !== '/_error'
    ) {
        return <Error403 />;
    }

    if (isAuthenticated && router.query.returnUrl) {
        void router.push(router.query.returnUrl.toString());
        return <AuthLoading />;
    }

    return <>{children}</>;
}
