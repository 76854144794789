import LoggedInHeader from 'layout/Header/LoggedInHeader';
import { ReactNode } from 'react';
import LoggedInFooter from 'layout/Footer/LoggedInFooter';
import styles from './loggedInLayout.module.scss';
import ErrorBoundary from 'components/_errors/ErrorBoundary';

export type LoggedInLayoutProps = {
    children: ReactNode;
};

const LoggedInLayout = ({ children }: LoggedInLayoutProps) => (
    <>
        <LoggedInHeader />
        <ErrorBoundary>
            <div className={styles.wrapper}>{children}</div>
        </ErrorBoundary>
        <LoggedInFooter />
    </>
);

export default LoggedInLayout;
