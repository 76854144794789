import { rest } from 'msw';
import questionnairesPath from 'consts/paths/api/questionnaires/questionnairesPath';
import resolvePath from 'mocks/resolvePath';
export default ({ delay = 1500 } = {}) => {
    return [
        rest.delete(resolvePath(`${questionnairesPath}/*/languages/*`), (req, res, ctx) => {
            return res(ctx.delay(delay), ctx.status(200), ctx.json({}));
        }),
    ];
};
