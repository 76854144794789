import { rest } from 'msw';
import usersPath from 'consts/paths/api/auth/users/users';
import resolvePath from 'mocks/resolvePath';

export default ({ delay = 0 } = {}) => [
    rest.get(resolvePath(usersPath), (req, res, ctx) =>
        res(
            ctx.delay(delay),
            ctx.status(200),
            ctx.json([
                {
                    id: 0,
                    email: 'Test@gladiator.com',
                    userName: 'test',
                    profile: 'Test emperor',
                    name: 'Test aurelius',
                    status: 'suspended',
                    country: 'IT',
                    organisation: 'Government',
                    motivation:
                        'You have power over your mind - not outside events. Realize this, and you will find strength.',
                },
                {
                    id: 1,
                    email: 'maximus@gladiator.com',
                    userName: 'Maximus',
                    profile: 'administrator',
                    name: 'Maximus Decimus Meridius',
                    status: 'approved',
                    country: 'ES',
                    organisation: 'Army',
                    motivation: ' revenge against Commodus',
                },
                {
                    id: 2,
                    email: 'Marcus@gladiator.com',
                    userName: 'Marc',
                    profile: 'True emperor',
                    name: 'marcus aurelius',
                    status: 'pending',
                    country: 'IT',
                    organisation: 'Government',
                    motivation:
                        'You have power over your mind - not outside events. Realize this, and you will find strength.',
                },
                {
                    id: 3,
                    email: 'Commodus@gladiator.com',
                    userName: 'Commodus',
                    profile: 'administrator',
                    name: 'Commodus',
                    status: 'suspended',
                    country: 'IT',
                    organisation: 'Throne',
                    motivation: 'Not good..',
                },
                {
                    id: 4,
                    email: 'Jake@gladiator.com',
                    userName: 'Jake',
                    profile: 'Jake',
                    name: 'Jake',
                    status: 'pending',
                    country: 'IT',
                    organisation: 'Frontend',
                    motivation:
                        'You have power over your mind - not outside events. Realize this, and you will find strength.',
                },
            ]),
        ),
    ),
];
