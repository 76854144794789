import { rest } from 'msw';
import questionnaireItemsPath from 'consts/paths/api/questionnaires/questionnaireItemsPath';
import resolvePath from 'mocks/resolvePath';
export default ({ delay = 1500 } = {}) => {
    return [
        rest.put(resolvePath(`${questionnaireItemsPath}/*`), (req, res, ctx) => {
            return res(
                ctx.delay(delay),
                ctx.status(200),
                ctx.json({
                    questionnaire: 18,
                    item: 311,
                    position: 2,
                    wording: 'Have you had numbness or tingling in your arm or shoulder?',
                    comment: null,
                    relatedQuestions: [],
                    id: 458,
                    createDate: '2016-03-01T11:52:04.853',
                    updateDate: '2022-03-11T09:30:34.8381178+00:00',
                }),
            );
        }),
    ];
};
