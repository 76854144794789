import Map from '../../_types/Map';

function omitFalsyFields(obj: Map<unknown>) {
    return [...Object.keys(obj)].reduce<Map<unknown>>((ret, key) => {
        const newRet: Map<unknown> = {
            ...ret,
        };
        if (obj[key]) {
            if (obj[key] === true) {
                newRet[key] = true;
            } else if (obj[key] !== false) {
                newRet[key] = omitFalsyFields(obj[key] as Map<unknown>);
            }
        }
        return newRet;
    }, {});
}

export default omitFalsyFields;
