import { useQuery } from 'react-query';
import useApi from 'hooks/useApi';
import authorsPath from 'consts/paths/api/authors';
import { QuestionnaireAuthor } from '_types/api/questionnaires/Author';

const useQuestionnaireAuthors = () => {
    const api = useApi();
    const authorsQueryKey = 'authors';
    const {
        data: questionnaireAuthors,
        error,
        isLoading,
    } = useQuery(authorsQueryKey, async () => {
        const result = await api.get<QuestionnaireAuthor[]>(authorsPath);
        return result?.data;
    });

    return { questionnaireAuthors, error, isLoading };
};

export default useQuestionnaireAuthors;
