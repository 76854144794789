import { rest } from 'msw';
import questionnairesPath, { downloadCsvPath } from 'consts/paths/api/questionnaires/questionnairesPath';
import resolvePath from 'mocks/resolvePath';

export default ({ delay = 1500 } = {}) => {
    return [
        rest.get(resolvePath(`${questionnairesPath}/*${downloadCsvPath}`), (req, res, ctx) => {
            return res(
                ctx.delay(delay),
                ctx.status(200),
                ctx.text(
                    'Questionnaire,ItemId,Type,Position,Code,Wording,Translation,RelatedQuestions ADB - Official #1,616,Question,1,Q522,Did tiredness interfere with your daily activities?,, ADB - Official #1,7,Question,2,Q4,"Do you have any trouble doing strenuous activities, like carrying a heavy shopping bag or a suitcase ? ",,',
                ),
            );
        }),
    ];
};
