import { Modal as BsModal } from 'react-bootstrap';
import { ReactNode } from 'react';
import styles from './modal.module.scss';

type Props = {
    isShowing: boolean;
    children: ReactNode;
    size?: 'sm' | 'lg' | 'xl';
    dialogClassName?: string;
};
const Modal = ({ isShowing, children, size, dialogClassName }: Props) => (
    <BsModal show={isShowing} size={size} dialogClassName={`${styles.modalDefault}  ${dialogClassName || ''}`}>
        {children}
    </BsModal>
);

export default Modal;
