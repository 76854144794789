import { rest } from 'msw';
import resolvePath from 'mocks/resolvePath';
import items from './items.json';
export default ({ delay = 1500 } = {}) => {
    return [
        rest.get(resolvePath('/items/search'), (req, res, ctx) => {
            return res(ctx.delay(delay), ctx.status(200), ctx.json(items));
        }),
    ];
};
