import { rest } from 'msw';
import resolvePath from 'mocks/resolvePath';
import keywords from './keywords.json';
import itemsPath from 'consts/paths/api/items/getItems';
import { KEYWORDS } from 'consts/itemDetails';

export default ({ delay = 1000 } = {}) => [
    rest.get(resolvePath(`${itemsPath}/${KEYWORDS}/search*`), (req, res, ctx) =>
        res(ctx.delay(delay), ctx.status(200), ctx.json(keywords)),
    ),
];
