import { rest } from 'msw';
import itemsPath from 'consts/paths/api/items/getItems';
import resolvePath from 'mocks/resolvePath';
import item from '../getItem/item.json';

export default ({ delay = 1500 } = {}) => {
    return [
        rest.put(resolvePath(`${itemsPath}/*`), (req, res, ctx) => {
            return res(ctx.delay(delay), ctx.status(200), ctx.json(item));
        }),
    ];
};
