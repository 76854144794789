import Error500 from '../500';
import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';

type ErrorBoundaryProps = {
    children: ReactNode;
};

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
    return (
        <Sentry.ErrorBoundary fallback={Error500} showDialog>
            {children}
        </Sentry.ErrorBoundary>
    );
};

export default ErrorBoundary;
