import { Alert } from 'react-bootstrap';
import styles from './login-info.module.scss';

export type WarningProps = {
    show: boolean;
    text: string;
};
const Warning = ({ show, text }: WarningProps) => (
    <Alert variant="danger" className={show ? styles.alert : 'd-none'}>
        <span className={styles.alertIcon} />
        {text}
    </Alert>
);

export default Warning;
