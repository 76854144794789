import useCountries from 'hooks/useCountries';
import FormSelect, { FormSelectPropsWithoutItems } from '..';

function FormCountrySelect<TValues>(props: FormSelectPropsWithoutItems<TValues>) {
    const { countries } = useCountries();
    return (
        <FormSelect
            {...props}
            isHidingEmptyOptions={true}
            items={countries?.map(({ code, name }) => ({ label: name, value: code }))}
        />
    );
}

export default FormCountrySelect;
