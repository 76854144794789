import { rest } from 'msw';
import itemsPath from 'consts/paths/api/items/getItems';
import recommendedWordingPath from 'consts/paths/api/items/recommendedWording';
import resolvePath from 'mocks/resolvePath';

export default ({ delay = 500 } = {}) => [
    rest.put(resolvePath(`${itemsPath}/*${recommendedWordingPath}/*`), (req, res, ctx) =>
        res(ctx.delay(delay), ctx.status(200), ctx.json({})),
    ),
];
