import { useEffect, useState } from 'react';
import useApi from 'hooks/useApi';
import styles from './typeaheadWithApiCall.module.scss';
import { ReactBootstrapTypeaheadWrapperProps } from '../Typeahead/ReactBootstrapTypeaheadWrapper';
import { Path, UseFormSetValue } from 'react-hook-form';
import Typeahead from 'components/_form/Typeahead';
import { UseControllerProps } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { Option } from 'react-bootstrap-typeahead/types/types';
import useDebounce from 'hooks/useDebounce';
import { BulletList as Loader } from 'react-content-loader';

export type AsyncTypeaheadApiConfig<TParam> = {
    url: string;
    maxResults?: number;
    params?: { [key in string]: TParam };
};

type TypeaheadWithApiCallProps<TOption, TForm extends FieldValues, TParam> = Omit<
    ReactBootstrapTypeaheadWrapperProps<TOption>,
    'onChange' | 'options'
> &
    UseControllerProps<TForm> & {
        setValue: UseFormSetValue<TForm>;
        defaultSelected?: Option[];
        name: string | Path<TForm>;
        error?: string | undefined;
        infoText: string;
        allowNew?: boolean;
        apiConfig: AsyncTypeaheadApiConfig<TParam> | AsyncTypeaheadApiConfig<TParam>[];
        multiple?: boolean;
        categoriesType?: number;
    };

function TypeaheadWithApiCall<TOption, TForm, TParam>({
    name,
    error = '',
    defaultSelected = [],
    infoText,
    allowNew = false,
    apiConfig,
    categoriesType,
    ...props
}: TypeaheadWithApiCallProps<TOption, TForm, TParam>) {
    const api = useApi();
    const [values, setValues] = useState<TOption[]>([]);
    const [query, setQuery] = useState<string>('');
    const debounce = useDebounce(query);
    const [isLoading, setIsLoading] = useState(false);

    const request = async ({ url, maxResults, params }: AsyncTypeaheadApiConfig<TParam>) => {
        const queryParams = new URLSearchParams({ q: query, ...params }).toString();
        let result;
        if (categoriesType) {
            result = await api.get(`${url}?type=${categoriesType}&${queryParams}`);
        } else {
            result = await api.get(`${url}?${queryParams}`);
        }

        const filteredResult = result?.data.slice(0, maxResults);
        return { ...result, data: filteredResult };
    };

    const fetchData = async (query: string) => {
        setIsLoading(true);
        if (query.trim().length === 0) {
            return;
        }
        const results = await Promise.all(([] as AsyncTypeaheadApiConfig<TParam>[]).concat(apiConfig).map(request));
        const mappedValues = results?.flatMap((r) => r.data) || [];
        setValues(mappedValues as TOption[]);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData(query);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounce]);
    return (
        <>
            <Typeahead
                name={name}
                minLength={1}
                id={name}
                options={values}
                onInputChange={setQuery}
                isInvalid={!!error}
                emptyLabel={isLoading ? <Loader height={250} /> : null}
                defaultSelected={defaultSelected}
                allowNew={allowNew}
                isWithApiCall={true}
                {...props}
            />
            <p className={styles.helpInfoText}>{infoText}</p>
            <p className={styles.fieldError}> {error}</p>
        </>
    );
}

export default TypeaheadWithApiCall;
