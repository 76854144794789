import { Nav } from 'react-bootstrap';
import Link from 'next/link';
import { ReactNode } from 'react';
import { NavLinkProps as BsNavLinkProps } from 'react-bootstrap/NavLink';

type NavLinkProps = BsNavLinkProps & {
    href: string;
    children: ReactNode;
};

function NavLink({ href, children, ...props }: NavLinkProps) {
    return (
        <Link href={href} passHref>
            <Nav.Link {...props}>{children}</Nav.Link>
        </Link>
    );
}

export default NavLink;
