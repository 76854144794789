import Link from 'next/link';
import { Container, Row } from 'react-bootstrap';
import styles from './500.module.scss';

export default function Error500() {
    return (
        <Container className={styles.wrapper}>
            <Row>
                <h1>Something went wrong</h1>
                <p className="lead">Please try again later.</p>
                <p>
                    <Link href="/">Back home</Link>
                </p>
            </Row>
        </Container>
    );
}
