import { rest } from 'msw';
import requestAccessPath from 'consts/paths/api/auth/requestAccess';
import resolvePath from '../resolvePath';

export default ({ delay = 0 } = {}) => [
    rest.post(resolvePath(requestAccessPath), (req, res, ctx) =>
        res(
            ctx.delay(delay),
            ctx.status(200),
            ctx.json({
                id: 72,
                roles: ['viewTranslations', 'viewContent', 'manageOwnCustom'],
            }),
        ),
    ),
];
