import { rest } from 'msw';
import itemsPath from 'consts/paths/api/items/getItems';
import recommendedWordingPath from 'consts/paths/api/items/recommendedWording';
import resolvePath from 'mocks/resolvePath';
export default ({ delay = 0 } = {}) => {
    return [
        rest.post(resolvePath(`${itemsPath}/*${recommendedWordingPath}`), (req, res, ctx) => {
            return res(
                ctx.delay(delay),
                ctx.status(200),
                ctx.json({
                    item: 15,
                    language: 'en',
                    wording: 'Please go on to the next page',
                    id: 60,
                    createDate: '2022-04-29T06:50:26.8088909+00:00',
                    updateDate: '2022-04-29T06:50:26.8088909+00:00',
                }),
            );
        }),
    ];
};
