import { useQuery, useQueryClient, useMutation } from 'react-query';
import CurrentUser from '_types/api/CurrentUser';
import path from 'consts/paths/api/users/current-user';
import useApi from '../useApi';
import useUserSession from 'hooks/useUserSession';
import updatePasswordPath from 'consts/paths/api/auth/updatePassword';
import UpdatePassword from '_types/api/UpdatePassword';

export default function useCurrentUser() {
    const api = useApi();
    const queryClient = useQueryClient();
    const currentUserKey = 'current-user';
    const { isAuthenticated } = useUserSession();

    const { data: currentUser } = useQuery<CurrentUser>(
        currentUserKey,
        async (): Promise<CurrentUser> => {
            const { data } = await api.get<CurrentUser>(path);
            return data;
        },
        { enabled: isAuthenticated },
    );

    const updateCurrentUser = async (user: CurrentUser) => {
        await api.put(`${path}/${user.id}`, user);
        queryClient.invalidateQueries(currentUserKey);
    };

    const {
        isSuccess: isPasswordUpdated,
        mutate: updatePassword,
        reset: resetSuccess,
    } = useMutation(async (data: UpdatePassword) => {
        await api.post(updatePasswordPath, data);
    });

    return {
        currentUser: currentUser as CurrentUser,
        updateCurrentUser,
        isPasswordUpdated,
        updatePassword,
        resetSuccess,
    };
}
