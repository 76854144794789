import FormSelect, { FormSelectItem, FormSelectPropsWithoutItems } from '..';
import QuestionnaireState from '_types/api/questionnaires/QuestionnaireState';

function QuestionnaireStateSelect<TValue>(props: FormSelectPropsWithoutItems<TValue>) {
    const types: FormSelectItem[] = [
        { label: 'Draft', value: QuestionnaireState.DRAFT },
        { label: 'Waiting for approval', value: QuestionnaireState.WAITING_FOR_APPROVAL },
        { label: 'Published', value: QuestionnaireState.PUBLISHED },
    ];

    return <FormSelect {...props} items={types} isHidingEmptyOptions={true} />;
}

export default QuestionnaireStateSelect;
