import { ReactNode } from 'react';
import { Nav } from 'react-bootstrap';
import styles from './footer.module.scss';

export type FooterBaseProps = {
    children?: ReactNode;
};

const FooterBase = ({ children }: FooterBaseProps) => {
    const items = [
        { link: 'https://www.eortc.org/', text: 'EORTC website', id: 0 },
        {
            link: 'https://qol.eortc.org',
            text: 'EORTC Quality of Life Group',
            id: 1,
        },
        { link: 'mailto:itemlibrary@eortc.org', text: 'Contact us', id: 2 },
    ];
    return (
        <footer className={styles.footer}>
            {children}
            <Nav className={styles.navbar}>
                {items.map((item) => (
                    <Nav.Item className={styles.navitem} key={item.id}>
                        <Nav.Link href={item.link}>{item.text}</Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
        </footer>
    );
};

export default FooterBase;
