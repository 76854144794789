import { AxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default <T>(candidate: any): AxiosError<T> => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (candidate?.isAxiosError === true) {
        return candidate as AxiosError<T>;
    }
    throw candidate;
};
