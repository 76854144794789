import { rest } from 'msw';
import itemsCategoriesPath from 'consts/paths/api/items/getCategories';
import resolvePath from 'mocks/resolvePath';

export default ({ delay = 0 } = {}) => [
    rest.get(resolvePath(itemsCategoriesPath), (req, res, ctx) =>
        res(
            ctx.delay(delay),
            ctx.status(200),
            // XXL data
            ctx.json([
                {
                    categoryId: 1,
                    categoryName: 'Activities of Daily Living',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 10,
                            code: 'Q7',
                            description: 'RF08 limit work daily activities',
                            categoryId: 1,
                        },
                        {
                            itemId: 67,
                            code: 'Q53',
                            description: 'urinary problems daily activities',
                            categoryId: 1,
                        },
                        {
                            itemId: 68,
                            code: 'Q54',
                            description: 'bowel problems daily activities',
                            categoryId: 1,
                        },
                        {
                            itemId: 266,
                            code: 'Q206',
                            description: 'modify daily activities',
                            categoryId: 1,
                        },
                        {
                            itemId: 369,
                            code: 'Q298',
                            description: 'worry change daily life',
                            categoryId: 1,
                        },
                        {
                            itemId: 371,
                            code: 'Q300',
                            description: 'problem usual activities weight loss',
                            categoryId: 1,
                        },
                        {
                            itemId: 412,
                            code: 'Q339',
                            description: 'activities limited vision',
                            categoryId: 1,
                        },
                        {
                            itemId: 420,
                            code: 'Q346',
                            description: 'problem driving daylight',
                            categoryId: 1,
                        },
                        {
                            itemId: 421,
                            code: 'Q347',
                            description: 'problem driving dark ',
                            categoryId: 1,
                        },
                        {
                            itemId: 458,
                            code: 'Q381',
                            description: 'problem carry usual activities tired',
                            categoryId: 1,
                        },
                        {
                            itemId: 616,
                            code: 'Q520',
                            description: 'tired interference daily activities',
                            categoryId: 1,
                        },
                        {
                            itemId: 658,
                            code: 'Q555',
                            description: 'drug intake interference daily life',
                            categoryId: 1,
                        },
                        {
                            itemId: 707,
                            code: 'Q594',
                            description: 'scar pain daily activities',
                            categoryId: 1,
                        },
                        {
                            itemId: 800,
                            code: 'Q675',
                            description: 'RF04 need help work daily activity',
                            categoryId: 1,
                        },
                    ],
                },
                {
                    categoryId: 2,
                    categoryName: 'Basic ADL',
                    parentId: 1,
                    parentCategoryName: 'Activities of Daily Living',
                    items: [
                        {
                            itemId: 18,
                            code: 'Q9',
                            description: 'PF05 help with daily living',
                            categoryId: 2,
                        },
                        {
                            itemId: 753,
                            code: 'Q628',
                            description: 'PF03 need help footcare',
                            categoryId: 2,
                        },
                        {
                            itemId: 755,
                            code: 'Q630',
                            description: 'PF06 help grooming',
                            categoryId: 2,
                        },
                        {
                            itemId: 759,
                            code: 'Q634',
                            description: 'PF10',
                            categoryId: 2,
                        },
                        {
                            itemId: 762,
                            code: 'Q637',
                            description: 'PF14 need help put on shirt',
                            categoryId: 2,
                        },
                        {
                            itemId: 767,
                            code: 'Q642',
                            description: 'PF21 need help wash face hands',
                            categoryId: 2,
                        },
                        {
                            itemId: 772,
                            code: 'Q647',
                            description: 'PF27 need help undress',
                            categoryId: 2,
                        },
                        {
                            itemId: 774,
                            code: 'Q649',
                            description: 'PF28 need help eat',
                            categoryId: 2,
                        },
                        {
                            itemId: 872,
                            code: 'Q746',
                            description: 'FA32 exhausted dress',
                            categoryId: 2,
                        },
                        {
                            itemId: 874,
                            code: 'Q748',
                            description: 'FA34 exhausted shower',
                            categoryId: 2,
                        },
                        {
                            itemId: 918,
                            code: 'Q792',
                            description: 'DY01 short breath wash',
                            categoryId: 2,
                        },
                        {
                            itemId: 932,
                            code: 'Q806',
                            description: 'DY16 short breath dress',
                            categoryId: 2,
                        },
                        {
                            itemId: 1098,
                            code: 'Q857',
                            description: 'clean oneself',
                            categoryId: 2,
                        },
                    ],
                },
                {
                    categoryId: 3,
                    categoryName: 'Instrumental ADL',
                    parentId: 1,
                    parentCategoryName: 'Activities of Daily Living',
                    items: [
                        {
                            itemId: 49,
                            code: 'Q37',
                            description: 'help with chores',
                            categoryId: 3,
                        },
                        {
                            itemId: 629,
                            code: 'Q529',
                            description: 'limited travel',
                            categoryId: 3,
                        },
                        {
                            itemId: 797,
                            code: 'Q672',
                            description: 'RF01 limit household tasks',
                            categoryId: 3,
                        },
                        {
                            itemId: 798,
                            code: 'Q673',
                            description: 'RF02 limit light housework',
                            categoryId: 3,
                        },
                        {
                            itemId: 802,
                            code: 'Q677',
                            description: 'RF06 limit repairs maintenance',
                            categoryId: 3,
                        },
                        {
                            itemId: 803,
                            code: 'Q678',
                            description: 'RF07 limit finances',
                            categoryId: 3,
                        },
                        {
                            itemId: 804,
                            code: 'Q679',
                            description: 'RF09 limit heavy household',
                            categoryId: 3,
                        },
                        {
                            itemId: 859,
                            code: 'Q733',
                            description: 'FA12 shopping exhausting',
                            categoryId: 3,
                        },
                    ],
                },
                {
                    categoryId: 4,
                    categoryName: 'Body Image',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 1,
                            code: 'Q1',
                            description: 'less masculine',
                            categoryId: 4,
                        },
                        {
                            itemId: 130,
                            code: 'Q95',
                            description: 'problem appearance',
                            categoryId: 4,
                        },
                        {
                            itemId: 148,
                            code: 'Q109',
                            description: 'less feminine',
                            categoryId: 4,
                        },
                        {
                            itemId: 169,
                            code: 'Q128',
                            description: 'physically less attractive',
                            categoryId: 4,
                        },
                        {
                            itemId: 170,
                            code: 'Q129',
                            description: 'problems look naked',
                            categoryId: 4,
                        },
                        {
                            itemId: 171,
                            code: 'Q130',
                            description: 'dissatisfied body',
                            categoryId: 4,
                        },
                        {
                            itemId: 248,
                            code: 'Q189',
                            description: 'less feminine/masculine ',
                            categoryId: 4,
                        },
                        {
                            itemId: 323,
                            code: 'Q260',
                            description: 'feel less sexually attractive',
                            categoryId: 4,
                        },
                        {
                            itemId: 327,
                            code: 'Q264',
                            description: 'satisfied size breast',
                            categoryId: 4,
                        },
                        {
                            itemId: 328,
                            code: 'Q265',
                            description: 'satisfied shape breast',
                            categoryId: 4,
                        },
                        {
                            itemId: 329,
                            code: 'Q266',
                            description: 'satisfied skin appearance breast',
                            categoryId: 4,
                        },
                        {
                            itemId: 330,
                            code: 'Q267',
                            description: 'satisfied symmetry breasts',
                            categoryId: 4,
                        },
                        {
                            itemId: 331,
                            code: 'Q268',
                            description: 'satisfied cleavage',
                            categoryId: 4,
                        },
                        {
                            itemId: 333,
                            code: 'Q270',
                            description: 'satisfied appearance nipple',
                            categoryId: 4,
                        },
                        {
                            itemId: 335,
                            code: 'Q272',
                            description: 'satisfied scars appearance breast',
                            categoryId: 4,
                        },
                        {
                            itemId: 337,
                            code: 'Q274',
                            description: 'satisfied breast reconstruction result ',
                            categoryId: 4,
                        },
                        {
                            itemId: 341,
                            code: 'Q278',
                            description: 'satisfied scars appearance',
                            categoryId: 4,
                        },
                        {
                            itemId: 342,
                            code: 'Q279',
                            description: 'problem loss of nipple',
                            categoryId: 4,
                        },
                        {
                            itemId: 367,
                            code: 'Q296',
                            description: 'worry appearance',
                            categoryId: 4,
                        },
                        {
                            itemId: 398,
                            code: 'Q325',
                            description: 'dissatisfied cosmetic surgery',
                            categoryId: 4,
                        },
                        {
                            itemId: 501,
                            code: 'Q415',
                            description: 'lack confidence body',
                            categoryId: 4,
                        },
                        {
                            itemId: 613,
                            code: 'Q517',
                            description: 'lack self confidence',
                            categoryId: 4,
                        },
                        {
                            itemId: 643,
                            code: 'Q543',
                            description: 'worry appearance abdomen',
                            categoryId: 4,
                        },
                        {
                            itemId: 789,
                            code: 'Q664',
                            description: 'EF14 lose interest appearance',
                            categoryId: 4,
                        },
                        {
                            itemId: 1227,
                            code: 'Q915',
                            description: 'satisfied cosmetic result surgery',
                            categoryId: 4,
                        },
                    ],
                },
                {
                    categoryId: 5,
                    categoryName: 'Bones, Muscles, & Joints',
                    parentId: null,
                    parentCategoryName: null,
                    items: [],
                },
                {
                    categoryId: 6,
                    categoryName: 'Muscle weakness',
                    parentId: 5,
                    parentCategoryName: 'Bones, Muscles, & Joints',
                    items: [
                        {
                            itemId: 405,
                            code: 'Q332',
                            description: 'problem open weak hands',
                            categoryId: 6,
                        },
                        {
                            itemId: 408,
                            code: 'Q335',
                            description: 'problem stairs chair weak legs',
                            categoryId: 6,
                        },
                        {
                            itemId: 411,
                            code: 'Q338',
                            description: 'problem use pedals',
                            categoryId: 6,
                        },
                        {
                            itemId: 429,
                            code: 'Q355',
                            description: ' muscle weakness',
                            categoryId: 6,
                        },
                    ],
                },
                {
                    categoryId: 7,
                    categoryName: 'Myalgia',
                    parentId: 5,
                    parentCategoryName: 'Bones, Muscles, & Joints',
                    items: [
                        {
                            itemId: 392,
                            code: 'Q321',
                            description: 'cramps hands',
                            categoryId: 7,
                        },
                        {
                            itemId: 393,
                            code: 'Q322',
                            description: 'cramps feet',
                            categoryId: 7,
                        },
                        {
                            itemId: 456,
                            code: 'Q379',
                            description: 'muscle cramps',
                            categoryId: 7,
                        },
                    ],
                },
                {
                    categoryId: 8,
                    categoryName: 'Bowel',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 68,
                            code: 'Q54',
                            description: 'bowel problems daily activities',
                            categoryId: 8,
                        },
                        {
                            itemId: 287,
                            code: 'Q227',
                            description: 'pain cramp rectum',
                            categoryId: 8,
                        },
                        {
                            itemId: 288,
                            code: 'Q228',
                            description: 'pain discomfort anus',
                            categoryId: 8,
                        },
                        {
                            itemId: 292,
                            code: 'Q232',
                            description: 'unable empty bowels',
                            categoryId: 8,
                        },
                        {
                            itemId: 294,
                            code: 'Q234',
                            description: 'wear incontinence aid',
                            categoryId: 8,
                        },
                        {
                            itemId: 295,
                            code: 'Q235',
                            description: 'restricted types food bowel problems',
                            categoryId: 8,
                        },
                        {
                            itemId: 296,
                            code: 'Q236',
                            description: 'worry bowel problem',
                            categoryId: 8,
                        },
                        {
                            itemId: 297,
                            code: 'Q237',
                            description: 'embarrassed bowel problem',
                            categoryId: 8,
                        },
                        {
                            itemId: 300,
                            code: 'Q240',
                            description: 'unhappy rest life bowel habit',
                            categoryId: 8,
                        },
                        {
                            itemId: 306,
                            code: 'Q244',
                            description: 'assistance manage bowel problem',
                            categoryId: 8,
                        },
                        {
                            itemId: 317,
                            code: 'Q254',
                            description: 'frequent bowel movements',
                            categoryId: 8,
                        },
                        {
                            itemId: 318,
                            code: 'Q255',
                            description: 'urge bowel movement',
                            categoryId: 8,
                        },
                        {
                            itemId: 353,
                            code: 'Q282',
                            description: 'change bowel habit',
                            categoryId: 8,
                        },
                        {
                            itemId: 517,
                            code: 'Q431',
                            description: 'pain buttock anal area rectum',
                            categoryId: 8,
                        },
                        {
                            itemId: 519,
                            code: 'Q433',
                            description: 'stool mucus',
                            categoryId: 8,
                        },
                        {
                            itemId: 524,
                            code: 'Q435',
                            description: 'stoma bag',
                            categoryId: 8,
                        },
                        {
                            itemId: 527,
                            code: 'Q437',
                            description: 'stoma leak stools',
                            categoryId: 8,
                        },
                        {
                            itemId: 529,
                            code: 'Q439',
                            description: 'stoma frequent change day',
                            categoryId: 8,
                        },
                        {
                            itemId: 535,
                            code: 'Q445',
                            description: 'sore skin anal area',
                            categoryId: 8,
                        },
                        {
                            itemId: 536,
                            code: 'Q446',
                            description: 'bowel movement frequent day',
                            categoryId: 8,
                        },
                        {
                            itemId: 538,
                            code: 'Q448',
                            description: 'embarrassed bowel movement',
                            categoryId: 8,
                        },
                        {
                            itemId: 584,
                            code: 'Q490',
                            description: 'hurry toilet stools urge',
                            categoryId: 8,
                        },
                        {
                            itemId: 1236,
                            code: 'Q924',
                            description: 'change bowel movements',
                            categoryId: 8,
                        },
                        {
                            itemId: 1296,
                            code: 'Q957',
                            description: 'loose fatty stools',
                            categoryId: 8,
                        },
                        {
                            itemId: 1297,
                            code: 'Q958',
                            description: 'dark red black stools',
                            categoryId: 8,
                        },
                    ],
                },
                {
                    categoryId: 9,
                    categoryName: 'Blood in stools',
                    parentId: 8,
                    parentCategoryName: 'Bowel',
                    items: [
                        {
                            itemId: 70,
                            code: 'Q55',
                            description: 'blood in stools',
                            categoryId: 9,
                        },
                        {
                            itemId: 289,
                            code: 'Q229',
                            description: 'bright blood stools',
                            categoryId: 9,
                        },
                        {
                            itemId: 290,
                            code: 'Q230',
                            description: 'dark blood clots stools',
                            categoryId: 9,
                        },
                    ],
                },
                {
                    categoryId: 10,
                    categoryName: 'Constipation',
                    parentId: 8,
                    parentCategoryName: 'Bowel',
                    items: [
                        {
                            itemId: 30,
                            code: 'Q19',
                            description: 'CO09 constipation',
                            categoryId: 10,
                        },
                        {
                            itemId: 949,
                            code: 'Q823',
                            description: 'CO01 need BM cannot',
                            categoryId: 10,
                        },
                        {
                            itemId: 950,
                            code: 'Q824',
                            description: 'CO02 hard stools',
                            categoryId: 10,
                        },
                        {
                            itemId: 951,
                            code: 'Q825',
                            description: 'CO03 too hard stools',
                            categoryId: 10,
                        },
                        {
                            itemId: 953,
                            code: 'Q827',
                            description: 'CO05 strain 10 min BM',
                            categoryId: 10,
                        },
                        {
                            itemId: 954,
                            code: 'Q828',
                            description: 'CO06 press pass stool',
                            categoryId: 10,
                        },
                        {
                            itemId: 955,
                            code: 'Q829',
                            description: 'CO07 not finish BM',
                            categoryId: 10,
                        },
                        {
                            itemId: 956,
                            code: 'Q830',
                            description: 'CO08 finger pass stool',
                            categoryId: 10,
                        },
                        {
                            itemId: 957,
                            code: 'Q831',
                            description: 'CO10 unwell',
                            categoryId: 10,
                        },
                    ],
                },
                {
                    categoryId: 11,
                    categoryName: 'Diarrhea',
                    parentId: 8,
                    parentCategoryName: 'Bowel',
                    items: [
                        {
                            itemId: 31,
                            code: 'Q20',
                            description: 'DI12 diarrhea',
                            categoryId: 11,
                        },
                        {
                            itemId: 291,
                            code: 'Q231',
                            description: 'unable wait bowel movement',
                            categoryId: 11,
                        },
                        {
                            itemId: 293,
                            code: 'Q233',
                            description: 'passing water open bowels',
                            categoryId: 11,
                        },
                        {
                            itemId: 302,
                            code: 'Q242',
                            description: 'medication diarrhoea',
                            categoryId: 11,
                        },
                        {
                            itemId: 304,
                            code: 'Q243',
                            description: 'highest number bowel movement',
                            categoryId: 11,
                        },
                        {
                            itemId: 958,
                            code: 'Q832',
                            description: 'DI01 wear pad',
                            categoryId: 11,
                        },
                        {
                            itemId: 959,
                            code: 'Q833',
                            description: 'DI02 3 BM liquid stools',
                            categoryId: 11,
                        },
                        {
                            itemId: 960,
                            code: 'Q834',
                            description: 'DI03 severe toilet several times',
                            categoryId: 11,
                        },
                        {
                            itemId: 961,
                            code: 'Q835',
                            description: 'DI04 leakage liquid stools',
                            categoryId: 11,
                        },
                        {
                            itemId: 962,
                            code: 'Q836',
                            description: 'DI05 sever DI reach toilet',
                            categoryId: 11,
                        },
                        {
                            itemId: 964,
                            code: 'Q838',
                            description: 'DI07 distress DI',
                            categoryId: 11,
                        },
                        {
                            itemId: 965,
                            code: 'Q839',
                            description: 'DI08 liquid watery stools',
                            categoryId: 11,
                        },
                        {
                            itemId: 966,
                            code: 'Q840',
                            description: 'DI09 discomfort DI',
                            categoryId: 11,
                        },
                        {
                            itemId: 967,
                            code: 'Q841',
                            description: 'DI10 worry far toilet DI',
                            categoryId: 11,
                        },
                    ],
                },
                {
                    categoryId: 12,
                    categoryName: 'Fecal incontinence',
                    parentId: 8,
                    parentCategoryName: 'Bowel',
                    items: [
                        {
                            itemId: 110,
                            code: 'Q86',
                            description: 'worry incontinent',
                            categoryId: 12,
                        },
                        {
                            itemId: 276,
                            code: 'Q216',
                            description: 'release liquid stools',
                            categoryId: 12,
                        },
                        {
                            itemId: 534,
                            code: 'Q444',
                            description: 'leakage stools back passage',
                            categoryId: 12,
                        },
                        {
                            itemId: 541,
                            code: 'Q451',
                            description: 'problem control bowels',
                            categoryId: 12,
                        },
                        {
                            itemId: 586,
                            code: 'Q492',
                            description: 'leak stools',
                            categoryId: 12,
                        },
                        {
                            itemId: 1093,
                            code: 'Q852',
                            description: 'leak stools mucus anus',
                            categoryId: 12,
                        },
                    ],
                },
                {
                    categoryId: 13,
                    categoryName: 'Flatulence/gas',
                    parentId: 8,
                    parentCategoryName: 'Bowel',
                    items: [],
                },
                {
                    categoryId: 14,
                    categoryName: 'Breast',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 177,
                            code: 'Q134',
                            description: 'pain area affected breast',
                            categoryId: 14,
                        },
                        {
                            itemId: 178,
                            code: 'Q135',
                            description: 'swollen area affected breast',
                            categoryId: 14,
                        },
                        {
                            itemId: 180,
                            code: 'Q137',
                            description: 'skin problems area affected breast',
                            categoryId: 14,
                        },
                        {
                            itemId: 321,
                            code: 'Q258',
                            description: 'problem fullness arm',
                            categoryId: 14,
                        },
                        {
                            itemId: 325,
                            code: 'Q262',
                            description: 'breast role sexuality',
                            categoryId: 14,
                        },
                        {
                            itemId: 326,
                            code: 'Q263',
                            description: 'problem loss sensations breast',
                            categoryId: 14,
                        },
                    ],
                },
                {
                    categoryId: 15,
                    categoryName: 'Breast reconstruction',
                    parentId: 14,
                    parentCategoryName: 'Breast',
                    items: [
                        {
                            itemId: 327,
                            code: 'Q264',
                            description: 'satisfied size breast',
                            categoryId: 15,
                        },
                        {
                            itemId: 328,
                            code: 'Q265',
                            description: 'satisfied shape breast',
                            categoryId: 15,
                        },
                        {
                            itemId: 329,
                            code: 'Q266',
                            description: 'satisfied skin appearance breast',
                            categoryId: 15,
                        },
                        {
                            itemId: 330,
                            code: 'Q267',
                            description: 'satisfied symmetry breasts',
                            categoryId: 15,
                        },
                        {
                            itemId: 331,
                            code: 'Q268',
                            description: 'satisfied cleavage',
                            categoryId: 15,
                        },
                        {
                            itemId: 332,
                            code: 'Q269',
                            description: 'satisfied softness breast',
                            categoryId: 15,
                        },
                        {
                            itemId: 333,
                            code: 'Q270',
                            description: 'satisfied appearance nipple',
                            categoryId: 15,
                        },
                        {
                            itemId: 335,
                            code: 'Q272',
                            description: 'satisfied scars appearance breast',
                            categoryId: 15,
                        },
                        {
                            itemId: 337,
                            code: 'Q274',
                            description: 'satisfied breast reconstruction result ',
                            categoryId: 15,
                        },
                        {
                            itemId: 338,
                            code: 'Q275',
                            description: 'coming to terms breast reconstruction',
                            categoryId: 15,
                        },
                        {
                            itemId: 339,
                            code: 'Q276',
                            description: 'tightness breast',
                            categoryId: 15,
                        },
                        {
                            itemId: 340,
                            code: 'Q277',
                            description: 'numb breast',
                            categoryId: 15,
                        },
                        {
                            itemId: 341,
                            code: 'Q278',
                            description: 'satisfied scars appearance',
                            categoryId: 15,
                        },
                        {
                            itemId: 342,
                            code: 'Q279',
                            description: 'problem loss of nipple',
                            categoryId: 15,
                        },
                        {
                            itemId: 343,
                            code: 'Q280',
                            description: 'coming to terms nipple preservation/reconstruction',
                            categoryId: 15,
                        },
                    ],
                },
                {
                    categoryId: 16,
                    categoryName: 'Cardiovascular',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 510,
                            code: 'Q424',
                            description: 'worry damage heart blood vessels',
                            categoryId: 16,
                        },
                        {
                            itemId: 608,
                            code: 'Q512',
                            description: 'vulnerable veins',
                            categoryId: 16,
                        },
                        {
                            itemId: 626,
                            code: 'Q526',
                            description: 'problem repeated injections',
                            categoryId: 16,
                        },
                        {
                            itemId: 654,
                            code: 'Q551',
                            description: 'worry blood recover',
                            categoryId: 16,
                        },
                        {
                            itemId: 1244,
                            code: 'Q932',
                            description: 'palpitations',
                            categoryId: 16,
                        },
                        {
                            itemId: 1262,
                            code: 'Q947',
                            description: 'rapid heartbeat',
                            categoryId: 16,
                        },
                    ],
                },
                {
                    categoryId: 17,
                    categoryName: 'Cognitive Functioning',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 612,
                            code: 'Q516',
                            description: 'problem think clearly',
                            categoryId: 17,
                        },
                        {
                            itemId: 614,
                            code: 'Q518',
                            description: 'feel confused',
                            categoryId: 17,
                        },
                        {
                            itemId: 615,
                            code: 'Q519',
                            description: 'problem finish things',
                            categoryId: 17,
                        },
                        {
                            itemId: 830,
                            code: 'Q705',
                            description: 'CF28 problems gather thoughts',
                            categoryId: 17,
                        },
                    ],
                },
                {
                    categoryId: 18,
                    categoryName: 'Concentration',
                    parentId: 17,
                    parentCategoryName: 'Cognitive Functioning',
                    items: [
                        {
                            itemId: 34,
                            code: 'Q23',
                            description: 'CF11 problems concentrate',
                            categoryId: 18,
                        },
                        {
                            itemId: 806,
                            code: 'Q681',
                            description: 'CF02 distracted thoughts',
                            categoryId: 18,
                        },
                        {
                            itemId: 815,
                            code: 'Q690',
                            description: 'CF12 distracted read',
                            categoryId: 18,
                        },
                        {
                            itemId: 816,
                            code: 'Q691',
                            description: 'CF14 problems concentrate interested',
                            categoryId: 18,
                        },
                        {
                            itemId: 824,
                            code: 'Q699',
                            description: 'CF22 distracted task finish',
                            categoryId: 18,
                        },
                        {
                            itemId: 828,
                            code: 'Q703',
                            description: 'CF26 problems focus important',
                            categoryId: 18,
                        },
                        {
                            itemId: 836,
                            code: 'Q711',
                            description: 'CF34 problems focus task',
                            categoryId: 18,
                        },
                    ],
                },
                {
                    categoryId: 19,
                    categoryName: 'Memory',
                    parentId: 17,
                    parentCategoryName: 'Cognitive Functioning',
                    items: [
                        {
                            itemId: 39,
                            code: 'Q28',
                            description: ' CF13 problems remember',
                            categoryId: 19,
                        },
                        {
                            itemId: 807,
                            code: 'Q682',
                            description: 'CF03 problems remember date',
                            categoryId: 19,
                        },
                        {
                            itemId: 808,
                            code: 'Q683',
                            description: 'CF04 problems remember told',
                            categoryId: 19,
                        },
                        {
                            itemId: 809,
                            code: 'Q684',
                            description: 'CF05 problems remember say talk',
                            categoryId: 19,
                        },
                        {
                            itemId: 810,
                            code: 'Q685',
                            description: 'CF06 problems remember happen',
                            categoryId: 19,
                        },
                        {
                            itemId: 811,
                            code: 'Q686',
                            description: 'CF07 walk forget',
                            categoryId: 19,
                        },
                        {
                            itemId: 812,
                            code: 'Q687',
                            description: 'CF08 problems remember names',
                            categoryId: 19,
                        },
                        {
                            itemId: 813,
                            code: 'Q688',
                            description: 'CF09 problems remember goal distracted',
                            categoryId: 19,
                        },
                        {
                            itemId: 814,
                            code: 'Q689',
                            description: 'CF10 problems remember do interrupt',
                            categoryId: 19,
                        },
                        {
                            itemId: 817,
                            code: 'Q692',
                            description: 'CF15 forgetful',
                            categoryId: 19,
                        },
                        {
                            itemId: 819,
                            code: 'Q694',
                            description: 'CF17 problems recognise',
                            categoryId: 19,
                        },
                        {
                            itemId: 820,
                            code: 'Q695',
                            description: 'CF18 problems remember told',
                            categoryId: 19,
                        },
                        {
                            itemId: 822,
                            code: 'Q697',
                            description: 'CF20 problems remember new information',
                            categoryId: 19,
                        },
                        {
                            itemId: 823,
                            code: 'Q698',
                            description: 'CF21 problems remember take things',
                            categoryId: 19,
                        },
                        {
                            itemId: 825,
                            code: 'Q700',
                            description: 'CF23 problems remember already done',
                            categoryId: 19,
                        },
                        {
                            itemId: 826,
                            code: 'Q701',
                            description: 'CF24 problems remember said',
                            categoryId: 19,
                        },
                        {
                            itemId: 827,
                            code: 'Q702',
                            description: 'CF25 problems remember message',
                            categoryId: 19,
                        },
                        {
                            itemId: 829,
                            code: 'Q704',
                            description: 'CF27 problems remember think',
                            categoryId: 19,
                        },
                        {
                            itemId: 831,
                            code: 'Q706',
                            description: 'CF29 problems remember plans',
                            categoryId: 19,
                        },
                        {
                            itemId: 832,
                            code: 'Q707',
                            description: 'CF30 problems remember weekday',
                            categoryId: 19,
                        },
                        {
                            itemId: 833,
                            code: 'Q708',
                            description: 'CF31 problems remember text',
                            categoryId: 19,
                        },
                        {
                            itemId: 834,
                            code: 'Q709',
                            description: 'CF32 problems remember did',
                            categoryId: 19,
                        },
                        {
                            itemId: 835,
                            code: 'Q710',
                            description: 'CF33 forget routine',
                            categoryId: 19,
                        },
                    ],
                },
                {
                    categoryId: 20,
                    categoryName: 'Multi-tasking',
                    parentId: 17,
                    parentCategoryName: 'Cognitive Functioning',
                    items: [
                        {
                            itemId: 805,
                            code: 'Q680',
                            description: 'CF01 problems two tasks simultaneous',
                            categoryId: 20,
                        },
                    ],
                },
                {
                    categoryId: 21,
                    categoryName: 'Communication',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 50,
                            code: 'Q38',
                            description: 'able to talk with family about illness',
                            categoryId: 21,
                        },
                        {
                            itemId: 128,
                            code: 'Q93',
                            description: 'communication HCP sexual issues',
                            categoryId: 21,
                        },
                        {
                            itemId: 133,
                            code: 'Q98',
                            description: 'communication sexual issues partner',
                            categoryId: 21,
                        },
                        {
                            itemId: 144,
                            code: 'Q106',
                            description: 'problem talking to others',
                            categoryId: 21,
                        },
                        {
                            itemId: 202,
                            code: 'Q156',
                            description: 'talk disease partner',
                            categoryId: 21,
                        },
                        {
                            itemId: 203,
                            code: 'Q157',
                            description: 'talk sexuality partner',
                            categoryId: 21,
                        },
                        {
                            itemId: 298,
                            code: 'Q238',
                            description: 'problem communicate thoughts',
                            categoryId: 21,
                        },
                        {
                            itemId: 479,
                            code: 'Q394',
                            description: 'opportunity express emotions',
                            categoryId: 21,
                        },
                        {
                            itemId: 556,
                            code: 'Q465',
                            description: 'problem talk feelings family friends',
                            categoryId: 21,
                        },
                        {
                            itemId: 721,
                            code: 'Q603',
                            description: 'someone talk feelings',
                            categoryId: 21,
                        },
                    ],
                },
                {
                    categoryId: 22,
                    categoryName: 'Dermatological',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 332,
                            code: 'Q269',
                            description: 'satisfied softness breast',
                            categoryId: 22,
                        },
                        {
                            itemId: 608,
                            code: 'Q512',
                            description: 'vulnerable veins',
                            categoryId: 22,
                        },
                        {
                            itemId: 626,
                            code: 'Q526',
                            description: 'problem repeated injections',
                            categoryId: 22,
                        },
                        {
                            itemId: 637,
                            code: 'Q537',
                            description: 'skin colour changes',
                            categoryId: 22,
                        },
                        {
                            itemId: 638,
                            code: 'Q538',
                            description: 'problem wound heal',
                            categoryId: 22,
                        },
                        {
                            itemId: 704,
                            code: 'Q591',
                            description: 'area wound oversensitive',
                            categoryId: 22,
                        },
                        {
                            itemId: 1147,
                            code: 'Q891',
                            description: 'tear split skin genital area',
                            categoryId: 22,
                        },
                        {
                            itemId: 1153,
                            code: 'Q897',
                            description: 'tight skin genital area',
                            categoryId: 22,
                        },
                        {
                            itemId: 1157,
                            code: 'Q901',
                            description: 'tight skin legs',
                            categoryId: 22,
                        },
                        {
                            itemId: 1219,
                            code: 'Q907',
                            description: 'reddening hands feet',
                            categoryId: 22,
                        },
                        {
                            itemId: 1238,
                            code: 'Q926',
                            description: 'broken nails',
                            categoryId: 22,
                        },
                        {
                            itemId: 1239,
                            code: 'Q927',
                            description: 'hair colour change',
                            categoryId: 22,
                        },
                        {
                            itemId: 1240,
                            code: 'Q928',
                            description: 'hair texture change',
                            categoryId: 22,
                        },
                        {
                            itemId: 1251,
                            code: 'Q939',
                            description: 'skin sensitive sun',
                            categoryId: 22,
                        },
                    ],
                },
                {
                    categoryId: 23,
                    categoryName: 'Bruising',
                    parentId: 22,
                    parentCategoryName: 'Dermatological',
                    items: [
                        {
                            itemId: 422,
                            code: 'Q348',
                            description: 'bruising',
                            categoryId: 23,
                        },
                        {
                            itemId: 1252,
                            code: 'Q940',
                            description: 'bruise easily',
                            categoryId: 23,
                        },
                    ],
                },
                {
                    categoryId: 24,
                    categoryName: 'Dry/itchy skin',
                    parentId: 22,
                    parentCategoryName: 'Dermatological',
                    items: [
                        {
                            itemId: 180,
                            code: 'Q137',
                            description: 'skin problems area affected breast',
                            categoryId: 24,
                        },
                        {
                            itemId: 186,
                            code: 'Q142',
                            description: 'skin problems',
                            categoryId: 24,
                        },
                        {
                            itemId: 197,
                            code: 'Q151',
                            description: 'itching',
                            categoryId: 24,
                        },
                        {
                            itemId: 308,
                            code: 'Q245',
                            description: 'problem itchy skin',
                            categoryId: 24,
                        },
                        {
                            itemId: 636,
                            code: 'Q536',
                            description: 'rash',
                            categoryId: 24,
                        },
                        {
                            itemId: 1096,
                            code: 'Q855',
                            description: 'itchy irritated skin treated',
                            categoryId: 24,
                        },
                        {
                            itemId: 1145,
                            code: 'Q889',
                            description: 'itchy irritated skin genital area',
                            categoryId: 24,
                        },
                        {
                            itemId: 1248,
                            code: 'Q936',
                            description: 'dry flaking cracked skin',
                            categoryId: 24,
                        },
                    ],
                },
                {
                    categoryId: 25,
                    categoryName: 'Hair loss',
                    parentId: 22,
                    parentCategoryName: 'Dermatological',
                    items: [
                        {
                            itemId: 155,
                            code: 'Q116',
                            description: 'lost hair',
                            categoryId: 25,
                        },
                        {
                            itemId: 166,
                            code: 'Q125',
                            description: 'upset loss hair',
                            categoryId: 25,
                        },
                        {
                            itemId: 647,
                            code: 'Q547',
                            description: 'upset treatment affect hair',
                            categoryId: 25,
                        },
                        {
                            itemId: 701,
                            code: 'Q588',
                            description: 'thin lifeless hair',
                            categoryId: 25,
                        },
                    ],
                },
                {
                    categoryId: 26,
                    categoryName: 'Jaundice',
                    parentId: 22,
                    parentCategoryName: 'Dermatological',
                    items: [
                        {
                            itemId: 196,
                            code: 'Q150',
                            description: 'jaundice ',
                            categoryId: 26,
                        },
                        {
                            itemId: 199,
                            code: 'Q153',
                            description: 'worry jaundice',
                            categoryId: 26,
                        },
                        {
                            itemId: 316,
                            code: 'Q253',
                            description: 'yellow skin',
                            categoryId: 26,
                        },
                    ],
                },
                {
                    categoryId: 27,
                    categoryName: 'Redness/flushing',
                    parentId: 22,
                    parentCategoryName: 'Dermatological',
                    items: [
                        {
                            itemId: 619,
                            code: 'Q523',
                            description: 'look flushed red',
                            categoryId: 27,
                        },
                    ],
                },
                {
                    categoryId: 28,
                    categoryName: 'Sore skin',
                    parentId: 22,
                    parentCategoryName: 'Dermatological',
                    items: [
                        {
                            itemId: 528,
                            code: 'Q438',
                            description: 'stoma sore skin',
                            categoryId: 28,
                        },
                        {
                            itemId: 535,
                            code: 'Q445',
                            description: 'sore skin anal area',
                            categoryId: 28,
                        },
                        {
                            itemId: 1146,
                            code: 'Q890',
                            description: 'sore skin genital area',
                            categoryId: 28,
                        },
                        {
                            itemId: 1155,
                            code: 'Q899',
                            description: 'sore skin groin',
                            categoryId: 28,
                        },
                    ],
                },
                {
                    categoryId: 29,
                    categoryName: 'Splitting finger/toenails',
                    parentId: 22,
                    parentCategoryName: 'Dermatological',
                    items: [
                        {
                            itemId: 699,
                            code: 'Q586',
                            description: 'split nails',
                            categoryId: 29,
                        },
                    ],
                },
                {
                    categoryId: 30,
                    categoryName: 'Disease/Treatment Burden',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 51,
                            code: 'Q39',
                            description: 'worry family coping',
                            categoryId: 30,
                        },
                        {
                            itemId: 58,
                            code: 'Q46',
                            description: 'burden disease patient',
                            categoryId: 30,
                        },
                        {
                            itemId: 59,
                            code: 'Q47',
                            description: 'burden treatment patient',
                            categoryId: 30,
                        },
                        {
                            itemId: 62,
                            code: 'Q50',
                            description: 'go out close to toilet',
                            categoryId: 30,
                        },
                        {
                            itemId: 242,
                            code: 'Q183',
                            description: 'dependent others urostomy care ',
                            categoryId: 30,
                        },
                        {
                            itemId: 269,
                            code: 'Q209',
                            description: 'worry become dependent',
                            categoryId: 30,
                        },
                        {
                            itemId: 306,
                            code: 'Q244',
                            description: 'assistance manage bowel problem',
                            categoryId: 30,
                        },
                        {
                            itemId: 338,
                            code: 'Q275',
                            description: 'coming to terms breast reconstruction',
                            categoryId: 30,
                        },
                        {
                            itemId: 365,
                            code: 'Q294',
                            description: 'worry burden other people',
                            categoryId: 30,
                        },
                        {
                            itemId: 370,
                            code: 'Q299',
                            description: 'worry become more dependent',
                            categoryId: 30,
                        },
                        {
                            itemId: 462,
                            code: 'Q384',
                            description: 'need social support therapy disease',
                            categoryId: 30,
                        },
                        {
                            itemId: 504,
                            code: 'Q418',
                            description: 'problem accept limitations',
                            categoryId: 30,
                        },
                        {
                            itemId: 558,
                            code: 'Q467',
                            description: 'disease treatment sex life',
                            categoryId: 30,
                        },
                        {
                            itemId: 627,
                            code: 'Q527',
                            description: 'distress disease treatment close people',
                            categoryId: 30,
                        },
                        {
                            itemId: 652,
                            code: 'Q549',
                            description: 'problem cope hospital',
                            categoryId: 30,
                        },
                        {
                            itemId: 658,
                            code: 'Q555',
                            description: 'drug intake interference daily life',
                            categoryId: 30,
                        },
                        {
                            itemId: 659,
                            code: 'Q556',
                            description: 'watch closely new symptoms',
                            categoryId: 30,
                        },
                        {
                            itemId: 713,
                            code: 'Q595',
                            description: 'able deal with problems',
                            categoryId: 30,
                        },
                        {
                            itemId: 715,
                            code: 'Q597',
                            description: 'find things enjoy',
                            categoryId: 30,
                        },
                        {
                            itemId: 729,
                            code: 'Q611',
                            description: 'wonder anything done',
                            categoryId: 30,
                        },
                        {
                            itemId: 730,
                            code: 'Q612',
                            description: 'felt unfair ill',
                            categoryId: 30,
                        },
                        {
                            itemId: 739,
                            code: 'Q621',
                            description: 'feelings life changed less well',
                            categoryId: 30,
                        },
                        {
                            itemId: 740,
                            code: 'Q622',
                            description: ' feelings life changed last weeks',
                            categoryId: 30,
                        },
                        {
                            itemId: 1264,
                            code: 'Q949',
                            description: 'worry hormone tablets body scan',
                            categoryId: 30,
                        },
                        {
                            itemId: 1265,
                            code: 'Q950',
                            description: 'worry drugs life',
                            categoryId: 30,
                        },
                        {
                            itemId: 1268,
                            code: 'Q953',
                            description: 'worry close cope',
                            categoryId: 30,
                        },
                    ],
                },
                {
                    categoryId: 31,
                    categoryName: 'Treatment side effects',
                    parentId: 30,
                    parentCategoryName: 'Disease/Treatment Burden',
                    items: [
                        {
                            itemId: 123,
                            code: 'Q88',
                            description: 'treatment sex life',
                            categoryId: 31,
                        },
                        {
                            itemId: 220,
                            code: 'Q168',
                            description: 'problem side effects ',
                            categoryId: 31,
                        },
                        {
                            itemId: 621,
                            code: 'Q525',
                            description: 'side effects treatment',
                            categoryId: 31,
                        },
                    ],
                },
                {
                    categoryId: 32,
                    categoryName: 'Dyspnea',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 22,
                            code: 'Q11',
                            description: 'DY06 short of breath',
                            categoryId: 32,
                        },
                        {
                            itemId: 431,
                            code: 'Q357',
                            description: ' short breath exertion',
                            categoryId: 32,
                        },
                        {
                            itemId: 562,
                            code: 'Q471',
                            description: 'short breath rest',
                            categoryId: 32,
                        },
                        {
                            itemId: 564,
                            code: 'Q473',
                            description: 'short breath walk',
                            categoryId: 32,
                        },
                        {
                            itemId: 565,
                            code: 'Q474',
                            description: 'short breath stairs',
                            categoryId: 32,
                        },
                        {
                            itemId: 918,
                            code: 'Q792',
                            description: 'DY01 short breath wash',
                            categoryId: 32,
                        },
                        {
                            itemId: 919,
                            code: 'Q793',
                            description: 'DY02 short breath rest',
                            categoryId: 32,
                        },
                        {
                            itemId: 920,
                            code: 'Q794',
                            description: 'DY03 problem breath sweat',
                            categoryId: 32,
                        },
                        {
                            itemId: 921,
                            code: 'Q795',
                            description: 'DY04 gasp air',
                            categoryId: 32,
                        },
                        {
                            itemId: 922,
                            code: 'Q796',
                            description: 'DY05 feel stop breathe',
                            categoryId: 32,
                        },
                        {
                            itemId: 924,
                            code: 'Q798',
                            description: 'DY08 not enough air',
                            categoryId: 32,
                        },
                        {
                            itemId: 926,
                            code: 'Q800',
                            description: 'DY10 short breath bend',
                            categoryId: 32,
                        },
                        {
                            itemId: 927,
                            code: 'Q801',
                            description: 'DY11 short breath minimal effort',
                            categoryId: 32,
                        },
                        {
                            itemId: 928,
                            code: 'Q802',
                            description: 'DY12 suffocate',
                            categoryId: 32,
                        },
                        {
                            itemId: 929,
                            code: 'Q803',
                            description: 'DY13 short breath moderate activity',
                            categoryId: 32,
                        },
                        {
                            itemId: 930,
                            code: 'Q804',
                            description: 'DY14 severe short breath',
                            categoryId: 32,
                        },
                        {
                            itemId: 931,
                            code: 'Q805',
                            description: 'DY15 rapid breathing',
                            categoryId: 32,
                        },
                        {
                            itemId: 932,
                            code: 'Q806',
                            description: 'DY16 short breath dress',
                            categoryId: 32,
                        },
                        {
                            itemId: 933,
                            code: 'Q807',
                            description: 'DY17 short breath walk 100 m',
                            categoryId: 32,
                        },
                        {
                            itemId: 934,
                            code: 'Q808',
                            description: 'DY18 short breath talk',
                            categoryId: 32,
                        },
                        {
                            itemId: 935,
                            code: 'Q809',
                            description: 'DY19 stop breath walk',
                            categoryId: 32,
                        },
                        {
                            itemId: 936,
                            code: 'Q810',
                            description: 'DY20 very severe short breath',
                            categoryId: 32,
                        },
                        {
                            itemId: 937,
                            code: 'Q811',
                            description: 'DY21 short breath walk',
                            categoryId: 32,
                        },
                        {
                            itemId: 938,
                            code: 'Q812',
                            description: 'DY22 problems breathe in',
                            categoryId: 32,
                        },
                        {
                            itemId: 939,
                            code: 'Q813',
                            description: 'DY23 short breath walk level ground',
                            categoryId: 32,
                        },
                        {
                            itemId: 940,
                            code: 'Q814',
                            description: 'DY24 problems breathe out',
                            categoryId: 32,
                        },
                        {
                            itemId: 941,
                            code: 'Q815',
                            description: 'DY25 short breath walk up flight stairs',
                            categoryId: 32,
                        },
                        {
                            itemId: 942,
                            code: 'Q816',
                            description: 'DY26 short breath stand up chair',
                            categoryId: 32,
                        },
                        {
                            itemId: 943,
                            code: 'Q817',
                            description: 'DY27 short breath walk less 100 m',
                            categoryId: 32,
                        },
                        {
                            itemId: 944,
                            code: 'Q818',
                            description: 'DY28 cannot breathe',
                            categoryId: 32,
                        },
                        {
                            itemId: 945,
                            code: 'Q819',
                            description: 'DY29 short breath lie down',
                            categoryId: 32,
                        },
                        {
                            itemId: 946,
                            code: 'Q820',
                            description: 'DY30 short breath go up few steps',
                            categoryId: 32,
                        },
                        {
                            itemId: 947,
                            code: 'Q821',
                            description: 'DY31 short breath walk around home',
                            categoryId: 32,
                        },
                        {
                            itemId: 948,
                            code: 'Q822',
                            description: 'DY32 out breath',
                            categoryId: 32,
                        },
                    ],
                },
                {
                    categoryId: 33,
                    categoryName: 'Eating/Appetite Loss',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 76,
                            code: 'Q61',
                            description: 'problem drinking liquids',
                            categoryId: 33,
                        },
                        {
                            itemId: 77,
                            code: 'Q62',
                            description: 'problem eating liquidised food',
                            categoryId: 33,
                        },
                        {
                            itemId: 79,
                            code: 'Q64',
                            description: 'problem eating solid food',
                            categoryId: 33,
                        },
                        {
                            itemId: 131,
                            code: 'Q96',
                            description: 'problem eating',
                            categoryId: 33,
                        },
                        {
                            itemId: 132,
                            code: 'Q97',
                            description: 'problem eating with family',
                            categoryId: 33,
                        },
                        {
                            itemId: 138,
                            code: 'Q103',
                            description: 'problem enjoying meals',
                            categoryId: 33,
                        },
                        {
                            itemId: 160,
                            code: 'Q121',
                            description: 'nutritional supplements ',
                            categoryId: 33,
                        },
                        {
                            itemId: 161,
                            code: 'Q122',
                            description: 'feeding tube',
                            categoryId: 33,
                        },
                        {
                            itemId: 218,
                            code: 'Q166',
                            description: 'discomfort eating',
                            categoryId: 33,
                        },
                        {
                            itemId: 234,
                            code: 'Q175',
                            description: 'long time finish meal',
                            categoryId: 33,
                        },
                        {
                            itemId: 352,
                            code: 'Q281',
                            description: 'unpleasant food texture',
                            categoryId: 33,
                        },
                        {
                            itemId: 358,
                            code: 'Q287',
                            description: 'worry not eat enough',
                            categoryId: 33,
                        },
                        {
                            itemId: 361,
                            code: 'Q290',
                            description: 'unable to eat',
                            categoryId: 33,
                        },
                        {
                            itemId: 372,
                            code: 'Q301',
                            description: 'FA29 tired eat',
                            categoryId: 33,
                        },
                        {
                            itemId: 474,
                            code: 'Q389',
                            description: 'eat solid food',
                            categoryId: 33,
                        },
                        {
                            itemId: 476,
                            code: 'Q391',
                            description: 'eat liquidised soft food',
                            categoryId: 33,
                        },
                        {
                            itemId: 477,
                            code: 'Q392',
                            description: 'drink liquids',
                            categoryId: 33,
                        },
                        {
                            itemId: 639,
                            code: 'Q539',
                            description: 'problem chew',
                            categoryId: 33,
                        },
                        {
                            itemId: 645,
                            code: 'Q545',
                            description: 'worry enough nourishment',
                            categoryId: 33,
                        },
                        {
                            itemId: 892,
                            code: 'Q766',
                            description: 'NV06 nausea eat less',
                            categoryId: 33,
                        },
                        {
                            itemId: 900,
                            code: 'Q774',
                            description: 'NV14 nausea interfere appetite',
                            categoryId: 33,
                        },
                        {
                            itemId: 902,
                            code: 'Q776',
                            description: 'NV16 nausea unable eat',
                            categoryId: 33,
                        },
                        {
                            itemId: 904,
                            code: 'Q778',
                            description: 'NV18 nausea less fluid',
                            categoryId: 33,
                        },
                        {
                            itemId: 905,
                            code: 'Q779',
                            description: 'NV19 nausea unable eat stomach',
                            categoryId: 33,
                        },
                        {
                            itemId: 1141,
                            code: 'Q885',
                            description: 'problem swallowing liquid',
                            categoryId: 33,
                        },
                        {
                            itemId: 1142,
                            code: 'Q886',
                            description: 'problem swallow solid food',
                            categoryId: 33,
                        },
                        {
                            itemId: 1143,
                            code: 'Q887',
                            description: 'problem pureed food',
                            categoryId: 33,
                        },
                        {
                            itemId: 1237,
                            code: 'Q925',
                            description: 'restricted types food',
                            categoryId: 33,
                        },
                    ],
                },
                {
                    categoryId: 34,
                    categoryName: 'Altered taste/smell',
                    parentId: 33,
                    parentCategoryName: 'Eating/Appetite Loss',
                    items: [],
                },
                {
                    categoryId: 35,
                    categoryName: 'Appetite loss',
                    parentId: 33,
                    parentCategoryName: 'Eating/Appetite Loss',
                    items: [
                        {
                            itemId: 27,
                            code: 'Q16',
                            description: 'AP04 appetite loss',
                            categoryId: 35,
                        },
                        {
                            itemId: 355,
                            code: 'Q284',
                            description: 'too much put off',
                            categoryId: 35,
                        },
                        {
                            itemId: 363,
                            code: 'Q292',
                            description: 'pressure others eat more',
                            categoryId: 35,
                        },
                        {
                            itemId: 374,
                            code: 'Q303',
                            description: 'AP02 force eat',
                            categoryId: 35,
                        },
                        {
                            itemId: 906,
                            code: 'Q780',
                            description: 'AP01 severe lack appetite',
                            categoryId: 35,
                        },
                        {
                            itemId: 907,
                            code: 'Q781',
                            description: 'AP03 encouragement eat',
                            categoryId: 35,
                        },
                        {
                            itemId: 908,
                            code: 'Q782',
                            description: 'AP05 problem enjoy food',
                            categoryId: 35,
                        },
                        {
                            itemId: 909,
                            code: 'Q783',
                            description: 'AP06 lack interest eat',
                            categoryId: 35,
                        },
                        {
                            itemId: 910,
                            code: 'Q784',
                            description: 'AP07 food repulsive',
                            categoryId: 35,
                        },
                    ],
                },
                {
                    categoryId: 36,
                    categoryName: 'Early satiety',
                    parentId: 33,
                    parentCategoryName: 'Eating/Appetite Loss',
                    items: [
                        {
                            itemId: 181,
                            code: 'Q138',
                            description: 'full up too quickly ',
                            categoryId: 36,
                        },
                    ],
                },
                {
                    categoryId: 37,
                    categoryName: 'Eating restrictions',
                    parentId: 33,
                    parentCategoryName: 'Eating/Appetite Loss',
                    items: [
                        {
                            itemId: 195,
                            code: 'Q149',
                            description: 'limited types of food',
                            categoryId: 37,
                        },
                        {
                            itemId: 295,
                            code: 'Q235',
                            description: 'restricted types food bowel problems',
                            categoryId: 37,
                        },
                        {
                            itemId: 313,
                            code: 'Q250',
                            description: 'limited amounts of food',
                            categoryId: 37,
                        },
                    ],
                },
                {
                    categoryId: 38,
                    categoryName: 'Edema',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 83,
                            code: 'Q68',
                            description: 'swelling legs ankles',
                            categoryId: 38,
                        },
                        {
                            itemId: 175,
                            code: 'Q132',
                            description: 'swollen arm hand',
                            categoryId: 38,
                        },
                        {
                            itemId: 178,
                            code: 'Q135',
                            description: 'swollen area affected breast',
                            categoryId: 38,
                        },
                        {
                            itemId: 321,
                            code: 'Q258',
                            description: 'problem fullness arm',
                            categoryId: 38,
                        },
                        {
                            itemId: 336,
                            code: 'Q273',
                            description: 'problems clothes tight',
                            categoryId: 38,
                        },
                        {
                            itemId: 454,
                            code: 'Q377',
                            description: 'swelling certain body parts',
                            categoryId: 38,
                        },
                        {
                            itemId: 545,
                            code: 'Q454',
                            description: 'swelling legs',
                            categoryId: 38,
                        },
                        {
                            itemId: 579,
                            code: 'Q485',
                            description: 'heavy legs',
                            categoryId: 38,
                        },
                        {
                            itemId: 635,
                            code: 'Q535',
                            description: 'neck swelling ',
                            categoryId: 38,
                        },
                        {
                            itemId: 642,
                            code: 'Q542',
                            description: 'abdomen swelling',
                            categoryId: 38,
                        },
                        {
                            itemId: 1152,
                            code: 'Q896',
                            description: 'swelling genital area',
                            categoryId: 38,
                        },
                        {
                            itemId: 1154,
                            code: 'Q898',
                            description: 'swelling groin',
                            categoryId: 38,
                        },
                        {
                            itemId: 1228,
                            code: 'Q916',
                            description: 'swelling body',
                            categoryId: 38,
                        },
                        {
                            itemId: 1229,
                            code: 'Q917',
                            description: 'swelling face eyes',
                            categoryId: 38,
                        },
                    ],
                },
                {
                    categoryId: 39,
                    categoryName: 'Emotional Functioning',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 56,
                            code: 'Q44',
                            description: 'positive outlook',
                            categoryId: 39,
                        },
                        {
                            itemId: 166,
                            code: 'Q125',
                            description: 'upset loss hair',
                            categoryId: 39,
                        },
                        {
                            itemId: 217,
                            code: 'Q165',
                            description: 'enjoying yourself',
                            categoryId: 39,
                        },
                        {
                            itemId: 237,
                            code: 'Q178',
                            description: 'think illness',
                            categoryId: 39,
                        },
                        {
                            itemId: 271,
                            code: 'Q211',
                            description: 'feel positive health',
                            categoryId: 39,
                        },
                        {
                            itemId: 479,
                            code: 'Q394',
                            description: 'opportunity express emotions',
                            categoryId: 39,
                        },
                        {
                            itemId: 503,
                            code: 'Q417',
                            description: 'patient HCP listen emotions',
                            categoryId: 39,
                        },
                        {
                            itemId: 505,
                            code: 'Q419',
                            description: 'HCP help manage emotions',
                            categoryId: 39,
                        },
                        {
                            itemId: 518,
                            code: 'Q432',
                            description: 'restless agitated',
                            categoryId: 39,
                        },
                        {
                            itemId: 556,
                            code: 'Q465',
                            description: 'problem talk feelings family friends',
                            categoryId: 39,
                        },
                        {
                            itemId: 609,
                            code: 'Q513',
                            description: 'feeling discouraged',
                            categoryId: 39,
                        },
                        {
                            itemId: 613,
                            code: 'Q517',
                            description: 'lack self confidence',
                            categoryId: 39,
                        },
                        {
                            itemId: 647,
                            code: 'Q547',
                            description: 'upset treatment affect hair',
                            categoryId: 39,
                        },
                        {
                            itemId: 653,
                            code: 'Q550',
                            description: 'problem isolation hospital',
                            categoryId: 39,
                        },
                        {
                            itemId: 716,
                            code: 'Q598',
                            description: 'forgive things done',
                            categoryId: 39,
                        },
                        {
                            itemId: 717,
                            code: 'Q599',
                            description: 'troubled',
                            categoryId: 39,
                        },
                        {
                            itemId: 718,
                            code: 'Q600',
                            description: 'lonely',
                            categoryId: 39,
                        },
                        {
                            itemId: 720,
                            code: 'Q602',
                            description: 'love close people',
                            categoryId: 39,
                        },
                        {
                            itemId: 721,
                            code: 'Q603',
                            description: 'someone talk feelings',
                            categoryId: 39,
                        },
                        {
                            itemId: 725,
                            code: 'Q607',
                            description: 'life fulfil',
                            categoryId: 39,
                        },
                        {
                            itemId: 726,
                            code: 'Q608',
                            description: 'life worthwhile',
                            categoryId: 39,
                        },
                        {
                            itemId: 780,
                            code: 'Q655',
                            description: 'EF05 vulnerable',
                            categoryId: 39,
                        },
                        {
                            itemId: 783,
                            code: 'Q658',
                            description: 'EF08 discouraged',
                            categoryId: 39,
                        },
                        {
                            itemId: 792,
                            code: 'Q667',
                            description: 'EF20 desperate',
                            categoryId: 39,
                        },
                        {
                            itemId: 793,
                            code: 'Q668',
                            description: 'EF21 fear lost control',
                            categoryId: 39,
                        },
                        {
                            itemId: 891,
                            code: 'Q765',
                            description: 'NV05 nausea interfere enjoy life',
                            categoryId: 39,
                        },
                        {
                            itemId: 1271,
                            code: 'Q956',
                            description: 'support family friends',
                            categoryId: 39,
                        },
                    ],
                },
                {
                    categoryId: 40,
                    categoryName: 'Anger',
                    parentId: 39,
                    parentCategoryName: 'Emotional Functioning',
                    items: [
                        {
                            itemId: 37,
                            code: 'Q26',
                            description: 'EF17 irritable',
                            categoryId: 40,
                        },
                        {
                            itemId: 781,
                            code: 'Q656',
                            description: 'EF06 frustrated',
                            categoryId: 40,
                        },
                    ],
                },
                {
                    categoryId: 41,
                    categoryName: 'Anxiety',
                    parentId: 39,
                    parentCategoryName: 'Emotional Functioning',
                    items: [
                        {
                            itemId: 36,
                            code: 'Q25',
                            description: 'EF19 worry',
                            categoryId: 41,
                        },
                        {
                            itemId: 245,
                            code: 'Q186',
                            description: 'worry results',
                            categoryId: 41,
                        },
                        {
                            itemId: 246,
                            code: 'Q187',
                            description: 'worry future treatments',
                            categoryId: 41,
                        },
                        {
                            itemId: 296,
                            code: 'Q236',
                            description: 'worry bowel problem',
                            categoryId: 41,
                        },
                        {
                            itemId: 656,
                            code: 'Q553',
                            description: 'need keep fears concerns family friends',
                            categoryId: 41,
                        },
                        {
                            itemId: 728,
                            code: 'Q610',
                            description: 'worries concerns future',
                            categoryId: 41,
                        },
                        {
                            itemId: 778,
                            code: 'Q653',
                            description: 'EF03 panic',
                            categoryId: 41,
                        },
                    ],
                },
                {
                    categoryId: 42,
                    categoryName: 'Depression',
                    parentId: 39,
                    parentCategoryName: 'Emotional Functioning',
                    items: [
                        {
                            itemId: 38,
                            code: 'Q27',
                            description: 'EF16 depressed',
                            categoryId: 42,
                        },
                        {
                            itemId: 782,
                            code: 'Q657',
                            description: 'EF07 worthless',
                            categoryId: 42,
                        },
                        {
                            itemId: 785,
                            code: 'Q660',
                            description: 'EF10 nothing cheer up',
                            categoryId: 42,
                        },
                        {
                            itemId: 787,
                            code: 'Q662',
                            description: 'EF12 pleasure gone',
                            categoryId: 42,
                        },
                        {
                            itemId: 789,
                            code: 'Q664',
                            description: 'EF14 lose interest appearance',
                            categoryId: 42,
                        },
                        {
                            itemId: 790,
                            code: 'Q665',
                            description: 'EF15 miserable',
                            categoryId: 42,
                        },
                        {
                            itemId: 791,
                            code: 'Q666',
                            description: 'EF18 useless',
                            categoryId: 42,
                        },
                        {
                            itemId: 794,
                            code: 'Q669',
                            description: 'EF22 sad',
                            categoryId: 42,
                        },
                        {
                            itemId: 795,
                            code: 'Q670',
                            description: 'EF23 give up',
                            categoryId: 42,
                        },
                        {
                            itemId: 796,
                            code: 'Q671',
                            description: 'EF24 nothing look forward',
                            categoryId: 42,
                        },
                    ],
                },
                {
                    categoryId: 43,
                    categoryName: 'Distress',
                    parentId: 39,
                    parentCategoryName: 'Emotional Functioning',
                    items: [
                        {
                            itemId: 897,
                            code: 'Q771',
                            description: 'NV11 nausea distress',
                            categoryId: 43,
                        },
                        {
                            itemId: 964,
                            code: 'Q838',
                            description: 'DI07 distress DI',
                            categoryId: 43,
                        },
                        {
                            itemId: 1267,
                            code: 'Q952',
                            description: 'upsetting close distressed result disease treatment',
                            categoryId: 43,
                        },
                    ],
                },
                {
                    categoryId: 44,
                    categoryName: 'Embarrassment',
                    parentId: 39,
                    parentCategoryName: 'Emotional Functioning',
                    items: [
                        {
                            itemId: 241,
                            code: 'Q182',
                            description: 'embarrassed urostomy',
                            categoryId: 44,
                        },
                        {
                            itemId: 297,
                            code: 'Q237',
                            description: 'embarrassed bowel problem',
                            categoryId: 44,
                        },
                        {
                            itemId: 531,
                            code: 'Q441',
                            description: 'embarrassed stoma',
                            categoryId: 44,
                        },
                        {
                            itemId: 538,
                            code: 'Q448',
                            description: 'embarrassed bowel movement',
                            categoryId: 44,
                        },
                    ],
                },
                {
                    categoryId: 45,
                    categoryName: 'Fear',
                    parentId: 39,
                    parentCategoryName: 'Emotional Functioning',
                    items: [
                        {
                            itemId: 786,
                            code: 'Q661',
                            description: 'EF11 afraid',
                            categoryId: 45,
                        },
                    ],
                },
                {
                    categoryId: 46,
                    categoryName: 'Helplessness',
                    parentId: 39,
                    parentCategoryName: 'Emotional Functioning',
                    items: [
                        {
                            itemId: 611,
                            code: 'Q515',
                            description: 'feeling helpless',
                            categoryId: 46,
                        },
                        {
                            itemId: 777,
                            code: 'Q652',
                            description: 'EF02 helpless',
                            categoryId: 46,
                        },
                    ],
                },
                {
                    categoryId: 47,
                    categoryName: 'Mood swings',
                    parentId: 39,
                    parentCategoryName: 'Emotional Functioning',
                    items: [
                        {
                            itemId: 457,
                            code: 'Q380',
                            description: 'mood changes',
                            categoryId: 47,
                        },
                        {
                            itemId: 784,
                            code: 'Q659',
                            description: 'EF09 outbursts',
                            categoryId: 47,
                        },
                    ],
                },
                {
                    categoryId: 48,
                    categoryName: 'Stress',
                    parentId: 39,
                    parentCategoryName: 'Emotional Functioning',
                    items: [
                        {
                            itemId: 35,
                            code: 'Q24',
                            description: 'EF01 tense',
                            categoryId: 48,
                        },
                        {
                            itemId: 216,
                            code: 'Q164',
                            description: 'stress',
                            categoryId: 48,
                        },
                        {
                            itemId: 788,
                            code: 'Q663',
                            description: 'EF13 problems relax',
                            categoryId: 48,
                        },
                    ],
                },
                {
                    categoryId: 49,
                    categoryName: 'Family Life',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 40,
                            code: 'Q29',
                            description: 'SF10 PC/MT interference family life',
                            categoryId: 49,
                        },
                        {
                            itemId: 50,
                            code: 'Q38',
                            description: 'able to talk with family about illness',
                            categoryId: 49,
                        },
                        {
                            itemId: 51,
                            code: 'Q39',
                            description: 'worry family coping',
                            categoryId: 49,
                        },
                        {
                            itemId: 132,
                            code: 'Q97',
                            description: 'problem eating with family',
                            categoryId: 49,
                        },
                        {
                            itemId: 198,
                            code: 'Q152',
                            description: 'worry disruption family life',
                            categoryId: 49,
                        },
                        {
                            itemId: 219,
                            code: 'Q167',
                            description: 'worry family future',
                            categoryId: 49,
                        },
                        {
                            itemId: 462,
                            code: 'Q384',
                            description: 'need social support therapy disease',
                            categoryId: 49,
                        },
                        {
                            itemId: 588,
                            code: 'Q494',
                            description: 'effects treatment social family life',
                            categoryId: 49,
                        },
                        {
                            itemId: 627,
                            code: 'Q527',
                            description: 'distress disease treatment close people',
                            categoryId: 49,
                        },
                        {
                            itemId: 656,
                            code: 'Q553',
                            description: 'need keep fears concerns family friends',
                            categoryId: 49,
                        },
                        {
                            itemId: 719,
                            code: 'Q601',
                            description: 'share thoughts life people close',
                            categoryId: 49,
                        },
                        {
                            itemId: 720,
                            code: 'Q602',
                            description: 'love close people',
                            categoryId: 49,
                        },
                        {
                            itemId: 896,
                            code: 'Q770',
                            description: 'NV10 nausea interfere family life',
                            categoryId: 49,
                        },
                        {
                            itemId: 1130,
                            code: 'Q877',
                            description: 'family involved care',
                            categoryId: 49,
                        },
                        {
                            itemId: 1267,
                            code: 'Q952',
                            description: 'upsetting close distressed result disease treatment',
                            categoryId: 49,
                        },
                        {
                            itemId: 1268,
                            code: 'Q953',
                            description: 'worry close cope',
                            categoryId: 49,
                        },
                        {
                            itemId: 1271,
                            code: 'Q956',
                            description: 'support family friends',
                            categoryId: 49,
                        },
                    ],
                },
                {
                    categoryId: 50,
                    categoryName: 'Fatigue',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 24,
                            code: 'Q13',
                            description: 'FA27 need rest',
                            categoryId: 50,
                        },
                        {
                            itemId: 26,
                            code: 'Q15',
                            description: 'FA22 weak',
                            categoryId: 50,
                        },
                        {
                            itemId: 32,
                            code: 'Q21',
                            description: 'FA17 tired',
                            categoryId: 50,
                        },
                        {
                            itemId: 122,
                            code: 'Q87',
                            description: 'fatigue sex life',
                            categoryId: 50,
                        },
                        {
                            itemId: 205,
                            code: 'Q158',
                            description: 'FA06 slowed down',
                            categoryId: 50,
                        },
                        {
                            itemId: 206,
                            code: 'Q159',
                            description: 'FA19 lack energy',
                            categoryId: 50,
                        },
                        {
                            itemId: 315,
                            code: 'Q252',
                            description: 'FA15 weak arms legs',
                            categoryId: 50,
                        },
                        {
                            itemId: 372,
                            code: 'Q301',
                            description: 'FA29 tired eat',
                            categoryId: 50,
                        },
                        {
                            itemId: 424,
                            code: 'Q350',
                            description: 'lethargic',
                            categoryId: 50,
                        },
                        {
                            itemId: 433,
                            code: 'Q359',
                            description: 'sudden tiredness',
                            categoryId: 50,
                        },
                        {
                            itemId: 458,
                            code: 'Q381',
                            description: 'problem carry usual activities tired',
                            categoryId: 50,
                        },
                        {
                            itemId: 597,
                            code: 'Q502',
                            description: 'FA16 exhausted',
                            categoryId: 50,
                        },
                        {
                            itemId: 598,
                            code: 'Q503',
                            description: 'sleepy daytime',
                            categoryId: 50,
                        },
                        {
                            itemId: 607,
                            code: 'Q511',
                            description: 'problem get things started',
                            categoryId: 50,
                        },
                        {
                            itemId: 616,
                            code: 'Q520',
                            description: 'tired interference daily activities',
                            categoryId: 50,
                        },
                        {
                            itemId: 617,
                            code: 'Q521',
                            description: 'tired not understood close people',
                            categoryId: 50,
                        },
                        {
                            itemId: 848,
                            code: 'Q723',
                            description: 'FA01 tired eyes open day',
                            categoryId: 50,
                        },
                        {
                            itemId: 851,
                            code: 'Q726',
                            description: 'FA04 weak things',
                            categoryId: 50,
                        },
                        {
                            itemId: 853,
                            code: 'Q727',
                            description: 'FA05 lack energy things',
                            categoryId: 50,
                        },
                        {
                            itemId: 855,
                            code: 'Q729',
                            description: 'FA08 drained',
                            categoryId: 50,
                        },
                        {
                            itemId: 856,
                            code: 'Q730',
                            description: 'FA09 exhausted move body',
                            categoryId: 50,
                        },
                        {
                            itemId: 857,
                            code: 'Q731',
                            description: 'FA10 problems start tired',
                            categoryId: 50,
                        },
                        {
                            itemId: 858,
                            code: 'Q732',
                            description: 'FA11 tired simple',
                            categoryId: 50,
                        },
                        {
                            itemId: 859,
                            code: 'Q733',
                            description: 'FA12 shopping exhausting',
                            categoryId: 50,
                        },
                        {
                            itemId: 860,
                            code: 'Q734',
                            description: 'FA13 physically exhausted',
                            categoryId: 50,
                        },
                        {
                            itemId: 861,
                            code: 'Q735',
                            description: 'FA14 leisure exhausting',
                            categoryId: 50,
                        },
                        {
                            itemId: 862,
                            code: 'Q736',
                            description: 'FA18 sleep long day',
                            categoryId: 50,
                        },
                        {
                            itemId: 863,
                            code: 'Q737',
                            description: 'FA20 easily tired',
                            categoryId: 50,
                        },
                        {
                            itemId: 864,
                            code: 'Q738',
                            description: 'FA21 problems sit tired',
                            categoryId: 50,
                        },
                        {
                            itemId: 865,
                            code: 'Q739',
                            description: 'FA23 worn out',
                            categoryId: 50,
                        },
                        {
                            itemId: 866,
                            code: 'Q740',
                            description: 'FA24 long lack energy',
                            categoryId: 50,
                        },
                        {
                            itemId: 867,
                            code: 'Q741',
                            description: 'FA25 problems finish tired',
                            categoryId: 50,
                        },
                        {
                            itemId: 868,
                            code: 'Q742',
                            description: 'FA26 tired stairs',
                            categoryId: 50,
                        },
                        {
                            itemId: 869,
                            code: 'Q743',
                            description: 'FA28 need long rest',
                            categoryId: 50,
                        },
                        {
                            itemId: 871,
                            code: 'Q745',
                            description: 'FA31 extreme need rest',
                            categoryId: 50,
                        },
                        {
                            itemId: 872,
                            code: 'Q746',
                            description: 'FA32 exhausted dress',
                            categoryId: 50,
                        },
                        {
                            itemId: 873,
                            code: 'Q747',
                            description: 'FA33 tired long activity',
                            categoryId: 50,
                        },
                        {
                            itemId: 874,
                            code: 'Q748',
                            description: 'FA34 exhausted shower',
                            categoryId: 50,
                        },
                        {
                            itemId: 1257,
                            code: 'Q942',
                            description: 'mentally exhausted',
                            categoryId: 50,
                        },
                    ],
                },
                {
                    categoryId: 51,
                    categoryName: 'Financial Impact',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 42,
                            code: 'Q31',
                            description: 'FI01 financial difficulties',
                            categoryId: 51,
                        },
                        {
                            itemId: 970,
                            code: 'Q844',
                            description: 'FI02 extra expenses difficult pay',
                            categoryId: 51,
                        },
                        {
                            itemId: 971,
                            code: 'Q845',
                            description: 'FI03 extra expenses',
                            categoryId: 51,
                        },
                        {
                            itemId: 972,
                            code: 'Q846',
                            description: 'FI04 lack money basic',
                            categoryId: 51,
                        },
                        {
                            itemId: 973,
                            code: 'Q847',
                            description: 'FI05 debt',
                            categoryId: 51,
                        },
                        {
                            itemId: 974,
                            code: 'Q848',
                            description: 'FI06 change life finances',
                            categoryId: 51,
                        },
                        {
                            itemId: 975,
                            code: 'Q849',
                            description: 'FI07 less money',
                            categoryId: 51,
                        },
                        {
                            itemId: 976,
                            code: 'Q850',
                            description: 'FI08 problems pay regular expenses',
                            categoryId: 51,
                        },
                        {
                            itemId: 977,
                            code: 'Q851',
                            description: 'FI09 borrow sell',
                            categoryId: 51,
                        },
                    ],
                },
                {
                    categoryId: 52,
                    categoryName: 'Fine Motor Skills',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 400,
                            code: 'Q327',
                            description: 'problem pouring',
                            categoryId: 52,
                        },
                        {
                            itemId: 402,
                            code: 'Q329',
                            description: 'problem hold pen',
                            categoryId: 52,
                        },
                        {
                            itemId: 403,
                            code: 'Q330',
                            description: 'problem small objects fingers',
                            categoryId: 52,
                        },
                        {
                            itemId: 405,
                            code: 'Q332',
                            description: 'problem open weak hands',
                            categoryId: 52,
                        },
                    ],
                },
                {
                    categoryId: 53,
                    categoryName: 'Future Outlook',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 52,
                            code: 'Q40',
                            description: 'worry future important people',
                            categoryId: 53,
                        },
                        {
                            itemId: 53,
                            code: 'Q41',
                            description: 'worry health in future',
                            categoryId: 53,
                        },
                        {
                            itemId: 54,
                            code: 'Q42',
                            description: 'uncertain about future',
                            categoryId: 53,
                        },
                        {
                            itemId: 55,
                            code: 'Q43',
                            description: 'worry end of life',
                            categoryId: 53,
                        },
                        {
                            itemId: 56,
                            code: 'Q44',
                            description: 'positive outlook',
                            categoryId: 53,
                        },
                        {
                            itemId: 219,
                            code: 'Q167',
                            description: 'worry family future',
                            categoryId: 53,
                        },
                        {
                            itemId: 245,
                            code: 'Q186',
                            description: 'worry results',
                            categoryId: 53,
                        },
                        {
                            itemId: 246,
                            code: 'Q187',
                            description: 'worry future treatments',
                            categoryId: 53,
                        },
                        {
                            itemId: 268,
                            code: 'Q208',
                            description: 'worry mobility loss',
                            categoryId: 53,
                        },
                        {
                            itemId: 269,
                            code: 'Q209',
                            description: 'worry become dependent',
                            categoryId: 53,
                        },
                        {
                            itemId: 271,
                            code: 'Q211',
                            description: 'feel positive health',
                            categoryId: 53,
                        },
                        {
                            itemId: 278,
                            code: 'Q218',
                            description: 'worse outlook future',
                            categoryId: 53,
                        },
                        {
                            itemId: 300,
                            code: 'Q240',
                            description: 'unhappy rest life bowel habit',
                            categoryId: 53,
                        },
                        {
                            itemId: 359,
                            code: 'Q288',
                            description: 'worry weight loss continues',
                            categoryId: 53,
                        },
                        {
                            itemId: 370,
                            code: 'Q299',
                            description: 'worry become more dependent',
                            categoryId: 53,
                        },
                        {
                            itemId: 443,
                            code: 'Q368',
                            description: 'worry treatment future health problem',
                            categoryId: 53,
                        },
                        {
                            itemId: 445,
                            code: 'Q370',
                            description: 'worry continue work education',
                            categoryId: 53,
                        },
                        {
                            itemId: 506,
                            code: 'Q420',
                            description: 'worry chronically ill',
                            categoryId: 53,
                        },
                        {
                            itemId: 551,
                            code: 'Q460',
                            description: 'worried dying',
                            categoryId: 53,
                        },
                        {
                            itemId: 654,
                            code: 'Q551',
                            description: 'worry blood recover',
                            categoryId: 53,
                        },
                        {
                            itemId: 727,
                            code: 'Q609',
                            description: 'plan future',
                            categoryId: 53,
                        },
                        {
                            itemId: 728,
                            code: 'Q610',
                            description: 'worries concerns future',
                            categoryId: 53,
                        },
                        {
                            itemId: 729,
                            code: 'Q611',
                            description: 'wonder anything done',
                            categoryId: 53,
                        },
                        {
                            itemId: 796,
                            code: 'Q671',
                            description: 'EF24 nothing look forward',
                            categoryId: 53,
                        },
                        {
                            itemId: 1264,
                            code: 'Q949',
                            description: 'worry hormone tablets body scan',
                            categoryId: 53,
                        },
                        {
                            itemId: 1265,
                            code: 'Q950',
                            description: 'worry drugs life',
                            categoryId: 53,
                        },
                    ],
                },
                {
                    categoryId: 54,
                    categoryName: 'Disease progression',
                    parentId: 53,
                    parentCategoryName: 'Future Outlook',
                    items: [
                        {
                            itemId: 396,
                            code: 'Q323',
                            description: 'worry lose eye',
                            categoryId: 54,
                        },
                        {
                            itemId: 659,
                            code: 'Q556',
                            description: 'watch closely new symptoms',
                            categoryId: 54,
                        },
                        {
                            itemId: 700,
                            code: 'Q587',
                            description: 'afraid tumor progression',
                            categoryId: 54,
                        },
                    ],
                },
                {
                    categoryId: 55,
                    categoryName: 'Disease recurrence',
                    parentId: 53,
                    parentCategoryName: 'Future Outlook',
                    items: [
                        {
                            itemId: 391,
                            code: 'Q320',
                            description: 'worry tumor other areas',
                            categoryId: 55,
                        },
                        {
                            itemId: 419,
                            code: 'Q345',
                            description: 'worry tumor recur treated eye',
                            categoryId: 55,
                        },
                        {
                            itemId: 438,
                            code: 'Q364',
                            description: 'worry recurrence disease',
                            categoryId: 55,
                        },
                        {
                            itemId: 441,
                            code: 'Q366',
                            description: 'worry another type cancer',
                            categoryId: 55,
                        },
                    ],
                },
                {
                    categoryId: 56,
                    categoryName: 'Hopefulness',
                    parentId: 53,
                    parentCategoryName: 'Future Outlook',
                    items: [],
                },
                {
                    categoryId: 57,
                    categoryName: 'General Gastrointestinal',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 71,
                            code: 'Q56',
                            description: 'bloated feeling abdomen',
                            categoryId: 57,
                        },
                        {
                            itemId: 274,
                            code: 'Q214',
                            description: 'gurgling noise abdomen',
                            categoryId: 57,
                        },
                        {
                            itemId: 314,
                            code: 'Q251',
                            description: 'indigestion',
                            categoryId: 57,
                        },
                        {
                            itemId: 336,
                            code: 'Q273',
                            description: 'problems clothes tight',
                            categoryId: 57,
                        },
                        {
                            itemId: 642,
                            code: 'Q542',
                            description: 'abdomen swelling',
                            categoryId: 57,
                        },
                        {
                            itemId: 643,
                            code: 'Q543',
                            description: 'worry appearance abdomen',
                            categoryId: 57,
                        },
                        {
                            itemId: 1298,
                            code: 'Q959',
                            description: 'pain upper abdomen',
                            categoryId: 57,
                        },
                    ],
                },
                {
                    categoryId: 58,
                    categoryName: 'Abdominal pain/cramps',
                    parentId: 57,
                    parentCategoryName: 'General Gastrointestinal',
                    items: [
                        {
                            itemId: 228,
                            code: 'Q170',
                            description: 'discomfort stomach area',
                            categoryId: 58,
                        },
                        {
                            itemId: 286,
                            code: 'Q226',
                            description: 'abdominal cramp',
                            categoryId: 58,
                        },
                        {
                            itemId: 516,
                            code: 'Q430',
                            description: 'pain abdomen',
                            categoryId: 58,
                        },
                        {
                            itemId: 540,
                            code: 'Q450',
                            description: 'cramps abdomen',
                            categoryId: 58,
                        },
                        {
                            itemId: 620,
                            code: 'Q524',
                            description: 'abdominal discomfort',
                            categoryId: 58,
                        },
                        {
                            itemId: 966,
                            code: 'Q840',
                            description: 'DI09 discomfort DI',
                            categoryId: 58,
                        },
                    ],
                },
                {
                    categoryId: 59,
                    categoryName: 'Acid reflux',
                    parentId: 57,
                    parentCategoryName: 'General Gastrointestinal',
                    items: [
                        {
                            itemId: 184,
                            code: 'Q140',
                            description: 'acid reflux',
                            categoryId: 59,
                        },
                        {
                            itemId: 229,
                            code: 'Q171',
                            description: 'problem acid bile mouth',
                            categoryId: 59,
                        },
                        {
                            itemId: 230,
                            code: 'Q172',
                            description: 'acid indigestion heartburn',
                            categoryId: 59,
                        },
                    ],
                },
                {
                    categoryId: 60,
                    categoryName: 'Belching',
                    parentId: 57,
                    parentCategoryName: 'General Gastrointestinal',
                    items: [
                        {
                            itemId: 231,
                            code: 'Q173',
                            description: 'problem belching',
                            categoryId: 60,
                        },
                    ],
                },
                {
                    categoryId: 61,
                    categoryName: 'Flatulence/gas',
                    parentId: 57,
                    parentCategoryName: 'General Gastrointestinal',
                    items: [],
                },
                {
                    categoryId: 62,
                    categoryName: 'General Physical Health',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 273,
                            code: 'Q213',
                            description: 'setbacks condition',
                            categoryId: 62,
                        },
                        {
                            itemId: 452,
                            code: 'Q375',
                            description: 'worry changes weight',
                            categoryId: 62,
                        },
                        {
                            itemId: 521,
                            code: 'Q434',
                            description: 'worry weight',
                            categoryId: 62,
                        },
                        {
                            itemId: 1245,
                            code: 'Q933',
                            description: 'nose bleed',
                            categoryId: 62,
                        },
                        {
                            itemId: 1247,
                            code: 'Q935',
                            description: 'faint',
                            categoryId: 62,
                        },
                        {
                            itemId: 1299,
                            code: 'Q960',
                            description: 'loss consciousness',
                            categoryId: 62,
                        },
                        {
                            itemId: 1300,
                            code: 'Q961',
                            description: 'feel faint',
                            categoryId: 62,
                        },
                    ],
                },
                {
                    categoryId: 63,
                    categoryName: 'Allergic reactions',
                    parentId: 62,
                    parentCategoryName: 'General Physical Health',
                    items: [
                        {
                            itemId: 697,
                            code: 'Q584',
                            description: 'allergic reactions',
                            categoryId: 63,
                        },
                    ],
                },
                {
                    categoryId: 64,
                    categoryName: 'Fever',
                    parentId: 62,
                    parentCategoryName: 'General Physical Health',
                    items: [
                        {
                            itemId: 423,
                            code: 'Q349',
                            description: 'temperature go up down',
                            categoryId: 64,
                        },
                        {
                            itemId: 485,
                            code: 'Q400',
                            description: 'fevers',
                            categoryId: 64,
                        },
                        {
                            itemId: 644,
                            code: 'Q544',
                            description: 'chills',
                            categoryId: 64,
                        },
                        {
                            itemId: 651,
                            code: 'Q548',
                            description: 'fevers chills',
                            categoryId: 64,
                        },
                    ],
                },
                {
                    categoryId: 65,
                    categoryName: 'Global health',
                    parentId: 62,
                    parentCategoryName: 'General Physical Health',
                    items: [
                        {
                            itemId: 43,
                            code: 'Q32',
                            description: 'global health status',
                            categoryId: 65,
                        },
                    ],
                },
                {
                    categoryId: 66,
                    categoryName: 'Hot flushes',
                    parentId: 62,
                    parentCategoryName: 'General Physical Health',
                    items: [
                        {
                            itemId: 78,
                            code: 'Q63',
                            description: 'hot flushes',
                            categoryId: 66,
                        },
                        {
                            itemId: 560,
                            code: 'Q469',
                            description: 'hot flush sweats',
                            categoryId: 66,
                        },
                    ],
                },
                {
                    categoryId: 67,
                    categoryName: 'Infections',
                    parentId: 62,
                    parentCategoryName: 'General Physical Health',
                    items: [
                        {
                            itemId: 426,
                            code: 'Q352',
                            description: 'problem other infections',
                            categoryId: 67,
                        },
                        {
                            itemId: 427,
                            code: 'Q353',
                            description: 'need antibiotics courses',
                            categoryId: 67,
                        },
                        {
                            itemId: 428,
                            code: 'Q354',
                            description: 'worry get infection ',
                            categoryId: 67,
                        },
                        {
                            itemId: 1253,
                            code: 'Q941',
                            description: 'infections',
                            categoryId: 67,
                        },
                    ],
                },
                {
                    categoryId: 68,
                    categoryName: 'Malaise',
                    parentId: 62,
                    parentCategoryName: 'General Physical Health',
                    items: [
                        {
                            itemId: 129,
                            code: 'Q94',
                            description: 'felt ill',
                            categoryId: 68,
                        },
                        {
                            itemId: 167,
                            code: 'Q126',
                            description: 'feel ill unwell',
                            categoryId: 68,
                        },
                        {
                            itemId: 957,
                            code: 'Q831',
                            description: 'CO10 unwell',
                            categoryId: 68,
                        },
                    ],
                },
                {
                    categoryId: 69,
                    categoryName: 'Perspiration',
                    parentId: 62,
                    parentCategoryName: 'General Physical Health',
                    items: [
                        {
                            itemId: 366,
                            code: 'Q295',
                            description: 'night sweats',
                            categoryId: 69,
                        },
                        {
                            itemId: 453,
                            code: 'Q376',
                            description: 'sweat excessively',
                            categoryId: 69,
                        },
                        {
                            itemId: 920,
                            code: 'Q794',
                            description: 'DY03 problem breath sweat',
                            categoryId: 69,
                        },
                    ],
                },
                {
                    categoryId: 70,
                    categoryName: 'Weakness',
                    parentId: 62,
                    parentCategoryName: 'General Physical Health',
                    items: [
                        {
                            itemId: 26,
                            code: 'Q15',
                            description: 'FA22 weak',
                            categoryId: 70,
                        },
                        {
                            itemId: 315,
                            code: 'Q252',
                            description: 'FA15 weak arms legs',
                            categoryId: 70,
                        },
                    ],
                },
                {
                    categoryId: 71,
                    categoryName: 'Weight gain',
                    parentId: 62,
                    parentCategoryName: 'General Physical Health',
                    items: [
                        {
                            itemId: 87,
                            code: 'Q71',
                            description: 'problem weight gain',
                            categoryId: 71,
                        },
                        {
                            itemId: 163,
                            code: 'Q124',
                            description: 'weight gain',
                            categoryId: 71,
                        },
                    ],
                },
                {
                    categoryId: 72,
                    categoryName: 'Weight loss',
                    parentId: 62,
                    parentCategoryName: 'General Physical Health',
                    items: [
                        {
                            itemId: 6,
                            code: 'Q3',
                            description: 'weight loss problem',
                            categoryId: 72,
                        },
                        {
                            itemId: 162,
                            code: 'Q123',
                            description: 'weight loss',
                            categoryId: 72,
                        },
                        {
                            itemId: 239,
                            code: 'Q180',
                            description: 'worry weight too low',
                            categoryId: 72,
                        },
                        {
                            itemId: 240,
                            code: 'Q181',
                            description: 'worry weight loss',
                            categoryId: 72,
                        },
                        {
                            itemId: 359,
                            code: 'Q288',
                            description: 'worry weight loss continues',
                            categoryId: 72,
                        },
                        {
                            itemId: 368,
                            code: 'Q297',
                            description: 'feel weight loss control',
                            categoryId: 72,
                        },
                        {
                            itemId: 371,
                            code: 'Q300',
                            description: 'problem usual activities weight loss',
                            categoryId: 72,
                        },
                        {
                            itemId: 375,
                            code: 'Q304',
                            description: 'adequate information weight loss',
                            categoryId: 72,
                        },
                        {
                            itemId: 552,
                            code: 'Q461',
                            description: 'worried lose weight',
                            categoryId: 72,
                        },
                        {
                            itemId: 645,
                            code: 'Q545',
                            description: 'worry enough nourishment',
                            categoryId: 72,
                        },
                    ],
                },
                {
                    categoryId: 73,
                    categoryName: 'General QOL',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 44,
                            code: 'Q33',
                            description: 'global quality of life',
                            categoryId: 73,
                        },
                    ],
                },
                {
                    categoryId: 74,
                    categoryName: 'General Respiratory',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 944,
                            code: 'Q818',
                            description: 'DY28 cannot breathe',
                            categoryId: 74,
                        },
                    ],
                },
                {
                    categoryId: 75,
                    categoryName: 'Chest infections',
                    parentId: 74,
                    parentCategoryName: 'General Respiratory',
                    items: [
                        {
                            itemId: 425,
                            code: 'Q351',
                            description: 'problem chest infections',
                            categoryId: 75,
                        },
                    ],
                },
                {
                    categoryId: 76,
                    categoryName: 'Chest pain',
                    parentId: 74,
                    parentCategoryName: 'General Respiratory',
                    items: [],
                },
                {
                    categoryId: 77,
                    categoryName: 'Coughing',
                    parentId: 74,
                    parentCategoryName: 'General Respiratory',
                    items: [
                        {
                            itemId: 125,
                            code: 'Q90',
                            description: 'coughing',
                            categoryId: 77,
                        },
                        {
                            itemId: 480,
                            code: 'Q395',
                            description: 'problem coughing',
                            categoryId: 77,
                        },
                        {
                            itemId: 497,
                            code: 'Q411',
                            description: 'dry cough',
                            categoryId: 77,
                        },
                    ],
                },
                {
                    categoryId: 78,
                    categoryName: 'Coughing up blood',
                    parentId: 74,
                    parentCategoryName: 'General Respiratory',
                    items: [
                        {
                            itemId: 561,
                            code: 'Q470',
                            description: 'cough blood',
                            categoryId: 78,
                        },
                    ],
                },
                {
                    categoryId: 79,
                    categoryName: 'Medical Devices',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 448,
                            code: 'Q372',
                            description: 'dentures',
                            categoryId: 79,
                        },
                        {
                            itemId: 450,
                            code: 'Q373',
                            description: 'problem ill fitting dentures',
                            categoryId: 79,
                        },
                    ],
                },
                {
                    categoryId: 80,
                    categoryName: 'Drainage tubes/bags',
                    parentId: 79,
                    parentCategoryName: 'Medical Devices',
                    items: [
                        {
                            itemId: 221,
                            code: 'Q169',
                            description: 'problem drainage tubes/bags',
                            categoryId: 80,
                        },
                    ],
                },
                {
                    categoryId: 81,
                    categoryName: 'Feeding tube',
                    parentId: 79,
                    parentCategoryName: 'Medical Devices',
                    items: [
                        {
                            itemId: 161,
                            code: 'Q122',
                            description: 'feeding tube',
                            categoryId: 81,
                        },
                    ],
                },
                {
                    categoryId: 82,
                    categoryName: 'Pads',
                    parentId: 79,
                    parentCategoryName: 'Medical Devices',
                    items: [
                        {
                            itemId: 66,
                            code: 'Q52',
                            description: 'problem incontinence aid',
                            categoryId: 82,
                        },
                        {
                            itemId: 294,
                            code: 'Q234',
                            description: 'wear incontinence aid',
                            categoryId: 82,
                        },
                        {
                            itemId: 958,
                            code: 'Q832',
                            description: 'DI01 wear pad',
                            categoryId: 82,
                        },
                    ],
                },
                {
                    categoryId: 83,
                    categoryName: 'Stoma bag',
                    parentId: 79,
                    parentCategoryName: 'Medical Devices',
                    items: [
                        {
                            itemId: 524,
                            code: 'Q435',
                            description: 'stoma bag',
                            categoryId: 83,
                        },
                        {
                            itemId: 527,
                            code: 'Q437',
                            description: 'stoma leak stools',
                            categoryId: 83,
                        },
                        {
                            itemId: 528,
                            code: 'Q438',
                            description: 'stoma sore skin',
                            categoryId: 83,
                        },
                        {
                            itemId: 529,
                            code: 'Q439',
                            description: 'stoma frequent change day',
                            categoryId: 83,
                        },
                        {
                            itemId: 531,
                            code: 'Q441',
                            description: 'embarrassed stoma',
                            categoryId: 83,
                        },
                        {
                            itemId: 532,
                            code: 'Q442',
                            description: 'problem care stoma',
                            categoryId: 83,
                        },
                    ],
                },
                {
                    categoryId: 84,
                    categoryName: 'Testicular implant',
                    parentId: 79,
                    parentCategoryName: 'Medical Devices',
                    items: [
                        {
                            itemId: 209,
                            code: 'Q161',
                            description: 'satisfied testicular implant',
                            categoryId: 84,
                        },
                    ],
                },
                {
                    categoryId: 85,
                    categoryName: 'Urinary stoma',
                    parentId: 79,
                    parentCategoryName: 'Medical Devices',
                    items: [
                        {
                            itemId: 235,
                            code: 'Q176',
                            description: 'urine bag leak',
                            categoryId: 85,
                        },
                        {
                            itemId: 236,
                            code: 'Q177',
                            description: 'problem care urostomy',
                            categoryId: 85,
                        },
                        {
                            itemId: 238,
                            code: 'Q179',
                            description: 'skin irritarion urostomy',
                            categoryId: 85,
                        },
                        {
                            itemId: 241,
                            code: 'Q182',
                            description: 'embarrassed urostomy',
                            categoryId: 85,
                        },
                        {
                            itemId: 242,
                            code: 'Q183',
                            description: 'dependent others urostomy care ',
                            categoryId: 85,
                        },
                        {
                            itemId: 243,
                            code: 'Q184',
                            description: 'urostomy bag changes',
                            categoryId: 85,
                        },
                        {
                            itemId: 244,
                            code: 'Q185',
                            description: 'problem self-catheterization',
                            categoryId: 85,
                        },
                        {
                            itemId: 1159,
                            code: 'Q903',
                            description: 'urine catheter stoma bag',
                            categoryId: 85,
                        },
                    ],
                },
                {
                    categoryId: 86,
                    categoryName: 'Nausea/Vomiting',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 889,
                            code: 'Q763',
                            description: 'NV03 nausea interfere daily activities',
                            categoryId: 86,
                        },
                        {
                            itemId: 890,
                            code: 'Q764',
                            description: 'NV04 severe nausea',
                            categoryId: 86,
                        },
                        {
                            itemId: 891,
                            code: 'Q765',
                            description: 'NV05 nausea interfere enjoy life',
                            categoryId: 86,
                        },
                        {
                            itemId: 892,
                            code: 'Q766',
                            description: 'NV06 nausea eat less',
                            categoryId: 86,
                        },
                        {
                            itemId: 893,
                            code: 'Q767',
                            description: 'NV07 problem nausea',
                            categoryId: 86,
                        },
                        {
                            itemId: 894,
                            code: 'Q768',
                            description: 'NV08 nausea interfere physical activity',
                            categoryId: 86,
                        },
                        {
                            itemId: 895,
                            code: 'Q769',
                            description: 'NV09 nausea unbearable',
                            categoryId: 86,
                        },
                        {
                            itemId: 896,
                            code: 'Q770',
                            description: 'NV10 nausea interfere family life',
                            categoryId: 86,
                        },
                        {
                            itemId: 897,
                            code: 'Q771',
                            description: 'NV11 nausea distress',
                            categoryId: 86,
                        },
                        {
                            itemId: 899,
                            code: 'Q773',
                            description: 'NV13 nausea interfere leisure',
                            categoryId: 86,
                        },
                        {
                            itemId: 900,
                            code: 'Q774',
                            description: 'NV14 nausea interfere appetite',
                            categoryId: 86,
                        },
                        {
                            itemId: 901,
                            code: 'Q775',
                            description: 'NV15 nausea day night',
                            categoryId: 86,
                        },
                        {
                            itemId: 902,
                            code: 'Q776',
                            description: 'NV16 nausea unable eat',
                            categoryId: 86,
                        },
                        {
                            itemId: 904,
                            code: 'Q778',
                            description: 'NV18 nausea less fluid',
                            categoryId: 86,
                        },
                        {
                            itemId: 905,
                            code: 'Q779',
                            description: 'NV19 nausea unable eat stomach',
                            categoryId: 86,
                        },
                    ],
                },
                {
                    categoryId: 87,
                    categoryName: 'Nausea',
                    parentId: 86,
                    parentCategoryName: 'Nausea/Vomiting',
                    items: [
                        {
                            itemId: 28,
                            code: 'Q17',
                            description: 'NV01 nausea',
                            categoryId: 87,
                        },
                    ],
                },
                {
                    categoryId: 88,
                    categoryName: 'Vomiting',
                    parentId: 86,
                    parentCategoryName: 'Nausea/Vomiting',
                    items: [
                        {
                            itemId: 29,
                            code: 'Q18',
                            description: 'NV02 vomit',
                            categoryId: 88,
                        },
                    ],
                },
                {
                    categoryId: 89,
                    categoryName: 'Oral',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 72,
                            code: 'Q57',
                            description: 'mouth pain',
                            categoryId: 89,
                        },
                        {
                            itemId: 73,
                            code: 'Q58',
                            description: 'jaw pain',
                            categoryId: 89,
                        },
                        {
                            itemId: 74,
                            code: 'Q59',
                            description: 'sore mouth',
                            categoryId: 89,
                        },
                        {
                            itemId: 81,
                            code: 'Q66',
                            description: 'choked when swallowing',
                            categoryId: 89,
                        },
                        {
                            itemId: 82,
                            code: 'Q67',
                            description: 'problem teeth',
                            categoryId: 89,
                        },
                        {
                            itemId: 85,
                            code: 'Q69',
                            description: 'problem open mouth',
                            categoryId: 89,
                        },
                        {
                            itemId: 89,
                            code: 'Q73',
                            description: 'sticky saliva',
                            categoryId: 89,
                        },
                        {
                            itemId: 218,
                            code: 'Q166',
                            description: 'discomfort eating',
                            categoryId: 89,
                        },
                        {
                            itemId: 229,
                            code: 'Q171',
                            description: 'problem acid bile mouth',
                            categoryId: 89,
                        },
                        {
                            itemId: 442,
                            code: 'Q367',
                            description: 'information dental mouth problems',
                            categoryId: 89,
                        },
                        {
                            itemId: 447,
                            code: 'Q371',
                            description: 'satisfied amount information dental mouth problems',
                            categoryId: 89,
                        },
                        {
                            itemId: 448,
                            code: 'Q372',
                            description: 'dentures',
                            categoryId: 89,
                        },
                        {
                            itemId: 450,
                            code: 'Q373',
                            description: 'problem ill fitting dentures',
                            categoryId: 89,
                        },
                        {
                            itemId: 631,
                            code: 'Q531',
                            description: 'problem teeth loss',
                            categoryId: 89,
                        },
                        {
                            itemId: 639,
                            code: 'Q539',
                            description: 'problem chew',
                            categoryId: 89,
                        },
                        {
                            itemId: 1217,
                            code: 'Q905',
                            description: 'reddening mouth',
                            categoryId: 89,
                        },
                        {
                            itemId: 1263,
                            code: 'Q948',
                            description: 'knot throat',
                            categoryId: 89,
                        },
                        {
                            itemId: 1266,
                            code: 'Q951',
                            description: 'tingling numbness mouth',
                            categoryId: 89,
                        },
                    ],
                },
                {
                    categoryId: 90,
                    categoryName: 'Bleeding gums',
                    parentId: 89,
                    parentCategoryName: 'Oral',
                    items: [
                        {
                            itemId: 435,
                            code: 'Q361',
                            description: 'problem bleeding gums',
                            categoryId: 90,
                        },
                        {
                            itemId: 1242,
                            code: 'Q930',
                            description: 'bleeding gums',
                            categoryId: 90,
                        },
                    ],
                },
                {
                    categoryId: 91,
                    categoryName: 'Dry mouth',
                    parentId: 89,
                    parentCategoryName: 'Oral',
                    items: [
                        {
                            itemId: 86,
                            code: 'Q70',
                            description: 'dry mouth',
                            categoryId: 91,
                        },
                    ],
                },
                {
                    categoryId: 92,
                    categoryName: 'Dysphagia',
                    parentId: 89,
                    parentCategoryName: 'Oral',
                    items: [
                        {
                            itemId: 76,
                            code: 'Q61',
                            description: 'problem drinking liquids',
                            categoryId: 92,
                        },
                        {
                            itemId: 77,
                            code: 'Q62',
                            description: 'problem eating liquidised food',
                            categoryId: 92,
                        },
                        {
                            itemId: 79,
                            code: 'Q64',
                            description: 'problem eating solid food',
                            categoryId: 92,
                        },
                        {
                            itemId: 362,
                            code: 'Q291',
                            description: 'problem swallowing',
                            categoryId: 92,
                        },
                        {
                            itemId: 461,
                            code: 'Q383',
                            description: 'problem swallow saliva',
                            categoryId: 92,
                        },
                        {
                            itemId: 1141,
                            code: 'Q885',
                            description: 'problem swallowing liquid',
                            categoryId: 92,
                        },
                        {
                            itemId: 1142,
                            code: 'Q886',
                            description: 'problem swallow solid food',
                            categoryId: 92,
                        },
                        {
                            itemId: 1143,
                            code: 'Q887',
                            description: 'problem pureed food',
                            categoryId: 92,
                        },
                    ],
                },
                {
                    categoryId: 93,
                    categoryName: 'Feeling thirsty',
                    parentId: 89,
                    parentCategoryName: 'Oral',
                    items: [
                        {
                            itemId: 549,
                            code: 'Q458',
                            description: 'thirsty',
                            categoryId: 93,
                        },
                    ],
                },
                {
                    categoryId: 94,
                    categoryName: 'Hoarseness',
                    parentId: 89,
                    parentCategoryName: 'Oral',
                    items: [
                        {
                            itemId: 126,
                            code: 'Q91',
                            description: 'hoarseness',
                            categoryId: 94,
                        },
                        {
                            itemId: 632,
                            code: 'Q532',
                            description: 'problem hoarse',
                            categoryId: 94,
                        },
                        {
                            itemId: 1259,
                            code: 'Q944',
                            description: 'voice sound result disease treatment',
                            categoryId: 94,
                        },
                        {
                            itemId: 1260,
                            code: 'Q945',
                            description: 'tired voice',
                            categoryId: 94,
                        },
                    ],
                },
                {
                    categoryId: 95,
                    categoryName: 'Lip/mouth sores',
                    parentId: 89,
                    parentCategoryName: 'Oral',
                    items: [
                        {
                            itemId: 436,
                            code: 'Q362',
                            description: 'lip sores',
                            categoryId: 95,
                        },
                        {
                            itemId: 437,
                            code: 'Q363',
                            description: 'sores corners mouth',
                            categoryId: 95,
                        },
                    ],
                },
                {
                    categoryId: 96,
                    categoryName: 'Throat pain',
                    parentId: 89,
                    parentCategoryName: 'Oral',
                    items: [],
                },
                {
                    categoryId: 97,
                    categoryName: 'Pain',
                    parentId: null,
                    parentCategoryName: null,
                    items: [],
                },
                {
                    categoryId: 98,
                    categoryName: 'Generalized',
                    parentId: 97,
                    parentCategoryName: 'Pain',
                    items: [
                        {
                            itemId: 23,
                            code: 'Q12',
                            description: 'PA16 pain',
                            categoryId: 98,
                        },
                    ],
                },
                {
                    categoryId: 99,
                    categoryName: 'Headaches',
                    parentId: 97,
                    parentCategoryName: 'Pain',
                    items: [
                        {
                            itemId: 168,
                            code: 'Q127',
                            description: 'headaches',
                            categoryId: 99,
                        },
                    ],
                },
                {
                    categoryId: 100,
                    categoryName: 'Localized',
                    parentId: 97,
                    parentCategoryName: 'Pain',
                    items: [
                        {
                            itemId: 72,
                            code: 'Q57',
                            description: 'mouth pain',
                            categoryId: 100,
                        },
                        {
                            itemId: 73,
                            code: 'Q58',
                            description: 'jaw pain',
                            categoryId: 100,
                        },
                        {
                            itemId: 74,
                            code: 'Q59',
                            description: 'sore mouth',
                            categoryId: 100,
                        },
                        {
                            itemId: 174,
                            code: 'Q131',
                            description: 'pain arm shoulder',
                            categoryId: 100,
                        },
                        {
                            itemId: 177,
                            code: 'Q134',
                            description: 'pain area affected breast',
                            categoryId: 100,
                        },
                        {
                            itemId: 212,
                            code: 'Q163',
                            description: 'pain back',
                            categoryId: 100,
                        },
                        {
                            itemId: 233,
                            code: 'Q174',
                            description: 'pain burning feeling urinate',
                            categoryId: 100,
                        },
                        {
                            itemId: 250,
                            code: 'Q191',
                            description: 'pain in back',
                            categoryId: 100,
                        },
                        {
                            itemId: 251,
                            code: 'Q192',
                            description: 'pain in legs/hips',
                            categoryId: 100,
                        },
                        {
                            itemId: 252,
                            code: 'Q193',
                            description: 'pain in arms/shoulders',
                            categoryId: 100,
                        },
                        {
                            itemId: 253,
                            code: 'Q194',
                            description: 'pain in chest/ribs',
                            categoryId: 100,
                        },
                        {
                            itemId: 254,
                            code: 'Q195',
                            description: 'pain in buttocks',
                            categoryId: 100,
                        },
                        {
                            itemId: 286,
                            code: 'Q226',
                            description: 'abdominal cramp',
                            categoryId: 100,
                        },
                        {
                            itemId: 287,
                            code: 'Q227',
                            description: 'pain cramp rectum',
                            categoryId: 100,
                        },
                        {
                            itemId: 288,
                            code: 'Q228',
                            description: 'pain discomfort anus',
                            categoryId: 100,
                        },
                        {
                            itemId: 377,
                            code: 'Q306',
                            description: 'pain soreness discomfort treated eye',
                            categoryId: 100,
                        },
                        {
                            itemId: 392,
                            code: 'Q321',
                            description: 'cramps hands',
                            categoryId: 100,
                        },
                        {
                            itemId: 393,
                            code: 'Q322',
                            description: 'cramps feet',
                            categoryId: 100,
                        },
                        {
                            itemId: 516,
                            code: 'Q430',
                            description: 'pain abdomen',
                            categoryId: 100,
                        },
                        {
                            itemId: 517,
                            code: 'Q431',
                            description: 'pain buttock anal area rectum',
                            categoryId: 100,
                        },
                        {
                            itemId: 547,
                            code: 'Q456',
                            description: 'pain lower back',
                            categoryId: 100,
                        },
                        {
                            itemId: 703,
                            code: 'Q590',
                            description: 'pain area surgery',
                            categoryId: 100,
                        },
                        {
                            itemId: 1258,
                            code: 'Q943',
                            description: 'discomfort neck',
                            categoryId: 100,
                        },
                    ],
                },
                {
                    categoryId: 101,
                    categoryName: 'Physical Functioning',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 17,
                            code: 'Q8',
                            description: 'PF13 need stay bed chair ',
                            categoryId: 101,
                        },
                        {
                            itemId: 18,
                            code: 'Q9',
                            description: 'PF05 help with daily living',
                            categoryId: 101,
                        },
                        {
                            itemId: 193,
                            code: 'Q148',
                            description: 'physically limited disease treatment',
                            categoryId: 101,
                        },
                        {
                            itemId: 200,
                            code: 'Q154',
                            description: 'less active',
                            categoryId: 101,
                        },
                        {
                            itemId: 312,
                            code: 'Q249',
                            description: 'uncomfortable position',
                            categoryId: 101,
                        },
                        {
                            itemId: 431,
                            code: 'Q357',
                            description: ' short breath exertion',
                            categoryId: 101,
                        },
                        {
                            itemId: 629,
                            code: 'Q529',
                            description: 'limited travel',
                            categoryId: 101,
                        },
                        {
                            itemId: 702,
                            code: 'Q589',
                            description: 'decrease physical capabilities',
                            categoryId: 101,
                        },
                        {
                            itemId: 705,
                            code: 'Q592',
                            description: 'restricted performance surgery',
                            categoryId: 101,
                        },
                        {
                            itemId: 706,
                            code: 'Q593',
                            description: 'problem use arm shoulder operation',
                            categoryId: 101,
                        },
                        {
                            itemId: 753,
                            code: 'Q628',
                            description: 'PF03 need help footcare',
                            categoryId: 101,
                        },
                        {
                            itemId: 755,
                            code: 'Q630',
                            description: 'PF06 help grooming',
                            categoryId: 101,
                        },
                        {
                            itemId: 759,
                            code: 'Q634',
                            description: 'PF10',
                            categoryId: 101,
                        },
                        {
                            itemId: 762,
                            code: 'Q637',
                            description: 'PF14 need help put on shirt',
                            categoryId: 101,
                        },
                        {
                            itemId: 767,
                            code: 'Q642',
                            description: 'PF21 need help wash face hands',
                            categoryId: 101,
                        },
                        {
                            itemId: 772,
                            code: 'Q647',
                            description: 'PF27 need help undress',
                            categoryId: 101,
                        },
                        {
                            itemId: 774,
                            code: 'Q649',
                            description: 'PF28 need help eat',
                            categoryId: 101,
                        },
                        {
                            itemId: 851,
                            code: 'Q726',
                            description: 'FA04 weak things',
                            categoryId: 101,
                        },
                    ],
                },
                {
                    categoryId: 102,
                    categoryName: 'Bending',
                    parentId: 101,
                    parentCategoryName: 'Physical Functioning',
                    items: [
                        {
                            itemId: 764,
                            code: 'Q639',
                            description: 'PF17 problems bend pick',
                            categoryId: 102,
                        },
                        {
                            itemId: 926,
                            code: 'Q800',
                            description: 'DY10 short breath bend',
                            categoryId: 102,
                        },
                    ],
                },
                {
                    categoryId: 103,
                    categoryName: 'Climbing stairs',
                    parentId: 101,
                    parentCategoryName: 'Physical Functioning',
                    items: [
                        {
                            itemId: 46,
                            code: 'Q34',
                            description: 'problems steps or stairs',
                            categoryId: 103,
                        },
                        {
                            itemId: 408,
                            code: 'Q335',
                            description: 'problem stairs chair weak legs',
                            categoryId: 103,
                        },
                        {
                            itemId: 565,
                            code: 'Q474',
                            description: 'short breath stairs',
                            categoryId: 103,
                        },
                        {
                            itemId: 754,
                            code: 'Q629',
                            description: 'PF04 problems walk up stairs',
                            categoryId: 103,
                        },
                        {
                            itemId: 868,
                            code: 'Q742',
                            description: 'FA26 tired stairs',
                            categoryId: 103,
                        },
                        {
                            itemId: 941,
                            code: 'Q815',
                            description: 'DY25 short breath walk up flight stairs',
                            categoryId: 103,
                        },
                        {
                            itemId: 946,
                            code: 'Q820',
                            description: 'DY30 short breath go up few steps',
                            categoryId: 103,
                        },
                    ],
                },
                {
                    categoryId: 104,
                    categoryName: 'Coordination',
                    parentId: 101,
                    parentCategoryName: 'Physical Functioning',
                    items: [
                        {
                            itemId: 301,
                            code: 'Q241',
                            description: 'problem coordination',
                            categoryId: 104,
                        },
                    ],
                },
                {
                    categoryId: 105,
                    categoryName: 'Lifting/carrying',
                    parentId: 101,
                    parentCategoryName: 'Physical Functioning',
                    items: [
                        {
                            itemId: 7,
                            code: 'Q4',
                            description: 'PF15 problems carry bags',
                            categoryId: 105,
                        },
                        {
                            itemId: 751,
                            code: 'Q626',
                            description: 'PF01 problems lift cup glass mouth',
                            categoryId: 105,
                        },
                        {
                            itemId: 757,
                            code: 'Q632',
                            description: 'PF08 problems lift 10 kg',
                            categoryId: 105,
                        },
                        {
                            itemId: 766,
                            code: 'Q641',
                            description: 'PF20 problems carry bag upstairs',
                            categoryId: 105,
                        },
                        {
                            itemId: 770,
                            code: 'Q645',
                            description: 'PF25 problems carry 5 kg',
                            categoryId: 105,
                        },
                        {
                            itemId: 773,
                            code: 'Q648',
                            description: 'PF28 problems carry both hands upstairs',
                            categoryId: 105,
                        },
                        {
                            itemId: 775,
                            code: 'Q650',
                            description: 'PF30 problems lift pot',
                            categoryId: 105,
                        },
                    ],
                },
                {
                    categoryId: 106,
                    categoryName: 'Mobility',
                    parentId: 101,
                    parentCategoryName: 'Physical Functioning',
                    items: [
                        {
                            itemId: 48,
                            code: 'Q36',
                            description: 'unsteady on feet',
                            categoryId: 106,
                        },
                        {
                            itemId: 176,
                            code: 'Q133',
                            description: 'problem move arm',
                            categoryId: 106,
                        },
                        {
                            itemId: 268,
                            code: 'Q208',
                            description: 'worry mobility loss',
                            categoryId: 106,
                        },
                        {
                            itemId: 397,
                            code: 'Q324',
                            description: 'problem standing walking feeling ground',
                            categoryId: 106,
                        },
                        {
                            itemId: 404,
                            code: 'Q331',
                            description: 'problem steps pavements',
                            categoryId: 106,
                        },
                        {
                            itemId: 407,
                            code: 'Q334',
                            description: 'problem walking downstairs uneven ground',
                            categoryId: 106,
                        },
                    ],
                },
                {
                    categoryId: 107,
                    categoryName: 'Reaching/grasping',
                    parentId: 101,
                    parentCategoryName: 'Physical Functioning',
                    items: [
                        {
                            itemId: 763,
                            code: 'Q638',
                            description: 'PF16 problems reach object above head',
                            categoryId: 107,
                        },
                    ],
                },
                {
                    categoryId: 108,
                    categoryName: 'Running',
                    parentId: 10,
                    parentCategoryName: 'Constipation',
                    items: [
                        {
                            itemId: 765,
                            code: 'Q640',
                            description: 'PF18 problems run fast',
                            categoryId: 108,
                        },
                        {
                            itemId: 768,
                            code: 'Q643',
                            description: 'PF22 problems run 100 m',
                            categoryId: 108,
                        },
                        {
                            itemId: 776,
                            code: 'Q651',
                            description: 'PF31 problems run short',
                            categoryId: 108,
                        },
                    ],
                },
                {
                    categoryId: 109,
                    categoryName: 'Sitting',
                    parentId: 101,
                    parentCategoryName: 'Physical Functioning',
                    items: [
                        {
                            itemId: 864,
                            code: 'Q738',
                            description: 'FA21 problems sit tired',
                            categoryId: 109,
                        },
                        {
                            itemId: 1150,
                            code: 'Q894',
                            description: 'problem sit genital area',
                            categoryId: 109,
                        },
                    ],
                },
                {
                    categoryId: 110,
                    categoryName: 'Standing',
                    parentId: 101,
                    parentCategoryName: 'Physical Functioning',
                    items: [
                        {
                            itemId: 409,
                            code: 'Q336',
                            description: 'dizzy stan up sitting lying position',
                            categoryId: 110,
                        },
                        {
                            itemId: 942,
                            code: 'Q816',
                            description: 'DY26 short breath stand up chair',
                            categoryId: 110,
                        },
                    ],
                },
                {
                    categoryId: 111,
                    categoryName: 'Walking',
                    parentId: 101,
                    parentCategoryName: 'Physical Functioning',
                    items: [
                        {
                            itemId: 8,
                            code: 'Q5',
                            description: 'PF24 problems long walk',
                            categoryId: 111,
                        },
                        {
                            itemId: 9,
                            code: 'Q6',
                            description: 'PF19 problems short walk',
                            categoryId: 111,
                        },
                        {
                            itemId: 564,
                            code: 'Q473',
                            description: 'short breath walk',
                            categoryId: 111,
                        },
                        {
                            itemId: 752,
                            code: 'Q627',
                            description: 'PF02 problems walk 100 m',
                            categoryId: 111,
                        },
                        {
                            itemId: 756,
                            code: 'Q631',
                            description: 'PF07 problems walk 30 min',
                            categoryId: 111,
                        },
                        {
                            itemId: 758,
                            code: 'Q633',
                            description: 'PF09 problems hike 3 km uneven',
                            categoryId: 111,
                        },
                        {
                            itemId: 760,
                            code: 'Q635',
                            description: 'PF11 problems walking outdoors flat',
                            categoryId: 111,
                        },
                        {
                            itemId: 761,
                            code: 'Q636',
                            description: 'PF12 problems steps',
                            categoryId: 111,
                        },
                        {
                            itemId: 769,
                            code: 'Q644',
                            description: 'PF23 problems walk carry back',
                            categoryId: 111,
                        },
                        {
                            itemId: 771,
                            code: 'Q646',
                            description: 'PF26',
                            categoryId: 111,
                        },
                        {
                            itemId: 873,
                            code: 'Q747',
                            description: 'FA33 tired long activity',
                            categoryId: 111,
                        },
                        {
                            itemId: 894,
                            code: 'Q768',
                            description: 'NV08 nausea interfere physical activity',
                            categoryId: 111,
                        },
                        {
                            itemId: 929,
                            code: 'Q803',
                            description: 'DY13 short breath moderate activity',
                            categoryId: 111,
                        },
                        {
                            itemId: 933,
                            code: 'Q807',
                            description: 'DY17 short breath walk 100 m',
                            categoryId: 111,
                        },
                        {
                            itemId: 935,
                            code: 'Q809',
                            description: 'DY19 stop breath walk',
                            categoryId: 111,
                        },
                        {
                            itemId: 937,
                            code: 'Q811',
                            description: 'DY21 short breath walk',
                            categoryId: 111,
                        },
                        {
                            itemId: 939,
                            code: 'Q813',
                            description: 'DY23 short breath walk level ground',
                            categoryId: 111,
                        },
                        {
                            itemId: 943,
                            code: 'Q817',
                            description: 'DY27 short breath walk less 100 m',
                            categoryId: 111,
                        },
                        {
                            itemId: 947,
                            code: 'Q821',
                            description: 'DY31 short breath walk around home',
                            categoryId: 111,
                        },
                    ],
                },
                {
                    categoryId: 112,
                    categoryName: 'Recreation/Leisure',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 57,
                            code: 'Q45',
                            description: 'motivated continue hobbies',
                            categoryId: 112,
                        },
                        {
                            itemId: 861,
                            code: 'Q735',
                            description: 'FA14 leisure exhausting',
                            categoryId: 112,
                        },
                        {
                            itemId: 899,
                            code: 'Q773',
                            description: 'NV13 nausea interfere leisure',
                            categoryId: 112,
                        },
                    ],
                },
                {
                    categoryId: 113,
                    categoryName: 'Reproductive',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 209,
                            code: 'Q161',
                            description: 'satisfied testicular implant',
                            categoryId: 113,
                        },
                        {
                            itemId: 559,
                            code: 'Q468',
                            description: 'abnormal bleed vagina',
                            categoryId: 113,
                        },
                        {
                            itemId: 1145,
                            code: 'Q889',
                            description: 'itchy irritated skin genital area',
                            categoryId: 113,
                        },
                        {
                            itemId: 1146,
                            code: 'Q890',
                            description: 'sore skin genital area',
                            categoryId: 113,
                        },
                        {
                            itemId: 1147,
                            code: 'Q891',
                            description: 'tear split skin genital area',
                            categoryId: 113,
                        },
                        {
                            itemId: 1149,
                            code: 'Q893',
                            description: 'problem scar genital area',
                            categoryId: 113,
                        },
                        {
                            itemId: 1150,
                            code: 'Q894',
                            description: 'problem sit genital area',
                            categoryId: 113,
                        },
                        {
                            itemId: 1152,
                            code: 'Q896',
                            description: 'swelling genital area',
                            categoryId: 113,
                        },
                        {
                            itemId: 1153,
                            code: 'Q897',
                            description: 'tight skin genital area',
                            categoryId: 113,
                        },
                        {
                            itemId: 1154,
                            code: 'Q898',
                            description: 'swelling groin',
                            categoryId: 113,
                        },
                        {
                            itemId: 1155,
                            code: 'Q899',
                            description: 'sore skin groin',
                            categoryId: 113,
                        },
                    ],
                },
                {
                    categoryId: 114,
                    categoryName: 'Getting/maintaining erection',
                    parentId: 113,
                    parentCategoryName: 'Reproductive',
                    items: [
                        {
                            itemId: 94,
                            code: 'Q77',
                            description: 'problem erection',
                            categoryId: 114,
                        },
                        {
                            itemId: 145,
                            code: 'Q107',
                            description: 'confident about erection',
                            categoryId: 114,
                        },
                    ],
                },
                {
                    categoryId: 115,
                    categoryName: 'Ejaculation',
                    parentId: 113,
                    parentCategoryName: 'Reproductive',
                    items: [
                        {
                            itemId: 95,
                            code: 'Q78',
                            description: 'problem ejaculation',
                            categoryId: 115,
                        },
                    ],
                },
                {
                    categoryId: 116,
                    categoryName: 'Fertility',
                    parentId: 113,
                    parentCategoryName: 'Reproductive',
                    items: [
                        {
                            itemId: 201,
                            code: 'Q155',
                            description: 'worry fertility',
                            categoryId: 116,
                        },
                    ],
                },
                {
                    categoryId: 117,
                    categoryName: 'Vaginal discharge',
                    parentId: 113,
                    parentCategoryName: 'Reproductive',
                    items: [
                        {
                            itemId: 557,
                            code: 'Q466',
                            description: 'vaginal discharge',
                            categoryId: 117,
                        },
                        {
                            itemId: 1151,
                            code: 'Q895',
                            description: 'discharge vagina genital area',
                            categoryId: 117,
                        },
                    ],
                },
                {
                    categoryId: 118,
                    categoryName: 'Vaginal discomfort',
                    parentId: 113,
                    parentCategoryName: 'Reproductive',
                    items: [
                        {
                            itemId: 1225,
                            code: 'Q913',
                            description: 'discomfort vagina',
                            categoryId: 118,
                        },
                        {
                            itemId: 1226,
                            code: 'Q914',
                            description: 'pain vagina sex',
                            categoryId: 118,
                        },
                    ],
                },
                {
                    categoryId: 119,
                    categoryName: 'Vaginal dryness',
                    parentId: 113,
                    parentCategoryName: 'Reproductive',
                    items: [
                        {
                            itemId: 563,
                            code: 'Q472',
                            description: 'vagina dry sex',
                            categoryId: 119,
                        },
                        {
                            itemId: 1224,
                            code: 'Q912',
                            description: 'dry vagina',
                            categoryId: 119,
                        },
                    ],
                },
                {
                    categoryId: 120,
                    categoryName: 'Vaginal tightness',
                    parentId: 113,
                    parentCategoryName: 'Reproductive',
                    items: [
                        {
                            itemId: 567,
                            code: 'Q476',
                            description: 'vagina short ',
                            categoryId: 120,
                        },
                        {
                            itemId: 569,
                            code: 'Q477',
                            description: 'vagina tight',
                            categoryId: 120,
                        },
                        {
                            itemId: 591,
                            code: 'Q497',
                            description: 'vagina short tight',
                            categoryId: 120,
                        },
                        {
                            itemId: 1100,
                            code: 'Q858',
                            description: 'vagina narrow tight',
                            categoryId: 120,
                        },
                        {
                            itemId: 1148,
                            code: 'Q892',
                            description: 'narrow tight vaginal entrance',
                            categoryId: 120,
                        },
                        {
                            itemId: 1160,
                            code: 'Q904',
                            description: 'narrow tight vagina sex',
                            categoryId: 120,
                        },
                    ],
                },
                {
                    categoryId: 121,
                    categoryName: 'Role Functioning ',
                    parentId: null,
                    parentCategoryName: null,
                    items: [],
                },
                {
                    categoryId: 122,
                    categoryName: 'Household tasks',
                    parentId: 121,
                    parentCategoryName: 'Role Functioning ',
                    items: [
                        {
                            itemId: 797,
                            code: 'Q672',
                            description: 'RF01 limit household tasks',
                            categoryId: 122,
                        },
                        {
                            itemId: 798,
                            code: 'Q673',
                            description: 'RF02 limit light housework',
                            categoryId: 122,
                        },
                        {
                            itemId: 802,
                            code: 'Q677',
                            description: 'RF06 limit repairs maintenance',
                            categoryId: 122,
                        },
                        {
                            itemId: 803,
                            code: 'Q678',
                            description: 'RF07 limit finances',
                            categoryId: 122,
                        },
                        {
                            itemId: 804,
                            code: 'Q679',
                            description: 'RF09 limit heavy household',
                            categoryId: 122,
                        },
                    ],
                },
                {
                    categoryId: 123,
                    categoryName: 'Work/school',
                    parentId: 121,
                    parentCategoryName: 'Role Functioning ',
                    items: [
                        {
                            itemId: 10,
                            code: 'Q7',
                            description: 'RF08 limit work daily activities',
                            categoryId: 123,
                        },
                        {
                            itemId: 192,
                            code: 'Q147',
                            description: 'problem job education',
                            categoryId: 123,
                        },
                        {
                            itemId: 444,
                            code: 'Q369',
                            description: 'problem work/study',
                            categoryId: 123,
                        },
                        {
                            itemId: 445,
                            code: 'Q370',
                            description: 'worry continue work education',
                            categoryId: 123,
                        },
                        {
                            itemId: 800,
                            code: 'Q675',
                            description: 'RF04 need help work daily activity',
                            categoryId: 123,
                        },
                        {
                            itemId: 889,
                            code: 'Q763',
                            description: 'NV03 nausea interfere daily activities',
                            categoryId: 123,
                        },
                    ],
                },
                {
                    categoryId: 124,
                    categoryName: 'Satisfaction with Care – Health Care Setting',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 189,
                            code: 'Q145',
                            description: 'satisfied medical care',
                            categoryId: 124,
                        },
                        {
                            itemId: 688,
                            code: 'Q579',
                            description: 'waiting time results __tests__',
                            categoryId: 124,
                        },
                        {
                            itemId: 689,
                            code: 'Q580',
                            description: 'speed medical __tests__ treatments',
                            categoryId: 124,
                        },
                        {
                            itemId: 690,
                            code: 'Q581',
                            description: 'ease access',
                            categoryId: 124,
                        },
                        {
                            itemId: 691,
                            code: 'Q582',
                            description: 'ease find departments',
                            categoryId: 124,
                        },
                        {
                            itemId: 692,
                            code: 'Q583',
                            description: 'environment building',
                            categoryId: 124,
                        },
                        {
                            itemId: 1122,
                            code: 'Q869',
                            description: 'ease recognise roles HCPs',
                            categoryId: 124,
                        },
                        {
                            itemId: 1128,
                            code: 'Q875',
                            description: 'wait time test treatment',
                            categoryId: 124,
                        },
                        {
                            itemId: 1129,
                            code: 'Q876',
                            description: 'privacy examine treat',
                            categoryId: 124,
                        },
                        {
                            itemId: 1130,
                            code: 'Q877',
                            description: 'family involved care',
                            categoryId: 124,
                        },
                        {
                            itemId: 1134,
                            code: 'Q878',
                            description: 'see same HCPs outpatient',
                            categoryId: 124,
                        },
                        {
                            itemId: 1135,
                            code: 'Q879',
                            description: 'arrange appointment',
                            categoryId: 124,
                        },
                        {
                            itemId: 1136,
                            code: 'Q880',
                            description: 'waiting time obtain appointment',
                            categoryId: 124,
                        },
                    ],
                },
                {
                    categoryId: 125,
                    categoryName: 'Satisfaction with Care – Information and Communication',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 190,
                            code: 'Q146',
                            description: 'satisfied information disease treatment',
                            categoryId: 125,
                        },
                        {
                            itemId: 375,
                            code: 'Q304',
                            description: 'adequate information weight loss',
                            categoryId: 125,
                        },
                        {
                            itemId: 442,
                            code: 'Q367',
                            description: 'information dental mouth problems',
                            categoryId: 125,
                        },
                        {
                            itemId: 447,
                            code: 'Q371',
                            description: 'satisfied amount information dental mouth problems',
                            categoryId: 125,
                        },
                        {
                            itemId: 465,
                            code: 'Q387',
                            description: 'satisfied received information',
                            categoryId: 125,
                        },
                        {
                            itemId: 475,
                            code: 'Q390',
                            description: 'opportunities talk professionals',
                            categoryId: 125,
                        },
                        {
                            itemId: 478,
                            code: 'Q393',
                            description: 'free ask questions',
                            categoryId: 125,
                        },
                        {
                            itemId: 486,
                            code: 'Q401',
                            description: 'patient HCP time talk',
                            categoryId: 125,
                        },
                        {
                            itemId: 494,
                            code: 'Q409',
                            description: 'patient HCP language',
                            categoryId: 125,
                        },
                        {
                            itemId: 495,
                            code: 'Q410',
                            description: 'patient HCP answer openly',
                            categoryId: 125,
                        },
                        {
                            itemId: 503,
                            code: 'Q417',
                            description: 'patient HCP listen emotions',
                            categoryId: 125,
                        },
                        {
                            itemId: 507,
                            code: 'Q421',
                            description: 'HCP account preferences information',
                            categoryId: 125,
                        },
                        {
                            itemId: 508,
                            code: 'Q422',
                            description: 'patient HCP check information',
                            categoryId: 125,
                        },
                        {
                            itemId: 509,
                            code: 'Q423',
                            description: 'HCP check understand information',
                            categoryId: 125,
                        },
                        {
                            itemId: 511,
                            code: 'Q425',
                            description: 'HCP explain again',
                            categoryId: 125,
                        },
                        {
                            itemId: 512,
                            code: 'Q426',
                            description: 'HCP answer difficult questions',
                            categoryId: 125,
                        },
                        {
                            itemId: 513,
                            code: 'Q427',
                            description: 'HCP explain treatment aims',
                            categoryId: 125,
                        },
                        {
                            itemId: 514,
                            code: 'Q428',
                            description: 'HCP privacy',
                            categoryId: 125,
                        },
                        {
                            itemId: 515,
                            code: 'Q429',
                            description: 'satisfied HCP communication',
                            categoryId: 125,
                        },
                        {
                            itemId: 573,
                            code: 'Q479',
                            description: 'diagnosis',
                            categoryId: 125,
                        },
                        {
                            itemId: 574,
                            code: 'Q480',
                            description: 'extent disease',
                            categoryId: 125,
                        },
                        {
                            itemId: 575,
                            code: 'Q481',
                            description: 'possible causes',
                            categoryId: 125,
                        },
                        {
                            itemId: 576,
                            code: 'Q482',
                            description: 'disease control',
                            categoryId: 125,
                        },
                        {
                            itemId: 577,
                            code: 'Q483',
                            description: 'purpose __tests__',
                            categoryId: 125,
                        },
                        {
                            itemId: 578,
                            code: 'Q484',
                            description: 'procedures __tests__',
                            categoryId: 125,
                        },
                        {
                            itemId: 580,
                            code: 'Q486',
                            description: 'results medical __tests__',
                            categoryId: 125,
                        },
                        {
                            itemId: 582,
                            code: 'Q488',
                            description: 'medical treatment',
                            categoryId: 125,
                        },
                        {
                            itemId: 583,
                            code: 'Q489',
                            description: 'benefit treatment',
                            categoryId: 125,
                        },
                        {
                            itemId: 585,
                            code: 'Q491',
                            description: 'side effects',
                            categoryId: 125,
                        },
                        {
                            itemId: 587,
                            code: 'Q493',
                            description: 'expected effects symptoms',
                            categoryId: 125,
                        },
                        {
                            itemId: 588,
                            code: 'Q494',
                            description: 'effects treatment social family life',
                            categoryId: 125,
                        },
                        {
                            itemId: 589,
                            code: 'Q495',
                            description: 'effects treatment sexual activity',
                            categoryId: 125,
                        },
                        {
                            itemId: 590,
                            code: 'Q496',
                            description: 'help outside hospital',
                            categoryId: 125,
                        },
                        {
                            itemId: 592,
                            code: 'Q498',
                            description: 'rehabilitation',
                            categoryId: 125,
                        },
                        {
                            itemId: 593,
                            code: 'Q499',
                            description: 'aspects managing home',
                            categoryId: 125,
                        },
                        {
                            itemId: 594,
                            code: 'Q500',
                            description: 'psychological support',
                            categoryId: 125,
                        },
                        {
                            itemId: 595,
                            code: 'Q501',
                            description: 'places care',
                            categoryId: 125,
                        },
                        {
                            itemId: 599,
                            code: 'Q504',
                            description: 'help get well',
                            categoryId: 125,
                        },
                        {
                            itemId: 600,
                            code: 'Q505',
                            description: 'written information',
                            categoryId: 125,
                        },
                        {
                            itemId: 601,
                            code: 'Q506',
                            description: 'information cd tape video',
                            categoryId: 125,
                        },
                        {
                            itemId: 602,
                            code: 'Q507',
                            description: 'satisfied amount information',
                            categoryId: 125,
                        },
                        {
                            itemId: 603,
                            code: 'Q508',
                            description: 'wish more information',
                            categoryId: 125,
                        },
                        {
                            itemId: 605,
                            code: 'Q509',
                            description: 'wish less information',
                            categoryId: 125,
                        },
                        {
                            itemId: 606,
                            code: 'Q510',
                            description: 'information helpful',
                            categoryId: 125,
                        },
                        {
                            itemId: 630,
                            code: 'Q530',
                            description: 'problem adequate information',
                            categoryId: 125,
                        },
                        {
                            itemId: 672,
                            code: 'Q563',
                            description: 'information illness',
                            categoryId: 125,
                        },
                        {
                            itemId: 673,
                            code: 'Q564',
                            description: 'information medical test',
                            categoryId: 125,
                        },
                        {
                            itemId: 674,
                            code: 'Q565',
                            description: 'information treatment',
                            categoryId: 125,
                        },
                        {
                            itemId: 681,
                            code: 'Q572',
                            description: 'information care',
                            categoryId: 125,
                        },
                        {
                            itemId: 684,
                            code: 'Q575',
                            description: 'exchange information caregivers',
                            categoryId: 125,
                        },
                        {
                            itemId: 686,
                            code: 'Q577',
                            description: 'information admission hospital',
                            categoryId: 125,
                        },
                        {
                            itemId: 687,
                            code: 'Q578',
                            description: 'information discharge hospital',
                            categoryId: 125,
                        },
                        {
                            itemId: 1112,
                            code: 'Q863',
                            description: 'information __tests__ treatment',
                            categoryId: 125,
                        },
                        {
                            itemId: 1118,
                            code: 'Q866',
                            description: 'information care treatment',
                            categoryId: 125,
                        },
                        {
                            itemId: 1124,
                            code: 'Q871',
                            description: 'information care services community',
                            categoryId: 125,
                        },
                        {
                            itemId: 1125,
                            code: 'Q872',
                            description: 'information schedule test',
                            categoryId: 125,
                        },
                        {
                            itemId: 1126,
                            code: 'Q873',
                            description: 'information support service',
                            categoryId: 125,
                        },
                        {
                            itemId: 1127,
                            code: 'Q874',
                            description: 'information improve prevent',
                            categoryId: 125,
                        },
                        {
                            itemId: 1137,
                            code: 'Q881',
                            description: 'communicate hospital home',
                            categoryId: 125,
                        },
                        {
                            itemId: 1138,
                            code: 'Q882',
                            description: 'information should do after hospital',
                            categoryId: 125,
                        },
                        {
                            itemId: 1139,
                            code: 'Q883',
                            description: 'information contact hospital',
                            categoryId: 125,
                        },
                    ],
                },
                {
                    categoryId: 126,
                    categoryName: 'Satisfaction with Care – Quality of Care from HCPs',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 320,
                            code: 'Q257',
                            description: 'adequate support HCP',
                            categoryId: 126,
                        },
                        {
                            itemId: 464,
                            code: 'Q386',
                            description: 'satisfied received care',
                            categoryId: 126,
                        },
                        {
                            itemId: 483,
                            code: 'Q398',
                            description: 'patient HCP understanding disease',
                            categoryId: 126,
                        },
                        {
                            itemId: 484,
                            code: 'Q399',
                            description: 'patient HCP mutual trust ',
                            categoryId: 126,
                        },
                        {
                            itemId: 487,
                            code: 'Q402',
                            description: 'patient HCP approach equal',
                            categoryId: 126,
                        },
                        {
                            itemId: 488,
                            code: 'Q403',
                            description: 'HCP sincere',
                            categoryId: 126,
                        },
                        {
                            itemId: 491,
                            code: 'Q406',
                            description: 'patient HCP easy talk openly',
                            categoryId: 126,
                        },
                        {
                            itemId: 492,
                            code: 'Q407',
                            description: 'patient HCP respect',
                            categoryId: 126,
                        },
                        {
                            itemId: 493,
                            code: 'Q408',
                            description: 'problem HCP seriously',
                            categoryId: 126,
                        },
                        {
                            itemId: 498,
                            code: 'Q412',
                            description: 'patient HCP look conversation',
                            categoryId: 126,
                        },
                        {
                            itemId: 499,
                            code: 'Q413',
                            description: 'HCP calm voice',
                            categoryId: 126,
                        },
                        {
                            itemId: 500,
                            code: 'Q414',
                            description: 'patient HCP understand situation',
                            categoryId: 126,
                        },
                        {
                            itemId: 505,
                            code: 'Q419',
                            description: 'HCP help manage emotions',
                            categoryId: 126,
                        },
                        {
                            itemId: 655,
                            code: 'Q552',
                            description: 'satisfied prepare treatment',
                            categoryId: 126,
                        },
                        {
                            itemId: 666,
                            code: 'Q557',
                            description: 'knowledge experience illness',
                            categoryId: 126,
                        },
                        {
                            itemId: 667,
                            code: 'Q558',
                            description: 'treatment follow-up',
                            categoryId: 126,
                        },
                        {
                            itemId: 668,
                            code: 'Q559',
                            description: 'attention physical problems',
                            categoryId: 126,
                        },
                        {
                            itemId: 669,
                            code: 'Q560',
                            description: 'willingness listen concerns',
                            categoryId: 126,
                        },
                        {
                            itemId: 670,
                            code: 'Q561',
                            description: 'interest personally',
                            categoryId: 126,
                        },
                        {
                            itemId: 671,
                            code: 'Q562',
                            description: 'comfort support',
                            categoryId: 126,
                        },
                        {
                            itemId: 675,
                            code: 'Q566',
                            description: 'frequency visits consultations',
                            categoryId: 126,
                        },
                        {
                            itemId: 676,
                            code: 'Q567',
                            description: 'time visits consultations',
                            categoryId: 126,
                        },
                        {
                            itemId: 677,
                            code: 'Q568',
                            description: 'physical examinations',
                            categoryId: 126,
                        },
                        {
                            itemId: 678,
                            code: 'Q569',
                            description: 'handle care',
                            categoryId: 126,
                        },
                        {
                            itemId: 679,
                            code: 'Q570',
                            description: 'attention physical comfort',
                            categoryId: 126,
                        },
                        {
                            itemId: 680,
                            code: 'Q571',
                            description: 'human qualities',
                            categoryId: 126,
                        },
                        {
                            itemId: 682,
                            code: 'Q573',
                            description: 'prompt buzzer calls',
                            categoryId: 126,
                        },
                        {
                            itemId: 683,
                            code: 'Q574',
                            description: 'time devoted',
                            categoryId: 126,
                        },
                        {
                            itemId: 685,
                            code: 'Q576',
                            description: 'kind helpful technical reception laboratory',
                            categoryId: 126,
                        },
                        {
                            itemId: 1109,
                            code: 'Q860',
                            description: 'aware care treatment',
                            categoryId: 126,
                        },
                        {
                            itemId: 1110,
                            code: 'Q861',
                            description: 'attention physical symptoms',
                            categoryId: 126,
                        },
                        {
                            itemId: 1111,
                            code: 'Q862',
                            description: 'thorough treating physical symptoms',
                            categoryId: 126,
                        },
                        {
                            itemId: 1113,
                            code: 'Q864',
                            description: 'attention opinion choice',
                            categoryId: 126,
                        },
                        {
                            itemId: 1114,
                            code: 'Q865',
                            description: 'interest person',
                            categoryId: 126,
                        },
                        {
                            itemId: 1119,
                            code: 'Q867',
                            description: 'advice manage symptoms',
                            categoryId: 126,
                        },
                        {
                            itemId: 1120,
                            code: 'Q868',
                            description: 'prompt answer requests',
                            categoryId: 126,
                        },
                        {
                            itemId: 1123,
                            code: 'Q870',
                            description: 'HCPs work team',
                            categoryId: 126,
                        },
                        {
                            itemId: 1140,
                            code: 'Q884',
                            description: 'provision follow up after treatment',
                            categoryId: 126,
                        },
                        {
                            itemId: 1269,
                            code: 'Q954',
                            description: 'support doctors',
                            categoryId: 126,
                        },
                        {
                            itemId: 1270,
                            code: 'Q955',
                            description: 'support HCPs',
                            categoryId: 126,
                        },
                    ],
                },
                {
                    categoryId: 127,
                    categoryName: 'Sensory/Neurological ',
                    parentId: null,
                    parentCategoryName: null,
                    items: [],
                },
                {
                    categoryId: 128,
                    categoryName: 'Altered taste/smell',
                    parentId: 127,
                    parentCategoryName: 'Sensory/Neurological ',
                    items: [],
                },
                {
                    categoryId: 129,
                    categoryName: 'Dizziness',
                    parentId: 127,
                    parentCategoryName: 'Sensory/Neurological ',
                    items: [
                        {
                            itemId: 48,
                            code: 'Q36',
                            description: 'unsteady on feet',
                            categoryId: 129,
                        },
                        {
                            itemId: 409,
                            code: 'Q336',
                            description: 'dizzy stan up sitting lying position',
                            categoryId: 129,
                        },
                        {
                            itemId: 698,
                            code: 'Q585',
                            description: 'dizzy',
                            categoryId: 129,
                        },
                    ],
                },
                {
                    categoryId: 130,
                    categoryName: 'Neuropathy',
                    parentId: 127,
                    parentCategoryName: 'Sensory/Neurological ',
                    items: [
                        {
                            itemId: 185,
                            code: 'Q141',
                            description: 'tingling numbness fingers toes',
                            categoryId: 130,
                        },
                        {
                            itemId: 311,
                            code: 'Q248',
                            description: 'numb tingling arm shoulder',
                            categoryId: 130,
                        },
                        {
                            itemId: 356,
                            code: 'Q285',
                            description: 'tingling hands feet',
                            categoryId: 130,
                        },
                        {
                            itemId: 380,
                            code: 'Q309',
                            description: 'tingling fingers hands',
                            categoryId: 130,
                        },
                        {
                            itemId: 383,
                            code: 'Q312',
                            description: 'tingling toes feet',
                            categoryId: 130,
                        },
                        {
                            itemId: 386,
                            code: 'Q315',
                            description: 'numb fingers/hands',
                            categoryId: 130,
                        },
                        {
                            itemId: 397,
                            code: 'Q324',
                            description: 'problem standing walking feeling ground',
                            categoryId: 130,
                        },
                        {
                            itemId: 399,
                            code: 'Q326',
                            description: 'problem distinguish hot cold water',
                            categoryId: 130,
                        },
                        {
                            itemId: 411,
                            code: 'Q338',
                            description: 'problem use pedals',
                            categoryId: 130,
                        },
                        {
                            itemId: 553,
                            code: 'Q462',
                            description: 'tingling numb hands feet',
                            categoryId: 130,
                        },
                        {
                            itemId: 1220,
                            code: 'Q908',
                            description: 'tingling fingers toes',
                            categoryId: 130,
                        },
                        {
                            itemId: 1266,
                            code: 'Q951',
                            description: 'tingling numbness mouth',
                            categoryId: 130,
                        },
                    ],
                },
                {
                    categoryId: 131,
                    categoryName: 'Numbness',
                    parentId: 127,
                    parentCategoryName: 'Sensory/Neurological ',
                    items: [
                        {
                            itemId: 340,
                            code: 'Q277',
                            description: 'numb breast',
                            categoryId: 131,
                        },
                        {
                            itemId: 357,
                            code: 'Q286',
                            description: 'numb fingers toes',
                            categoryId: 131,
                        },
                        {
                            itemId: 387,
                            code: 'Q316',
                            description: 'numb toes/feet',
                            categoryId: 131,
                        },
                    ],
                },
                {
                    categoryId: 132,
                    categoryName: 'Seizures',
                    parentId: 127,
                    parentCategoryName: 'Sensory/Neurological ',
                    items: [
                        {
                            itemId: 282,
                            code: 'Q222',
                            description: 'seizures',
                            categoryId: 132,
                        },
                    ],
                },
                {
                    categoryId: 133,
                    categoryName: 'Sexual',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 88,
                            code: 'Q72',
                            description: 'interest in sex',
                            categoryId: 133,
                        },
                        {
                            itemId: 90,
                            code: 'Q74',
                            description: 'sexually active',
                            categoryId: 133,
                        },
                        {
                            itemId: 94,
                            code: 'Q77',
                            description: 'problem erection',
                            categoryId: 133,
                        },
                        {
                            itemId: 95,
                            code: 'Q78',
                            description: 'problem ejaculation',
                            categoryId: 133,
                        },
                        {
                            itemId: 96,
                            code: 'Q79',
                            description: 'uncomfortable sexually intimate',
                            categoryId: 133,
                        },
                        {
                            itemId: 105,
                            code: 'Q81',
                            description: 'importance active sex life',
                            categoryId: 133,
                        },
                        {
                            itemId: 106,
                            code: 'Q82',
                            description: 'decreased libido',
                            categoryId: 133,
                        },
                        {
                            itemId: 107,
                            code: 'Q83',
                            description: 'satisfaction sexual desire',
                            categoryId: 133,
                        },
                        {
                            itemId: 108,
                            code: 'Q84',
                            description: 'sexual activity enjoyable',
                            categoryId: 133,
                        },
                        {
                            itemId: 109,
                            code: 'Q85',
                            description: 'satisfaction reach orgasm',
                            categoryId: 133,
                        },
                        {
                            itemId: 122,
                            code: 'Q87',
                            description: 'fatigue sex life',
                            categoryId: 133,
                        },
                        {
                            itemId: 123,
                            code: 'Q88',
                            description: 'treatment sex life',
                            categoryId: 133,
                        },
                        {
                            itemId: 128,
                            code: 'Q93',
                            description: 'communication HCP sexual issues',
                            categoryId: 133,
                        },
                        {
                            itemId: 133,
                            code: 'Q98',
                            description: 'communication sexual issues partner',
                            categoryId: 133,
                        },
                        {
                            itemId: 135,
                            code: 'Q100',
                            description: 'satisfied level intimacy',
                            categoryId: 133,
                        },
                        {
                            itemId: 137,
                            code: 'Q102',
                            description: 'insecure satisfy partner',
                            categoryId: 133,
                        },
                        {
                            itemId: 139,
                            code: 'Q104',
                            description: 'satisfied sex life',
                            categoryId: 133,
                        },
                        {
                            itemId: 140,
                            code: 'Q105',
                            description: 'extent sexual enjoyment',
                            categoryId: 133,
                        },
                        {
                            itemId: 145,
                            code: 'Q107',
                            description: 'confident about erection',
                            categoryId: 133,
                        },
                        {
                            itemId: 157,
                            code: 'Q118',
                            description: 'less interest sex',
                            categoryId: 133,
                        },
                        {
                            itemId: 158,
                            code: 'Q119',
                            description: 'less sexual enjoyment',
                            categoryId: 133,
                        },
                        {
                            itemId: 207,
                            code: 'Q160',
                            description: 'satisfied sexual relationship',
                            categoryId: 133,
                        },
                        {
                            itemId: 249,
                            code: 'Q190',
                            description: 'worry contaminate bladder treatment',
                            categoryId: 133,
                        },
                        {
                            itemId: 323,
                            code: 'Q260',
                            description: 'feel less sexually attractive',
                            categoryId: 133,
                        },
                        {
                            itemId: 324,
                            code: 'Q261',
                            description: 'uncomfortable intimate situations',
                            categoryId: 133,
                        },
                        {
                            itemId: 325,
                            code: 'Q262',
                            description: 'breast role sexuality',
                            categoryId: 133,
                        },
                        {
                            itemId: 326,
                            code: 'Q263',
                            description: 'problem loss sensations breast',
                            categoryId: 133,
                        },
                        {
                            itemId: 558,
                            code: 'Q467',
                            description: 'disease treatment sex life',
                            categoryId: 133,
                        },
                        {
                            itemId: 563,
                            code: 'Q472',
                            description: 'vagina dry sex',
                            categoryId: 133,
                        },
                        {
                            itemId: 589,
                            code: 'Q495',
                            description: 'effects treatment sexual activity',
                            categoryId: 133,
                        },
                        {
                            itemId: 1160,
                            code: 'Q904',
                            description: 'narrow tight vagina sex',
                            categoryId: 133,
                        },
                        {
                            itemId: 1226,
                            code: 'Q914',
                            description: 'pain vagina sex',
                            categoryId: 133,
                        },
                    ],
                },
                {
                    categoryId: 134,
                    categoryName: 'Sleep ',
                    parentId: null,
                    parentCategoryName: null,
                    items: [],
                },
                {
                    categoryId: 135,
                    categoryName: 'Daytime sleepiness',
                    parentId: 134,
                    parentCategoryName: 'Sleep ',
                    items: [
                        {
                            itemId: 598,
                            code: 'Q503',
                            description: 'sleepy daytime',
                            categoryId: 135,
                        },
                        {
                            itemId: 646,
                            code: 'Q546',
                            description: 'need sleep day',
                            categoryId: 135,
                        },
                        {
                            itemId: 848,
                            code: 'Q723',
                            description: 'FA01 tired eyes open day',
                            categoryId: 135,
                        },
                        {
                            itemId: 862,
                            code: 'Q736',
                            description: 'FA18 sleep long day',
                            categoryId: 135,
                        },
                    ],
                },
                {
                    categoryId: 136,
                    categoryName: 'Insomnia',
                    parentId: 134,
                    parentCategoryName: 'Sleep ',
                    items: [
                        {
                            itemId: 25,
                            code: 'Q14',
                            description: 'SL05 problem sleeping',
                            categoryId: 136,
                        },
                        {
                            itemId: 912,
                            code: 'Q786',
                            description: 'SL02 wake up night',
                            categoryId: 136,
                        },
                        {
                            itemId: 913,
                            code: 'Q787',
                            description: 'SL03 wake up early',
                            categoryId: 136,
                        },
                        {
                            itemId: 914,
                            code: 'Q788',
                            description: 'SL04 problem stay asleep',
                            categoryId: 136,
                        },
                        {
                            itemId: 915,
                            code: 'Q789',
                            description: 'SL06 problem good night sleep',
                            categoryId: 136,
                        },
                        {
                            itemId: 916,
                            code: 'Q790',
                            description: 'SL07 wake up long period night',
                            categoryId: 136,
                        },
                    ],
                },
                {
                    categoryId: 137,
                    categoryName: 'Sleep disturbance ',
                    parentId: 134,
                    parentCategoryName: 'Sleep ',
                    items: [],
                },
                {
                    categoryId: 138,
                    categoryName: 'Social Functioning ',
                    parentId: null,
                    parentCategoryName: null,
                    items: [],
                },
                {
                    categoryId: 139,
                    categoryName: 'Speech',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 126,
                            code: 'Q91',
                            description: 'hoarseness',
                            categoryId: 139,
                        },
                        {
                            itemId: 144,
                            code: 'Q106',
                            description: 'problem talking to others',
                            categoryId: 139,
                        },
                        {
                            itemId: 149,
                            code: 'Q110',
                            description: 'problem talking phone',
                            categoryId: 139,
                        },
                        {
                            itemId: 298,
                            code: 'Q238',
                            description: 'problem communicate thoughts',
                            categoryId: 139,
                        },
                        {
                            itemId: 463,
                            code: 'Q385',
                            description: 'problem talking',
                            categoryId: 139,
                        },
                        {
                            itemId: 632,
                            code: 'Q532',
                            description: 'problem hoarse',
                            categoryId: 139,
                        },
                        {
                            itemId: 633,
                            code: 'Q533',
                            description: 'problem talk noisy environment',
                            categoryId: 139,
                        },
                        {
                            itemId: 640,
                            code: 'Q540',
                            description: 'problem speak clearly',
                            categoryId: 139,
                        },
                        {
                            itemId: 934,
                            code: 'Q808',
                            description: 'DY18 short breath talk',
                            categoryId: 139,
                        },
                        {
                            itemId: 1259,
                            code: 'Q944',
                            description: 'voice sound result disease treatment',
                            categoryId: 139,
                        },
                        {
                            itemId: 1260,
                            code: 'Q945',
                            description: 'tired voice',
                            categoryId: 139,
                        },
                    ],
                },
                {
                    categoryId: 140,
                    categoryName: 'Spirituality',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 657,
                            code: 'Q554',
                            description: 'experience distinguish important things life',
                            categoryId: 140,
                        },
                        {
                            itemId: 713,
                            code: 'Q595',
                            description: 'able deal with problems',
                            categoryId: 140,
                        },
                        {
                            itemId: 714,
                            code: 'Q596',
                            description: 'peace myself',
                            categoryId: 140,
                        },
                        {
                            itemId: 716,
                            code: 'Q598',
                            description: 'forgive things done',
                            categoryId: 140,
                        },
                        {
                            itemId: 719,
                            code: 'Q601',
                            description: 'share thoughts life people close',
                            categoryId: 140,
                        },
                        {
                            itemId: 725,
                            code: 'Q607',
                            description: 'life fulfil',
                            categoryId: 140,
                        },
                        {
                            itemId: 726,
                            code: 'Q608',
                            description: 'life worthwhile',
                            categoryId: 140,
                        },
                        {
                            itemId: 730,
                            code: 'Q612',
                            description: 'felt unfair ill',
                            categoryId: 140,
                        },
                        {
                            itemId: 731,
                            code: 'Q613',
                            description: 'time quietness prayer meditation',
                            categoryId: 140,
                        },
                        {
                            itemId: 732,
                            code: 'Q614',
                            description: 'important others pray for me',
                            categoryId: 140,
                        },
                        {
                            itemId: 733,
                            code: 'Q615',
                            description: 'believe god',
                            categoryId: 140,
                        },
                        {
                            itemId: 734,
                            code: 'Q616',
                            description: 'always believed god',
                            categoryId: 140,
                        },
                        {
                            itemId: 735,
                            code: 'Q617',
                            description: 'beliefs changed less well',
                            categoryId: 140,
                        },
                        {
                            itemId: 736,
                            code: 'Q618',
                            description: 'beliefs changed last weeks',
                            categoryId: 140,
                        },
                        {
                            itemId: 737,
                            code: 'Q619',
                            description: 'connected god',
                            categoryId: 140,
                        },
                        {
                            itemId: 738,
                            code: 'Q620',
                            description: 'feel will live words, deeds, influence others',
                            categoryId: 140,
                        },
                        {
                            itemId: 739,
                            code: 'Q621',
                            description: 'feelings life changed less well',
                            categoryId: 140,
                        },
                        {
                            itemId: 740,
                            code: 'Q622',
                            description: ' feelings life changed last weeks',
                            categoryId: 140,
                        },
                        {
                            itemId: 741,
                            code: 'Q623',
                            description: 'life after death',
                            categoryId: 140,
                        },
                        {
                            itemId: 742,
                            code: 'Q624',
                            description: 'spiritual wellbeing',
                            categoryId: 140,
                        },
                        {
                            itemId: 743,
                            code: 'Q625',
                            description: 'global spiritual wellbeing',
                            categoryId: 140,
                        },
                    ],
                },
                {
                    categoryId: 141,
                    categoryName: 'Surgery',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 398,
                            code: 'Q325',
                            description: 'dissatisfied cosmetic surgery',
                            categoryId: 141,
                        },
                        {
                            itemId: 704,
                            code: 'Q591',
                            description: 'area wound oversensitive',
                            categoryId: 141,
                        },
                        {
                            itemId: 705,
                            code: 'Q592',
                            description: 'restricted performance surgery',
                            categoryId: 141,
                        },
                        {
                            itemId: 706,
                            code: 'Q593',
                            description: 'problem use arm shoulder operation',
                            categoryId: 141,
                        },
                        {
                            itemId: 707,
                            code: 'Q594',
                            description: 'scar pain daily activities',
                            categoryId: 141,
                        },
                        {
                            itemId: 1227,
                            code: 'Q915',
                            description: 'satisfied cosmetic result surgery',
                            categoryId: 141,
                        },
                    ],
                },
                {
                    categoryId: 142,
                    categoryName: 'Urinary',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 67,
                            code: 'Q53',
                            description: 'urinary problems daily activities',
                            categoryId: 142,
                        },
                        {
                            itemId: 236,
                            code: 'Q177',
                            description: 'problem care urostomy',
                            categoryId: 142,
                        },
                        {
                            itemId: 238,
                            code: 'Q179',
                            description: 'skin irritarion urostomy',
                            categoryId: 142,
                        },
                        {
                            itemId: 243,
                            code: 'Q184',
                            description: 'urostomy bag changes',
                            categoryId: 142,
                        },
                        {
                            itemId: 244,
                            code: 'Q185',
                            description: 'problem self-catheterization',
                            categoryId: 142,
                        },
                        {
                            itemId: 249,
                            code: 'Q190',
                            description: 'worry contaminate bladder treatment',
                            categoryId: 142,
                        },
                        {
                            itemId: 490,
                            code: 'Q405',
                            description: 'worry repeated bladder treatment',
                            categoryId: 142,
                        },
                        {
                            itemId: 1159,
                            code: 'Q903',
                            description: 'urine catheter stoma bag',
                            categoryId: 142,
                        },
                    ],
                },
                {
                    categoryId: 143,
                    categoryName: 'Painful urination',
                    parentId: 142,
                    parentCategoryName: 'Urinary',
                    items: [
                        {
                            itemId: 233,
                            code: 'Q174',
                            description: 'pain burning feeling urinate',
                            categoryId: 143,
                        },
                    ],
                },
                {
                    categoryId: 144,
                    categoryName: 'Urinary frequency',
                    parentId: 142,
                    parentCategoryName: 'Urinary',
                    items: [
                        {
                            itemId: 4,
                            code: 'Q2',
                            description: 'frequent urination day',
                            categoryId: 144,
                        },
                        {
                            itemId: 364,
                            code: 'Q293',
                            description: 'urinate frequently',
                            categoryId: 144,
                        },
                        {
                            itemId: 1097,
                            code: 'Q856',
                            description: 'frequent urination',
                            categoryId: 144,
                        },
                    ],
                },
                {
                    categoryId: 145,
                    categoryName: 'Urinary incontinence',
                    parentId: 142,
                    parentCategoryName: 'Urinary',
                    items: [
                        {
                            itemId: 66,
                            code: 'Q52',
                            description: 'problem incontinence aid',
                            categoryId: 145,
                        },
                        {
                            itemId: 110,
                            code: 'Q86',
                            description: 'worry incontinent',
                            categoryId: 145,
                        },
                        {
                            itemId: 235,
                            code: 'Q176',
                            description: 'urine bag leak',
                            categoryId: 145,
                        },
                        {
                            itemId: 310,
                            code: 'Q247',
                            description: 'problem controlling bladder',
                            categoryId: 145,
                        },
                        {
                            itemId: 543,
                            code: 'Q452',
                            description: 'leak urine',
                            categoryId: 145,
                        },
                    ],
                },
                {
                    categoryId: 146,
                    categoryName: 'Urinary retention',
                    parentId: 142,
                    parentCategoryName: 'Urinary',
                    items: [
                        {
                            itemId: 544,
                            code: 'Q453',
                            description: 'problem empty bladder',
                            categoryId: 146,
                        },
                    ],
                },
                {
                    categoryId: 147,
                    categoryName: 'Urinary urgency',
                    parentId: 142,
                    parentCategoryName: 'Urinary',
                    items: [
                        {
                            itemId: 60,
                            code: 'Q48',
                            description: 'hurry to toilet',
                            categoryId: 147,
                        },
                    ],
                },
                {
                    categoryId: 148,
                    categoryName: 'Visual',
                    parentId: null,
                    parentCategoryName: null,
                    items: [
                        {
                            itemId: 154,
                            code: 'Q115',
                            description: 'eyes painful irritated watery',
                            categoryId: 148,
                        },
                        {
                            itemId: 280,
                            code: 'Q220',
                            description: 'blurred vision',
                            categoryId: 148,
                        },
                        {
                            itemId: 281,
                            code: 'Q221',
                            description: 'problems reading vision',
                            categoryId: 148,
                        },
                        {
                            itemId: 376,
                            code: 'Q305',
                            description: 'grittiness treated eye',
                            categoryId: 148,
                        },
                        {
                            itemId: 377,
                            code: 'Q306',
                            description: 'pain soreness discomfort treated eye',
                            categoryId: 148,
                        },
                        {
                            itemId: 378,
                            code: 'Q307',
                            description: 'itching treated eye',
                            categoryId: 148,
                        },
                        {
                            itemId: 379,
                            code: 'Q308',
                            description: 'problem watering treated eye',
                            categoryId: 148,
                        },
                        {
                            itemId: 381,
                            code: 'Q310',
                            description: 'problem discharge treated eye',
                            categoryId: 148,
                        },
                        {
                            itemId: 382,
                            code: 'Q311',
                            description: 'dryness treated eye',
                            categoryId: 148,
                        },
                        {
                            itemId: 384,
                            code: 'Q313',
                            description: 'problem defects side vision',
                            categoryId: 148,
                        },
                        {
                            itemId: 396,
                            code: 'Q323',
                            description: 'worry lose eye',
                            categoryId: 148,
                        },
                        {
                            itemId: 400,
                            code: 'Q327',
                            description: 'problem pouring',
                            categoryId: 148,
                        },
                        {
                            itemId: 401,
                            code: 'Q328',
                            description: 'problem walk crowd',
                            categoryId: 148,
                        },
                        {
                            itemId: 404,
                            code: 'Q331',
                            description: 'problem steps pavements',
                            categoryId: 148,
                        },
                        {
                            itemId: 407,
                            code: 'Q334',
                            description: 'problem walking downstairs uneven ground',
                            categoryId: 148,
                        },
                        {
                            itemId: 412,
                            code: 'Q339',
                            description: 'activities limited vision',
                            categoryId: 148,
                        },
                        {
                            itemId: 414,
                            code: 'Q340',
                            description: 'distorted treated eye',
                            categoryId: 148,
                        },
                        {
                            itemId: 417,
                            code: 'Q343',
                            description: 'bright light uncomfortable',
                            categoryId: 148,
                        },
                        {
                            itemId: 418,
                            code: 'Q344',
                            description: 'vision interfere treated eye',
                            categoryId: 148,
                        },
                        {
                            itemId: 419,
                            code: 'Q345',
                            description: 'worry tumor recur treated eye',
                            categoryId: 148,
                        },
                        {
                            itemId: 420,
                            code: 'Q346',
                            description: 'problem driving daylight',
                            categoryId: 148,
                        },
                        {
                            itemId: 421,
                            code: 'Q347',
                            description: 'problem driving dark ',
                            categoryId: 148,
                        },
                        {
                            itemId: 455,
                            code: 'Q378',
                            description: 'problem eyes',
                            categoryId: 148,
                        },
                        {
                            itemId: 550,
                            code: 'Q459',
                            description: 'burning sore eyes',
                            categoryId: 148,
                        },
                        {
                            itemId: 1230,
                            code: 'Q918',
                            description: 'watery eyes',
                            categoryId: 148,
                        },
                        {
                            itemId: 1231,
                            code: 'Q919',
                            description: 'dry eyes',
                            categoryId: 148,
                        },
                        {
                            itemId: 1232,
                            code: 'Q920',
                            description: 'red eyes',
                            categoryId: 148,
                        },
                        {
                            itemId: 1233,
                            code: 'Q921',
                            description: 'itchy eyes',
                            categoryId: 148,
                        },
                        {
                            itemId: 1234,
                            code: 'Q922',
                            description: 'burning eyes',
                            categoryId: 148,
                        },
                    ],
                },
                {
                    categoryId: 149,
                    categoryName: 'Depth perception',
                    parentId: 148,
                    parentCategoryName: 'Visual',
                    items: [
                        {
                            itemId: 410,
                            code: 'Q337',
                            description: 'problem judge distance',
                            categoryId: 149,
                        },
                    ],
                },
                {
                    categoryId: 150,
                    categoryName: 'Double vision',
                    parentId: 148,
                    parentCategoryName: 'Visual',
                    items: [
                        {
                            itemId: 279,
                            code: 'Q219',
                            description: 'double vision',
                            categoryId: 150,
                        },
                        {
                            itemId: 385,
                            code: 'Q314',
                            description: 'problem double vision straight',
                            categoryId: 150,
                        },
                        {
                            itemId: 388,
                            code: 'Q317',
                            description: 'problem double vision sideways',
                            categoryId: 150,
                        },
                    ],
                },
                {
                    categoryId: 151,
                    categoryName: 'Face recognition',
                    parentId: 148,
                    parentCategoryName: 'Visual',
                    items: [
                        {
                            itemId: 819,
                            code: 'Q694',
                            description: 'CF17 problems recognise',
                            categoryId: 151,
                        },
                    ],
                },
                {
                    categoryId: 152,
                    categoryName: 'Floaters and flashers',
                    parentId: 148,
                    parentCategoryName: 'Visual',
                    items: [
                        {
                            itemId: 415,
                            code: 'Q341',
                            description: 'flash balls light treated eye',
                            categoryId: 152,
                        },
                        {
                            itemId: 416,
                            code: 'Q342',
                            description: 'floaters treated eye',
                            categoryId: 152,
                        },
                    ],
                },
            ]),
        ),
    ),
];
