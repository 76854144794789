import * as yup from 'yup';
import getIsRequiredText from 'functions/validation/getIsRequiredText';

export const emailSchema = yup.string().label('Email').email();
export const emailRequiredSchema = emailSchema.required();
export const passwordSchema = yup.string().min(8);
export const passwordRequiredSchema = passwordSchema.required(getIsRequiredText('Password'));
export const passwordOptionalSchema = yup.string().test('len', 'password must be at least 8 characters', (val) => {
    if (val === undefined) {
        return true;
    }
    return val.length === 0 || val.length >= 8;
});
