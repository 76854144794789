import { Modal } from 'react-bootstrap';
import { ReactNode } from 'react';

export type ModalBodyProps = {
    children: ReactNode;
};

const ModalBody = ({ children }: ModalBodyProps) => <Modal.Body>{children}</Modal.Body>;

export default ModalBody;
