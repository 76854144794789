import useAlerts from 'hooks/useAlerts';
import Alert from './Alert';

function Alerts() {
    const { alerts } = useAlerts();

    return (
        <>
            {alerts.map((alert, index) => (
                <Alert alert={alert} key={alert.id} offSet={index} />
            ))}
        </>
    );
}

export default Alerts;
