import { rest } from 'msw';
import { getRequestsPath } from 'consts/paths/api/requests/requestsPath';
import resolvePath from 'mocks/resolvePath';
import mockRequests from './requests.json';

export default ({ delay = 1500 } = {}) => {
    return [
        rest.get(resolvePath(getRequestsPath), (req, res, ctx) => {
            return res(ctx.delay(delay), ctx.status(200), ctx.json(mockRequests));
        }),
    ];
};
