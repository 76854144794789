enum QuestionnaireType {
    CORE = 'core',
    MODULE = 'module',
    STANDALONE = 'standalone',
    PREVIOUS = 'previous',
    CAT = 'cat',
    CAT_SHORT = 'catshort',
    ITEM_LIST = 'itemlist',
    SHORT_FORM = 'shortform',
}

export default QuestionnaireType;
