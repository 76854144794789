import * as yup from 'yup';
import { passwordSchema } from 'components/_form/schema-presets';

export const updatePasswordFormModalSchema = yup.object().shape({
    challenge: yup.string(),
    currentPassword: passwordSchema,
    newPassword: passwordSchema,
    confirmPassword: yup.string().oneOf([yup.ref('newPassword')], 'Passwords must match'),
});

export const updatePasswordFormModalDefaultValues = {
    challenge: 'password',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
};
