import LoggedOutHeader from 'layout/Header/LoggedOutHeader';
import { ReactNode } from 'react';
import FooterBase from 'layout/Footer/Base';
import ErrorBoundary from 'components/_errors/ErrorBoundary';

export type LoggedOutLayoutProps = {
    children: ReactNode;
};
const LoggedOutLayout = ({ children }: LoggedOutLayoutProps) => (
    <>
        <LoggedOutHeader />
        <ErrorBoundary>{children}</ErrorBoundary>
        <FooterBase />
    </>
);

export default LoggedOutLayout;
