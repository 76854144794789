import { rest } from 'msw';
import usersPath from 'consts/paths/api/auth/users/users';
import resolvePath from 'mocks/resolvePath';

export default ({ delay = 0 } = {}) => [
    rest.put(resolvePath(`${usersPath}/*`), (req, res, ctx) =>
        res(
            ctx.delay(delay),
            ctx.status(200),
            ctx.json({
                roles: ['viewTranslations', 'viewContent', 'manageOwnCustom'],
                questionnaires: [],
                id: 80,
                email: 'z.obradovic@emakina.rs',
                userName: 'z.obradovic@emakina.rs',
                profile: 'academic',
                name: 'Zoc',
                status: 'pending',
                country: 'AO',
                organisation: 'Emk',
                motivation: 'Testing e',
            }),
        ),
    ),
];
