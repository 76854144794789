import { rest } from 'msw';
import questionnaireSummariesPath from 'consts/paths/api/questionnaires/getSummaries';
import resolvePath from 'mocks/resolvePath';
import questionnaires from './questionnaires.json';
export default ({ delay = 1500 } = {}) => {
    return [
        rest.get(resolvePath(questionnaireSummariesPath), (req, res, ctx) => {
            const kind = req.url.searchParams.get('kind');
            return res(
                ctx.delay(delay),
                ctx.status(200),
                ctx.json(questionnaires.filter((q) => kind === null || !!parseInt(kind) === q.isCustom)),
            );
        }),
    ];
};
