import { rest } from 'msw';
import usersPath from 'consts/paths/api/auth/users/users';
import resolvePath from 'mocks/resolvePath';

export default ({ delay = 0 } = {}) => [
    rest.post(resolvePath(usersPath), (req, res, ctx) =>
        res(
            ctx.delay(delay),
            ctx.status(200),
            ctx.json({ id: 78, roles: ['viewTranslations', 'viewContent', 'manageOwnCustom'] }),
        ),
    ),
];
