import { rest } from 'msw';
import itemsSearchPath from 'consts/paths/api/items/search/searchScalePath';
import resolvePath from 'mocks/resolvePath';
import responseScales from './responseScales.json';
import ItemType from '../../../../_types/api/items/ItemType';
export default ({ delay = 1500 } = {}) => {
    return [
        rest.get(resolvePath(itemsSearchPath), (req, res, ctx) => {
            const type = req.url.searchParams.get('type');
            if (type !== ItemType.ResponseScale) {
                return;
            }
            return res(ctx.delay(delay), ctx.status(200), ctx.json(responseScales));
        }),
    ];
};
