import { rest } from 'msw';
import questionnaireItemsPath from 'consts/paths/api/questionnaires/questionnaireItemsPath';
import resolvePath from 'mocks/resolvePath';

export default ({ delay = 1500 } = {}) => [
    rest.post(resolvePath(`${questionnaireItemsPath}`), (req, res, ctx) =>
        res(
            ctx.delay(delay),
            ctx.status(200),
            ctx.json({
                comment: null,
                createDate: '2022-03-21T15:12:43.6665031+00:00',
                id: 9141,
                item: 748,
                position: 26,
                questionnaire: 1000243,
                relatedQuestions: [],
                updateDate: '2022-03-21T15:12:43.6665031+00:00',
                wording:
                    'Doctor(s), Nurse(s), Psychologist(s), Radiotherapy technician(s), Other (please specify: _____________)',
            }),
        ),
    ),
];
