import { ReactElement } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type TooltipWrapperProps = {
    placement: 'top' | 'right' | 'bottom' | 'left';
    text: string;
    children: ReactElement;
    tooltipId: string;
};

const TooltipWrapper = ({ placement, text, children, tooltipId }: TooltipWrapperProps) => (
    <OverlayTrigger placement={placement} overlay={<Tooltip id={tooltipId}>{text}</Tooltip>}>
        <span className="d-inline-block">{children}</span>
    </OverlayTrigger>
);

export default TooltipWrapper;
