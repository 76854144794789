import { rest } from 'msw';
import resolvePath from 'mocks/resolvePath';
import usage from './usage.json';
import usagePath from 'consts/paths/api/usage';

export default ({ delay = 2500 } = {}) => {
    return [
        rest.get(resolvePath(usagePath), (req, res, ctx) => res(ctx.delay(delay), ctx.status(200), ctx.json(usage))),
    ];
};
