import { rest } from 'msw';
import path from 'consts/paths/api/users/current-user';
import resolvePath from '../../resolvePath';

export default ({ delay = 0 } = {}) => [
    rest.put(resolvePath(`${path}/*`), (req, res, ctx) =>
        res(
            ctx.delay(delay),
            ctx.status(200),
            ctx.json({
                roles: [
                    'viewTranslations',
                    'viewContent',
                    'manageContent',
                    'manageOwnCustom',
                    'manageUsers',
                    'manageQuestionnaireRequests',
                    'downloadQuestionnaire',
                ],
                questionnaires: [],
                id: 73,
                email: 'test@test.com',
                userName: 'test@stest.com',
                profile: 'administrator',
                name: 'Z test (admin).',
                status: 'approved',
                country: 'RS',
                organisation: 'Emakina',
                motivation: 'I want to kick-off backbone.js and refactor the SPA in React.',
            }),
        ),
    ),
];
