import axios, { AxiosError, AxiosInstance, AxiosRequestHeaders } from 'axios';
import baseUrl from 'consts/api/apiBaseUrl';
import { useMemo } from 'react';
import useUserSession from './useUserSession';

const useApi = (): AxiosInstance => {
    const { accessToken, invalidateAccessToken } = useUserSession();

    return useMemo(() => {
        const headers: AxiosRequestHeaders = accessToken
            ? {
                  Authorization: `Bearer ${accessToken}`,
              }
            : {};

        const client = axios.create({
            baseURL: baseUrl,
            headers,
        });

        client.interceptors.response.use(undefined, (error: AxiosError) => {
            if (error.response?.status === 401) {
                invalidateAccessToken();
            }
            return Promise.reject(error);
        });

        return client;
    }, [accessToken, invalidateAccessToken]);
};

export default useApi;
