import { rest } from 'msw';
import forgotPasswordPath from 'consts/paths/api/auth/forgotPassword';
import resolvePath from '../resolvePath';

export default ({ delay = 0 } = {}) => [
    rest.post(resolvePath(forgotPasswordPath), (req, res, ctx) =>
        res(
            ctx.delay(delay),
            ctx.status(200),
            ctx.json({
                respose: 'All good',
            }),
        ),
    ),
];
