import { rest } from 'msw';
import questionnairesPath from 'consts/paths/api/questionnaires/questionnairesPath';
import { languagesPath } from 'consts/paths/api/languages';
import resolvePath from 'mocks/resolvePath';

export default ({ delay = 1500 } = {}) => [
    rest.post(resolvePath(`${questionnairesPath}/*${languagesPath}`), (req, res, ctx) =>
        res(ctx.delay(delay), ctx.status(200), ctx.json({})),
    ),
];
