import { Modal, Button } from 'react-bootstrap';
import { ReactNode } from 'react';
import styles from './modalHeader.module.scss';

export type ModalHeaderProps = {
    children?: ReactNode;
    title: string;
    hide: () => void;
    isReducible?: boolean;
    toggleReduce?: () => void;
    isReduced?: boolean;
};

const ModalHeader = ({ children, title, hide, isReducible, toggleReduce, isReduced }: ModalHeaderProps) => (
    <Modal.Header className={`${styles.header} ${isReduced && styles.headerReduced}`}>
        <Modal.Title className={styles.modalTitle}>{title}</Modal.Title>
        {isReducible && (
            <Button type="button" className={styles.leftButton} aria-label="Close" onClick={toggleReduce}>
                <span aria-hidden="true">
                    {isReduced ? (
                        <i className="bi bi-arrow-up-left-square"></i>
                    ) : (
                        <i className="bi bi-arrow-down-right-square"></i>
                    )}
                </span>
            </Button>
        )}
        {!isReduced && (
            <Button type="button" className={styles.button} aria-label="Close" onClick={hide}>
                <span aria-hidden="true">
                    <i className="bi bi-x-square"></i>
                </span>
            </Button>
        )}
        {children}
    </Modal.Header>
);

export default ModalHeader;
