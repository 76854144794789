import { rest } from 'msw';
import questionnairesPath from 'consts/paths/api/questionnaires/questionnairesPath';
import resolvePath from 'mocks/resolvePath';

export default ({ delay = 0 } = {}) => [
    rest.put(resolvePath(`${questionnairesPath}/*`), (req, res, ctx) =>
        res(
            ctx.delay(delay),
            ctx.status(200),
            ctx.json({
                name: 'asking for publish',
                code: null,
                type: 'module',
                state: 'published',
                phase: null,
                gender: 'nonSpecific',
                chemical: '',
                description: '',
                additionalInfo: null,
                contact: null,
                isCustom: true,
                author: 37,
                questionsStartPosition: null,
                questionnaireItems: [
                    {
                        questionnaire: 1000177,
                        item: 25,
                        position: 1,
                        wording: 'Have you had trouble sleeping?',
                        comment: null,
                        relatedQuestions: [],
                        id: 8960,
                        createDate: '2018-09-28T12:00:32.84',
                        updateDate: '2018-09-28T12:00:32.84',
                    },
                    {
                        questionnaire: 1000177,
                        item: 1000975,
                        position: 2,
                        wording: 'testq',
                        comment: null,
                        relatedQuestions: [],
                        id: 8962,
                        createDate: '2018-09-28T12:06:37.88',
                        updateDate: '2018-09-28T12:06:37.88',
                    },
                    {
                        questionnaire: 1000177,
                        item: 1001009,
                        position: 1,
                        wording: 'Test DS',
                        comment: null,
                        relatedQuestions: [],
                        id: 8968,
                        createDate: '2018-10-02T16:38:02.217',
                        updateDate: '2018-10-02T16:38:02.217',
                    },
                ],
                languages: [
                    { code: 'af', name: 'Afrikaans' },
                    { code: 'en', name: 'English' },
                    { code: 'eo', name: 'Esperanto' },
                    { code: 'lang441', name: 'French (Europe)' },
                ],
                id: 1000177,
                createDate: '2017-04-21T18:45:53.697',
                updateDate: '2022-02-21T15:05:23.493',
            }),
        ),
    ),
];
