/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ReactNode } from 'react';
import Button from 'elements/Button';
import { setLocale as setYupLocale } from 'yup';
import { UseFormReturn } from 'hooks/useForm';
import Warning from 'elements/Warning';
import ButtonWithLoader from 'elements/ButtonWithLoader';
import ModalBody from '../ModalBody';
import ModalFooter from '../ModalFooter';
import { DevTool } from '@hookform/devtools';

setYupLocale({
    mixed: {
        // eslint-disable-next-line no-template-curly-in-string
        required: '${path} is required',
    },
});

export interface CancelSubmitModalProps<Values> {
    form: UseFormReturn<Values>;
    onCancelClick: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: (props: UseFormReturn<Values>) => ReactNode;
    submitButtonText: string;
    cancelButtonText?: string;
    buttonInsteadCancelCallback?: () => void;
    buttonInsteadCancelText?: string;
}

function FormModalInner<Values>({
    form,
    onCancelClick,
    children,
    submitButtonText,
    cancelButtonText,
    buttonInsteadCancelText,
    buttonInsteadCancelCallback,
}: CancelSubmitModalProps<Values>) {
    const { handleSubmit, warningMessage, isLoading, control } = form;

    return (
        <>
            <ModalBody>
                <div className="p-4">
                    <Warning show={!!warningMessage} text={warningMessage} />
                    {children(form)}
                </div>
            </ModalBody>
            <ModalFooter>
                <ButtonWithLoader isLoading={isLoading} onClick={handleSubmit}>
                    {submitButtonText}
                </ButtonWithLoader>
                <Button
                    type="button"
                    onClick={buttonInsteadCancelCallback ? buttonInsteadCancelCallback : onCancelClick}
                    variant="outline-primary"
                    disabled={isLoading}
                >
                    {cancelButtonText || buttonInsteadCancelText || 'Cancel'}
                </Button>
            </ModalFooter>
            {process.env.NEXT_PUBLIC_HOOK_FORM_DEVTOOL === 'enabled' && <DevTool control={control} />}
        </>
    );
}

export default FormModalInner;
