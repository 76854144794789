const requestsPath = '/requests';
export default requestsPath;

export const questionnaireLibraryPath = '/questionnaire-library';

export const requestActionPath = `${questionnaireLibraryPath}/process/requests`;

export const getRequestsPath = questionnaireLibraryPath + requestsPath;

export const modulesPath = '/modules';

export const questionnaireLibraryModulesPath = questionnaireLibraryPath + modulesPath;
