import * as Sentry from '@sentry/nextjs';
import { useAtom, atom } from 'jotai';
const unexpectedErrorAtom = atom<Error | undefined>(undefined);
import useAlerts from 'hooks/useAlerts';

export default function useUnexpectedError() {
    const { addUnexpectedServerErrorAlert } = useAlerts();
    const [lastError, setUnexpectedError] = useAtom<Error | undefined, Error, void>(
        unexpectedErrorAtom,
        'unexpectedError',
    );
    return {
        hasUnexpectedError: !!lastError,
        setUnexpectedError: (newError: Error, { isUserAlreadyAlerted }: { isUserAlreadyAlerted?: boolean } = {}) => {
            if (newError !== lastError) {
                setUnexpectedError(newError);
                Sentry.captureException(newError);
                if (!isUserAlreadyAlerted) {
                    addUnexpectedServerErrorAlert();
                }
            }
        },
    };
}
