import Link from 'next/link';
import LoggedInLayout from 'layout/Layout/LoggedInLayout';
import styles from './403.module.scss';
import { Container, Row } from 'react-bootstrap';

export default function Error403() {
    return (
        <LoggedInLayout>
            <Container>
                <Row>
                    <h1 className={styles.title}>Not allowed!</h1>
                    <p className={styles.subTitle}>Oops, it seems that you are not supposed to be here...</p>
                    <p className={styles.link}>
                        <Link href="/">Back home</Link>
                    </p>
                </Row>
            </Container>
        </LoggedInLayout>
    );
}
