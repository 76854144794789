export type NewTypeaheadItem = {
    customOption: boolean;
    id: string;
    label: string;
};

export const normalizeTypeaheadArray = (array: (string | NewTypeaheadItem)[]) => {
    return array.map((item) => {
        return normalizeTypeaheadField(item);
    });
};

export const normalizeTypeaheadField = (value: string | NewTypeaheadItem | null) => {
    if (typeof value !== 'string' && value?.customOption) {
        return value.label;
    }
    return value as string;
};
