import { useQueryClient } from 'react-query';
import { questionnairesBaseQueryKey } from 'consts/sharedQueryKeys';

export default function useInvalidateQuestionnaires() {
    const queryClient = useQueryClient();

    return async () => {
        await queryClient.invalidateQueries({
            predicate: (query) =>
                typeof query.queryKey[0] === 'string' && query.queryKey[0] === questionnairesBaseQueryKey,
        });
    };
}
