import ReactBootstrapTypeaheadWrapper, { ReactBootstrapTypeaheadWrapperProps } from './ReactBootstrapTypeaheadWrapper';
import {
    Path,
    PathValue,
    UnpackNestedValue,
    useController,
    UseControllerProps,
    UseFormSetValue,
} from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import styles from './typeahead.module.scss';

export type TypeaheadProps<TOption, TForm extends FieldValues> = Omit<
    ReactBootstrapTypeaheadWrapperProps<TOption>,
    'onChange'
> &
    UseControllerProps<TForm> & {
        setValue: UseFormSetValue<TForm>;
        name: string;
        isWithApiCall?: boolean;
        callbackOnChange?: () => void;
    };

type ValueType<T> = UnpackNestedValue<PathValue<T, Path<T> & string>>;

function Typeahead<TOption, TForm>({
    setValue,
    isWithApiCall = false,
    name,
    callbackOnChange,
    ...props
}: TypeaheadProps<TOption, TForm>) {
    const { field } = useController({ name, ...props });
    return (
        <ReactBootstrapTypeaheadWrapper
            className={`${styles.selectContainer}  ${isWithApiCall ? styles.selectContainerWithApiCall : ''}`}
            {...props}
            {...field}
            onChange={(options) => {
                const opts = props.multiple ? options : options[0];
                setValue(name, opts as ValueType<TForm>, {});
                if (callbackOnChange) {
                    callbackOnChange();
                }
            }}
        />
    );
}
export default Typeahead;
