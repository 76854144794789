import { DefaultValues } from 'react-hook-form/dist/types/form';
import useForm from 'hooks/useForm';

export type useFormProps<Values> = {
    initialValues: Values;
    onSubmit: (values: Values) => Promise<unknown>;
    // eslint-disable-next-line
    validationSchema: any;
    onComplete: (values: Values) => void;
    toggle: () => void;
    isResetAfterSubmit?: boolean;
    onCancelClickCallback?: () => void;
};

function useFormModal<Values>({
    onComplete,
    onSubmit,
    validationSchema,
    initialValues,
    toggle,
    isResetAfterSubmit,
    onCancelClickCallback,
}: useFormProps<Values>) {
    const onSubmitProxy = async (data: Values) => {
        await onSubmit(data);
        onComplete(data);
        reset(isResetAfterSubmit ? undefined : data);
    };

    const form = useForm<Values>({
        schema: validationSchema,
        onSubmit: onSubmitProxy,
        defaultValues: initialValues as DefaultValues<Values>,
    });

    const { reset, setWarningMessage } = form;

    const onCancelClick = () => {
        onCancelClickCallback && onCancelClickCallback();
        toggle();
        reset();
        setWarningMessage('');
    };

    return { reset, onCancelClick, form };
}

export default useFormModal;
