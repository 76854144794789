import { ReactNode } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { FieldError } from 'react-hook-form';
import styles from './field.module.scss';

export type FieldProps = { children: ReactNode; id: string; label?: string; feedback: FieldError | undefined };

const Field = ({ children, id, label, feedback }: FieldProps) => (
    <Form.Group as={Row} controlId={id} className={styles.formGroup}>
        {label && (
            <Form.Label column md="4" className={`${styles.formInputFieldLabel} fw-bold d-flex px-3`}>
                <p className={styles.labelText}>{label}</p>
            </Form.Label>
        )}
        <Col md={label ? 8 : 12} className={styles.input}>
            {children}
            {feedback && <Form.Control.Feedback type="invalid">{feedback?.message}</Form.Control.Feedback>}
        </Col>
    </Form.Group>
);

export default Field;
