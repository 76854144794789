import { RequestParams, rest, RestRequest } from 'msw';
import { requestActionPath } from 'consts/paths/api/requests/requestsPath';
import resolvePath from 'mocks/resolvePath';
import { ActionRequestBody } from 'hooks/useRequestActions';

export default ({ delay = 1500 } = {}) => {
    return [
        rest.post(resolvePath(requestActionPath), (req: RestRequest<ActionRequestBody, RequestParams>, res, ctx) => {
            const { action, requestIds } = req.body;
            if (!requestIds?.length || !action) {
                return res(ctx.status(400));
            }
            return res(ctx.delay(delay), ctx.status(200));
        }),
    ];
};
