import { rest } from 'msw';
import resolvePath from 'mocks/resolvePath';
import icdCodes from './icdCodes.json';
import itemsPath from 'consts/paths/api/items/getItems';
import { ICDCODES } from 'consts/itemDetails';

export default ({ delay = 1000 } = {}) => [
    rest.get(resolvePath(`${itemsPath}/${ICDCODES}/search*`), (req, res, ctx) =>
        res(ctx.delay(delay), ctx.status(200), ctx.json(icdCodes)),
    ),
];
